import { PriceLevelInfo } from './price-level-info.model';

export class ConvertedPriceModel {
    priceLevels: Array<number>;
    packPrice: any;
    unitPrice: any;
    packUomId: number;
    unitUomId: number;
    packUomSize: number;
    unitUomSize: number;
    type: number;
    priceLevelId: number;
    defaultDiscountFee: number;
    productId: number;
}
