import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { AppState } from 'src/app/app.reducer';
import { TemplateEnum } from 'src/app/core/enums/template.enum';
import { AvailablePlatformModel } from 'src/app/core/models/order/available-platform.model';
import { LoadAvailablePlatforms } from '../../actions/order.actions';
import { getThemeId } from '../../selectors/branding.selector';
import { selectAvailablePlatforms } from '../../selectors/order.selector';

@Component({
    selector: 'app-platform-filter',
    templateUrl: './platform-filter.component.html',
    styleUrls: ['./platform-filter.component.scss']
})
export class PlatformFilterComponent implements OnInit, OnChanges {

    @Input() selectedPlatforms: number[] = [];
    @Output() change = new EventEmitter<any>();
    platforms: Array<AvailablePlatformModel>;
    availablePlatforms$: Observable<Array<AvailablePlatformModel>>;
    template$: Observable<TemplateEnum>;
    templateEnum = TemplateEnum;

    constructor(
        private store: Store<AppState>
    ) { }

    ngOnInit(): void {
        this.template$ = this.store.select(getThemeId);
        this.store.dispatch(new LoadAvailablePlatforms());
    }

    ngOnChanges() {
        this.availablePlatforms$ = this.store.select(selectAvailablePlatforms)
            .pipe(
                map((platforms) => platforms.map((s) => ({
                    ...s,
                    isSelected: this.selectedPlatforms.includes(s.id)
                }))),
                tap(platforms => this.platforms = platforms)
            );
    }

    onSelect($event: MatCheckboxChange, status) {
        if ($event.checked) {
            this.selectedPlatforms.push(status.id);
        } else {
            const idx = this.selectedPlatforms.findIndex(s => s === status.id);
            this.selectedPlatforms.splice(idx, 1);
        }

        this.emitSelectedPlatforms(this.selectedPlatforms);
    }

    emitSelectedPlatforms(selectedStatuses: Array<number>) {
        this.change.emit(selectedStatuses);
    }

    onNameClick(status, $event?) {
        if ($event) {
            $event.preventDefault();
        }

        this.change.emit([status.id]);
    }

    onSelectAll() {
        this.change.emit([]);
    }

}
