import { Component, OnInit, Input } from '@angular/core';

@Component({
    selector: 'app-icon-attachment',
    templateUrl: './icon-attachment.component.html',
    styleUrls: ['./icon-attachment.component.scss']
})
export class IconAttachmentComponent implements OnInit {

    @Input() cssClass = '';

    constructor() { }

    ngOnInit() {
    }

}
