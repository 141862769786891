import { createSelector, select } from '@ngrx/store';
import { Observable } from 'rxjs';
import { PaymentSetting } from 'src/app/core/models/payment/payment-setting.model';

export const selectPaymentState = state => state.payment;

export const selectPaymentProfiles = createSelector(
    selectPaymentState,
    state => state.profiles
);

export const selectPaymentProfilesLoading = createSelector(
    selectPaymentState,
    state => state.loadingProfiles
);


export const selectPaymentProfilesLoadingAdd = createSelector(
    selectPaymentState,
    state => state.loadingAdd
);

export const selectTransactionHistory = createSelector(
    selectPaymentState,
    state => state.transactionHistory
);

export const selectLoadingTransactionHistory = createSelector(
    selectPaymentState,
    state => state.loadingTransactionHistory
);

export const selectPaymentSettings = createSelector(
    selectPaymentState,
    state => state.settings
);

export const selectStripeDetails = createSelector(
    selectPaymentState,
    state => state.stripeDetails
);
