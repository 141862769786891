import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { tap, exhaustMap, map } from 'rxjs/operators';
import * as fromPriceRequestActions from '../actions/price-requests.actions';
import { PriceRequestsService } from 'src/app/core/services/price-requests/price-requests.service';
import { PriceRequestSeller } from 'src/app/core/models/price-request/price-request-seller.interface';
import { PriceRequestModel } from '../../core/models/price-request/price-request.model';

@Injectable()
export class PriceRequestsEffects {

    @Effect()
    load$ = this.actions$.pipe(
        ofType<fromPriceRequestActions.LoadPriceRequests>(fromPriceRequestActions.PriceRequestActionTypes.LoadPriceRequests),
        exhaustMap((action) => this.priceRequestsSvc.search(action.payload.requestParams)),
        map((response) => new fromPriceRequestActions.LoadPriceRequestsSuccess({ response }))
    );

    @Effect()
    archive$ = this.actions$.pipe(
        ofType<fromPriceRequestActions.ArchivePriceRequest>(fromPriceRequestActions.PriceRequestActionTypes.ArchivePriceRequest),
        exhaustMap((action) => this.priceRequestsSvc.archive(action.payload.id)
            .pipe(
                map((response) => new fromPriceRequestActions.ArchivePriceRequestSuccess({ id: action.payload.id }))
            )
        )
    );

    @Effect()
    addOrderMin$ = this.actions$.pipe(
        ofType<fromPriceRequestActions.UpdatePriceRequest>(fromPriceRequestActions.PriceRequestActionTypes.UpdatePriceRequest),
        exhaustMap((action) => this.priceRequestsSvc.update(action.payload)),
        map((updatedPriceRequest: PriceRequestModel) =>
            new fromPriceRequestActions.UpdatePriceRequestSuccess(updatedPriceRequest)
        )
    );

    @Effect()
    vendorPriceRequest$ = this.actions$.pipe(
        ofType<fromPriceRequestActions.LoadVendorPriceRequest>(fromPriceRequestActions.PriceRequestActionTypes.LoadVendorPriceRequest),
        exhaustMap((action) => this.priceRequestsSvc.vendorPriceRequest(action.payload.productId).pipe(
            map((vendorDetails: Array<PriceRequestSeller>) => {
                return new fromPriceRequestActions.LoadVendorPriceRequestSuccess({ vendorDetails, productId: action.payload.productId })
            })
        ))
    );

    @Effect()
    applyVendor$ = this.actions$.pipe(
        ofType<fromPriceRequestActions.RequestPriceVendor>(fromPriceRequestActions.PriceRequestActionTypes.RequestPriceVendor),
        exhaustMap((action) => this.priceRequestsSvc.apply(action.payload.productId, action.payload.vendorId).pipe(
            map((response: { date: Date }) => {
                return new fromPriceRequestActions.RequestPriceVendorSuccess({ applyDate: response.date, vendorId: action.payload.vendorId })
            })
        ))
    );

    constructor(
        private actions$: Actions,
        private priceRequestsSvc: PriceRequestsService
    ) { }
}
