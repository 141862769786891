export class GlobalProductModel {
    id: number;
    imageUrl: string;
    imageUrlSmall: string;
    productId: number;
    productName: string;
    sku: string;
    uomId: number;
    uomName: string;
    uomSize: number;
    vendorId: number;
    quantity: number;
    description: string;
    manufacturerId: number;
    categoryId: number;
}
