import { Action } from '@ngrx/store';
import { VendorActions, VendorActionTypes } from '../actions/vendor.actions';
import { randomColor } from '../constants/global.contants';


export interface VendorState {
  vendors: any;
  loadingVendors: boolean,
  categories: any[]
}

export const initialState: VendorState = {
  vendors: undefined,
  loadingVendors: false,
  categories: []
};

export function vendorReducer(state = initialState,
  action: VendorActions): VendorState {
  switch (action.type) {
    case VendorActionTypes.LoadVendorsAction: 
      return {
        ...state,
        loadingVendors: true
      }

    case VendorActionTypes.VendorsLoadedAction:
      const allVendors = {
        ...action.payload.vendors,
        items: action.payload.vendors.items.map(v => ({
              ...v,
              randomColor: randomColor()
            }))
      }

      return {
        ...state,
        vendors: allVendors,
        loadingVendors: false
      };

    case VendorActionTypes.SetVendorFavorite:
      const vendors = state.vendors.items ? {
        ...state.vendors,
        items: state.vendors.items.map(v => ({
                ...v,
                isFavorite: v.customerSiteID === action.payload.vendorId ? action.payload.isFavorite : v.isFavorite
              }))
      } : state.vendors;

      return {
        ...state,
        vendors: vendors
      }

    case VendorActionTypes.ManageRelationAction:
        const vendo = state.vendors.items ? {
        ...state.vendors,
        items: state.vendors.items.map(v => ({
                ...v,
                loading: v.customerAccountID === action.payload.vendorAccountId
              }))
      } : state.vendors;

      return {
        ...state,
        vendors: vendo
      }

    case VendorActionTypes.ManageRelationSuccess:
      const manageVendor = {
        ...state.vendors,
        items: state.vendors.items.map(v => {
          if(v.customerAccountID === action.payload.response.customerAccountID) {
            v.statusID = action.payload.response.statusID;
            v.isVirtual = action.payload.response.isVirtual;
            v.isActive = action.payload.response.isActive;
            v.isCatalog = action.payload.response.isCatalog;
            v.isOnHold = action.payload.response.isOnHold;
            v.loading = false;
          }
          return v;
        })
      };

      return {
        ...state,
        vendors: manageVendor,
      };
    case VendorActionTypes.LoadCategoriesSuccessAction:
      return {
        ...state,
        categories: action.payload.categories
      };
    default:
      return state;
  }
}
