import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { ChangePasswordModel } from '../../models/change-password.model';
import { CompleteAccountModel } from '../../models/auth/complete-account.model';
import { ResetPassword } from '../../models/reset-password.model';
import { ForgotPasswordResponse } from '../../models/forgot-password-response.model';

@Injectable({
    providedIn: 'root'
})
export class AuthenticationService {

    constructor(
        private http: HttpClient
    ) { }

    login(username: string, password: string): Observable<any> {
        return this.http
            .post<any>(`${environment.api.auth}/Auth/token`, {
                username,
                password
            });
    }

    authorizationToken(username: string): Observable<any> {
        return this.http.post<any>(`${environment.api.auth}/Auth/authorization_token/${ username }`, null);
    }

    checkTokenLogin(validationToken: string) {
        return this.http.post<any>(`${environment.api.auth}/Auth/token_login/${validationToken}`, null);
    }

    loginWithToken(token: string, isOldVersion: boolean = false) {
        return this.http.post(`${environment.api.auth}/Auth/${isOldVersion ? 'validate_session' : 'auto_login'}`, {
            accessToken: token
        });
    }

    refreshToken(refreshToken: string) {
        return this.http.post(`${environment.api.auth}/Auth/refresh_token?refreshToken=${refreshToken}`, null);
    }

    forgotPassword(username: string): Observable<ForgotPasswordResponse> {
        return this.http.post<ForgotPasswordResponse>(`${environment.api.auth}/Auth/forgot_password`, { username });
    }

    changePassword(passwordModel: ChangePasswordModel) {
        return this.http.post(`${environment.api.auth}/Auth/change_password`,
            {
                password: passwordModel.currentPassword,
                newPassword: passwordModel.newPassword,
                confirmPassword: passwordModel.confirmPassword
            });
    }

    resetPassword(resetPasswordRequest: ResetPassword): Observable<void> {
        const headers = new HttpHeaders({
            Authorization: `Bearer ${resetPasswordRequest.token}`
        });

        return this.http.post<void>(`${environment.api.auth}/Auth/reset_password`, resetPasswordRequest, { headers });
    }

    sendVerificationEmail(userId?: number) {
        return this.http.post(`${environment.api.auth}/Auth/send_verify_email/${userId || ''}`, null);
    }

    registerGuest(zipCode: string) {
        return this.http.post(`${environment.api.auth}/Auth/register/guest`, { zipCode });
    }

    updateInfo(completeAccount: CompleteAccountModel) {
        return this.http.post(`${environment.api.auth}/Auth/register/guest/update_info`, completeAccount);
    }
}
