import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ToggleInfobar } from 'src/app/shared/actions/infobar.actions';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/app.reducer';
import { PriceLevelModel } from 'src/app/core/models/product/price-level.model';
import { Observable } from 'rxjs';
import { selectLoadingSitesList } from 'src/app/shared/selectors/site.selector';
import { PriceLevelInfo } from 'src/app/core/models/product/price-level-info.model';
import { SavePriceLevel, UpdatePriceLevel } from 'src/app/shared/actions/product.actions';
import { InfobarState } from 'src/app/shared/reducers/infobar.reducer';
import { getSaving } from 'src/app/shared/selectors/product.selector';

@Component({
    selector: 'app-new-price-level',
    templateUrl: './new-price-level.component.html',
    styleUrls: ['./new-price-level.component.scss']
})
export class NewPriceLevelComponent implements OnInit {

    loading$: Observable<boolean>;
    form: FormGroup;
    @Input() infobarConfigs: InfobarState;
    @Input() set priceLevel(priceLevel: PriceLevelInfo) {
        this.priceLevelInfo = priceLevel;
        this.form = null;
        this.createForm();
    }

    priceLevelInfo: PriceLevelInfo;

    constructor(private fb: FormBuilder, private store: Store<AppState>) { }

    ngOnInit() {
        this.loading$ = this.store.select(getSaving);
        this.createForm();
    }

    createForm() {
        this.form = this.fb.group({
            name: [this.priceLevelInfo.id ? this.priceLevelInfo.name : '', Validators.required],
            discountFee: [this.priceLevelInfo.id ? this.priceLevelInfo.discountFee : '', Validators.required]
        });
    }

    save() {
        if (!this.form.valid) {
            return;
        }

        const priceLevel = new PriceLevelModel();
        priceLevel.id = this.priceLevelInfo.id ? this.priceLevelInfo.id : -1;
        priceLevel.discountFee = this.form.value.discountFee;
        priceLevel.name = this.form.value.name;
        priceLevel.baseCatalogId = this.priceLevelInfo.baseCatalogId;

        if (priceLevel.id > 0) {
            this.store.dispatch(new UpdatePriceLevel(priceLevel));
        } else {
            this.store.dispatch(new SavePriceLevel(priceLevel));
        }
    }
}
