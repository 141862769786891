import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-fruits-icon',
    templateUrl: './icon-fruits.component.html',
    styleUrls: ['./icon-fruits.component.scss']
})
export class IconFruitsComponent {

    @Input() cssClass = 'icon--18';

}
