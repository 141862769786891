import { Component, OnInit, Input, OnDestroy, Output, EventEmitter } from '@angular/core';
import * as moment from 'moment';
import { Observable, Subject } from 'rxjs';
import { GridService } from 'src/app/core/services/grid/grid.service';
import { tap } from 'rxjs/operators';
import { GRID_TYPE } from 'src/app/core/enums/grid-type.enum';
import { DATE_RANGE_DEFAULT_OPTIONS, DATE_CUSTOM_RANGES, DEFAULT_DATE_RANGE_SELECTION } from 'src/app/shared/constants/global.contants';
import { OrderFilterType } from 'src/app/core/enums/date-groping.enum';
import { ProductCostByCategoryService } from 'src/app/modules/product-cost-by-category/product-cost-by-category.service';
import { TemplateEnum } from 'src/app/core/enums/template.enum';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/app.reducer';
import { getThemeId } from '../../selectors/branding.selector';

@Component({
    selector: 'app-datepicker-range-filter',
    templateUrl: './datepicker-range-filter.component.html',
    styleUrls: ['./datepicker-range-filter.component.scss']
})
export class DatepickerRangeFilterComponent implements OnInit, OnDestroy {

    @Input() title: string;
    @Output() dateRangeChanged = new EventEmitter<{ start: Date, end: Date }>();
    gridApi: any;
    maxDate = moment().toDate();

    selectedRange: any;
    selected = DEFAULT_DATE_RANGE_SELECTION;
    template$: Observable<TemplateEnum>;
    templateEnum = TemplateEnum;
    rangeOptions: any[] = DATE_RANGE_DEFAULT_OPTIONS;
    ranges: any = DATE_CUSTOM_RANGES;
    columnDefs: any;
    rowData: any;

    private ngUnsubscribe: Subject<void> = new Subject<void>();

    constructor(
        private service: ProductCostByCategoryService,
        private gridSvc: GridService,
        private store: Store<AppState>
    ) { }

    ngOnInit() {
        this.template$ = this.store.select(getThemeId);
        this.selectedRange = this.rangeOptions[1];
    }

    ngOnDestroy() {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    onDateRangeChanged(params) {
        const startDate = params.startDate || this.selected[0];
        const endDate = params.endDate || this.selected[1];

        this.dateRangeChanged.emit({ start: startDate, end: endDate });
    }

    applyRange(range: string) {
        if (!range.includes('-')) {
            return;
        }

        const dates = range.split('-');
        const startDate = moment(dates[0].trim());
        const endDate = moment(dates[1].trim());

        // validate dates
        if (!startDate.isValid() || !endDate.isValid()) {
            return;
        }

        if (startDate > endDate) {
            return;
        }

        // dates are valid -> do call
        this.onDateRangeChanged({
            startDate,
            endDate
        });
    }

}

