import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-product-purchase-chart',
  templateUrl: './product-purchase-chart.component.html',
  styleUrls: ['./product-purchase-chart.component.scss']
})
export class ProductPurchaseChartComponent implements OnInit, OnChanges {

  @Input() purchaseHistory: any;

  public barChartType = 'bar';
  public barChartLegend = true;
  public barChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    legend: {
      labels: {
        usePointStyle: true,
        boxWidth: 8
      },
      pointStyle: 'circle'
    },
    scales: {
      xAxes: [{
        barPercentage: 0.3,
        gridLines: {
          drawBorder: true,
          drawOnChartArea: false,
          drawTicks: false
        },
        ticks: {
          padding: 10
        }
      }],
      yAxes: [{
        gridLines: {
          drawBorder: true,
          drawOnChartArea: false,
          drawTicks: false
        },
        ticks: {
          min: 0,
          padding: 10
        }
      }],
    }
  };

  public barChartLabels = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
  public barChartData = [
    {
      data: [],
      label: 'Last 7 days',
      fill: false,
      backgroundColor: '#5DD7F6',
      hoverBackgroundColor: '#50b0c8',
      borderColor: '#5DD7F6',
      hoverborderColor: '#50b0c8',
    }
  ];

  constructor() { }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.purchaseHistory) {
      const { currentValue } = changes.purchaseHistory;
      this.barChartData[0].data = [
        currentValue['day1'],
        currentValue['day2'],
        currentValue['day3'],
        currentValue['day4'],
        currentValue['day5'],
        currentValue['day6'],
        currentValue['day7']
      ];
    }
  }

}
