import { Action } from '@ngrx/store';
import { EntityType } from 'src/app/core/enums/entity-type.enum';

export enum RatingActionTypes {
  LoadRatingCategoriesAction = '[Rating] Load Rating Categories',
  LoadRatingCategoriesSuccess = '[Rating] Load Rating Categories Success',
  AddRatingAction = '[Rating] Set Rating',
  AddRatingSuccess = '[Rating] Set Rating Success',
  AddRatingError = '[Rating] Set Rating Error',
  LoadRatingAction = '[Rating] Load Rating ',
  LoadRatingSuccess = '[Rating] Load Rating Success',
}

export class LoadRatingCategories implements Action {
  readonly type = RatingActionTypes.LoadRatingCategoriesAction;

  constructor(public payload: { entityTypeId: EntityType }) { }
}

export class LoadRatingCategoriesSuccess implements Action {
  readonly type = RatingActionTypes.LoadRatingCategoriesSuccess;

  constructor( public payload: { categories: any[] } ) {}
}

export class AddRating implements Action {
  readonly type = RatingActionTypes.AddRatingAction;

  constructor(public payload: { entityId: any, ratingEntityTypeId: EntityType, score: any[] }) { }
}

export class AddRatingSuccess implements Action {
  readonly type = RatingActionTypes.AddRatingSuccess;

  constructor(public payload: { entityId: any, ratingEntityTypeId: EntityType, score: any[] }) { }
}

export class AddRatingError implements Action {
  readonly type = RatingActionTypes.AddRatingError;
}

export class LoadRating implements Action {
  readonly type = RatingActionTypes.LoadRatingAction

  constructor(public payload: { entityTypeId: EntityType, entityId: number }) { }
}

export class LoadRatingSuccess implements Action {
  readonly type = RatingActionTypes.LoadRatingSuccess

  constructor(public payload: { rating: any }) { }
}


export type RatingActions = 
| LoadRatingCategories
| LoadRatingCategoriesSuccess
| AddRating
| AddRatingSuccess 
| AddRatingError
| LoadRating 
| LoadRatingSuccess ;
