import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
    selector: 'app-order-status-temp1',
    templateUrl: './order-status.temp1.component.html',
    styleUrls: ['./order-status.temp1.component.scss']
})
export class OrderStatusTemp1Component implements OnInit {

    @Input() status: any;
    @Input() orderId: number;
    @Input() availableStatuses = [];
    @Output() selectStatus = new EventEmitter<any>();

    constructor() { }

    ngOnInit(): void {
    }

    onSelect(status) {
        this.selectStatus.emit(status);
    }

}
