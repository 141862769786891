import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-icon-product-unlock',
  templateUrl: './icon-product-unlock.component.html',
  styleUrls: ['./icon-product-unlock.component.scss']
})
export class IconProductUnlockComponent implements OnInit {
  @Input() cssClass = 'icon--24';

  constructor() { }

  ngOnInit(): void {
  }

}
