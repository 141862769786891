import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'orderBy',
    pure: true
})
export class OrderByPipe implements PipeTransform {

    transform(value: any[], propertyName: string): any[] {
        if (value && value.length && propertyName) {
            return value.sort((a: any, b: any) => b[propertyName]?.toString().localeCompare(a[propertyName]?.toString()));
        } else {
            return value;
        }
    }
}
