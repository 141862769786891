import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { catchError, exhaustMap, map, mergeMap, tap } from 'rxjs/operators';
import {
    LoadSites, SiteActionTypes, SitesLoaded, LoadSitesList, SitesListLoaded, EditSite, EditSiteSuccess, DeleteSite,
    DeleteSiteSuccess, ChangeSiteName, ChangeSiteNameSuccess, AddSite, AddSiteSuccess, UpdateSite, UpdateSiteSuccess,
    CreditApplication, CreditApplicationSuccess, AddCreditApplication, LoadCompanyDetails,
    CompanyDetailsLoaded, UpdateAddressSuccess, UpdateAddress, EditCompanyDetails, EditCompanyDetailsSuccess, EditCompanyDetailsFailed,
} from '../actions/site.actions';
import { SitesService } from 'src/app/core/services/sites/sites.service';
import { of } from 'rxjs';
import { ToggleInfobar } from '../actions/infobar.actions';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/app.reducer';
import { NotifierService } from 'angular-notifier';
import { SiteModel } from 'src/app/core/models/site.model';
import { HttpErrorResponse } from '@angular/common/http';
import { UserService } from 'src/app/core/services/user/user.service';

@Injectable()
export class SiteEffects {

    @Effect()
    loadSites$ = this.actions$.pipe(
        ofType<LoadSites>(SiteActionTypes.LoadSitesAction),
        exhaustMap(() => this.sitesSvc.loadSites().pipe(
            map((sites: Array<SiteModel>) => new SitesLoaded({ sites }))
        ))
    );

    @Effect()
    sites$ = this.actions$.pipe(
        ofType<LoadSitesList>(SiteActionTypes.LoadSitesListAction),
        exhaustMap((action: LoadSitesList) => this.sitesSvc.getSites(action.payload).pipe(
            map(sitesList => new SitesListLoaded({ sitesList }))
        ))
    );

    @Effect()
    editSite$ = this.actions$.pipe(
        ofType<EditSite>(SiteActionTypes.EditSiteAction),
        exhaustMap((action) => this.sitesSvc.editSite(action.payload)),
        map((sitesList) => new EditSiteSuccess({ sitesList }))
    );

    @Effect()
    deleteSite$ = this.actions$.pipe(
        ofType<DeleteSite>(SiteActionTypes.DeleteSiteAction),
        exhaustMap((action) => this.sitesSvc.deleteSite(action.payload.siteId)
            .pipe(map(() => action.payload.siteId))
        ),
        map((siteId) => new DeleteSiteSuccess({ siteId }))
    );


    @Effect()
    changesiteName$ = this.actions$.pipe(
        ofType<ChangeSiteName>(SiteActionTypes.ChangeSiteName),
        exhaustMap((action) => {
            const response = action.payload.name !== action.payload.currentName ? this.sitesSvc.editSite(action.payload) : of();
            return response.pipe(
                map(() => ({
                    siteId: action.payload.id,
                    name: action.payload.name
                })
                ));
        }),
        map((response) => new ChangeSiteNameSuccess(response))
    );

    @Effect({ dispatch: false })
    addSite$ = this.actions$.pipe(
        ofType<AddSite>(SiteActionTypes.AddSiteAction),
        exhaustMap((action) => this.sitesSvc.addSite(action.payload.site)),
        map((newSite) => {
            this.store.dispatch(new AddSiteSuccess({ newSite }));
            this.store.dispatch(new ToggleInfobar({ open: false }));
        }
        )
    );

    @Effect({ dispatch: false })
    updateSite$ = this.actions$.pipe(
        ofType<UpdateSite>(SiteActionTypes.UpdateSite),
        exhaustMap((action) => this.sitesSvc.editSite(action.payload.site)),
        map((site) => {
            this.store.dispatch(new UpdateSiteSuccess({ site }));
            this.store.dispatch(new ToggleInfobar({ open: false }));
            this.notifier.notify('success', 'Location updated with success');
        }
        )
    );

    @Effect()
    loadCreditApplication$ = this.actions$.pipe(
        ofType<CreditApplication>(SiteActionTypes.LoadCreditApplication),
        exhaustMap((action) => this.sitesSvc.getCreditApplication(action.payload.siteId)),
        map((creditApplicationDetails: any[]) => new CreditApplicationSuccess({ creditApplicationDetails }))
    );

    @Effect({ dispatch: false })
    addCreditApplication$ = this.actions$.pipe(
        ofType<AddCreditApplication>(SiteActionTypes.AddCreditApplication),
        exhaustMap((action) => this.sitesSvc.addCreditApplication(action.payload.siteId, action.payload.creditApplication)),
        map(() => {
            this.notifier.notify('success', 'Credit Application Information updated');
            this.store.dispatch(new ToggleInfobar({ open: false }));
        }
        )
    );

    @Effect()
    loadCompanyDetails$ = this.actions$.pipe(
        ofType<LoadCompanyDetails>(SiteActionTypes.LoadCompanyDetails),
        exhaustMap(() => this.sitesSvc.getCompanyDetails().pipe(
            map(companyDetails => new CompanyDetailsLoaded({ companyDetails }))
        ))
    );

    @Effect()
    updateAddress$ = this.actions$.pipe(
        ofType<UpdateAddress>(SiteActionTypes.UpdateAddress),
        exhaustMap((action) => this.sitesSvc.updateAddress(action.payload.address)
            .pipe(
                map(() => new UpdateAddressSuccess(action.payload))
            )
        )
    );

    @Effect()
    updateAddressSuccess$ = this.actions$.pipe(
        ofType<UpdateAddressSuccess>(SiteActionTypes.UpdateAddressSuccess),
        tap(() => {
            this.notifier.show({
                type: 'success',
                message: 'Address updated with success'
            });
        }),
        map(() => new ToggleInfobar({
            open: false
        }))
    );

    @Effect()
    editCompanyDetails$ = this.actions$.pipe(
        ofType(SiteActionTypes.EditCompanyDetails),
        mergeMap((action: EditCompanyDetails) => this.userSvc.editCompanyDetails(action.payload).pipe(
            map(() => {
                this.store.dispatch(new ToggleInfobar({
                    open: false
                }));

                return new EditCompanyDetailsSuccess(action.payload);
            }),
            catchError((err: HttpErrorResponse) => {
                this.notifier.notify('error', err.error.message);
                return of(new EditCompanyDetailsFailed());
            })
        ))
    );

    constructor(
        private actions$: Actions,
        private sitesSvc: SitesService,
        private store: Store<AppState>,
        private notifier: NotifierService,
        private userSvc: UserService
    ) { }

}
