import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-icon-prepared-foods',
  templateUrl: './icon-prepared-foods.component.html',
  styleUrls: ['./icon-prepared-foods.component.scss']
})
export class IconPreparedFoodsComponent implements OnInit {

  @Input() cssClass = 'icon--18';

  constructor() { }

  ngOnInit(): void {
  }

}
