import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/app.reducer';
import { LoadRatingCategories, AddRating } from '../../actions/rating.actions';
import { ratingCategories, loadingRatingCat } from '../../selectors/rating.selector';
import { EntityType } from 'src/app/core/enums/entity-type.enum';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'app-add-rating',
  templateUrl: './add-rating.component.html',
  styleUrls: ['./add-rating.component.scss']
})
export class AddRatingComponent implements OnInit {

  entityTypeId = EntityType.VENDOR;
  vendor: any;
  required: boolean;
  overallRating = 0;
  categorySize = 0;

  ratingCat$: Observable<any>;
  loading$: Observable<boolean>;
  categoryRating: Map<number, number> = new Map<number, number>();

  constructor(
    private store: Store<AppState>,
    public dialogRef: MatDialogRef<AddRatingComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { vendor: any; }) {
    this.vendor = this.data;
  }

  ngOnInit() {
    this.ratingCat$ = this.store.select(ratingCategories).pipe(
      tap(array => this.categorySize = array.length)
    );
    this.loading$ = this.store.select(loadingRatingCat);

    this.store.dispatch(new LoadRatingCategories({ entityTypeId: this.entityTypeId }));
  }

  onRatingClick(params) {
    const { rating, categoryId } = params;
    this.categoryRating.set(categoryId, rating);

    let sum = 0;
    this.categoryRating.forEach(value => {
      sum += value;
      this.overallRating = Math.round(sum / this.categoryRating.size);
    });
  }

  onSubmit() {
    const score = [];
    this.categoryRating.forEach((value: number, key: number) => {
      score.push({ categoryId: key, score: value });
    });

    if (score.length < this.categorySize) {
      this.required = true;
    } else {
      this.required = false;

      this.store.dispatch(new AddRating({
        entityId: this.vendor.vendorAccountId,
        ratingEntityTypeId: this.entityTypeId,
        score: score
      }));
    }
  }

  close(): void {
    this.dialogRef.close();
  }

}
