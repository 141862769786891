import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { UpdateShippingSettingsRequest } from '../../models/shipping/update-shipping-settings-request.model';
import { Observable } from 'rxjs';
import { ShippingSettings } from '../../models/shipping/shipping-settings.model';
import { Carrier } from '../../models/shipping/carrier.model';
import { DeliveryMethod } from '../../models/shipping/delivery-method.model';
import { DeliveryMethodsRequest } from '../../models/shipping/delivery-methods-request';

@Injectable({
    providedIn: 'root'
})
export class ShippingService {

    constructor(
        private http: HttpClient
    ) {
    }

    loadShippingSettings(): Observable<ShippingSettings[]> {
        return this.http.get<ShippingSettings[]>(`${environment.api.content}/Logistics/carriers`);
    }

    updateShippingSettings(settings: UpdateShippingSettingsRequest[]): Observable<boolean> {
        return this.http.post<boolean>(`${environment.api.content}/Logistics/update_carrier_settings`, settings);
    }

    loadAvailableShippingMethods(priceGroupId: number): Observable<Carrier[]> {
        return this.http
            .get<Carrier[]>(`${environment.api.content}/Logistics/available_carriers?priceGroupId=${priceGroupId}`);
    }

    loadDeliveryMethods(carrierId: number, request: DeliveryMethodsRequest): Observable<DeliveryMethod[]> {
        return this.http
            .post<DeliveryMethod[]>(`${environment.api.content}/Logistics/delivery_methods/${carrierId}`, request);
    }
}
