import {Component, OnInit, Input, OnDestroy} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {AppState} from 'src/app/app.reducer';
import {Store} from '@ngrx/store';
import {Observable, of, Subject} from 'rxjs';
import {selectCountries, selectStates} from '../../selectors/utils.selector';
import {LoadCountries, LoadStates} from '../../actions/utils.actions';
import {filter, takeUntil} from 'rxjs/operators';
import {AddCreditCard} from '../../actions/payment.actions';
import {MatDialogRef} from '@angular/material/dialog';


const creditcards = [
    {
        brand: 'American Express',
        verification: '^3[47][0-9]',
        separation: '^([0-9]{4})([0-9]{6})?(?:([0-9]{6})([0-9]{5}))?$',
        hidden: '**** ****** *[0-9][0-9][0-9][0-9]',
        length: 15
    },
    {
        brand: 'Master Card',
        verification: '^5[1-5][0-9]',
        separation: '^([0-9]{4})([0-9]{4})?([0-9]{4})?([0-9]{4})?$',
        hidden: '**** **** **** [0-9][0-9][0-9][0-9]',
        length: 16
    },
    {
        brand: 'Visa',
        verification: '^4[0-9]',
        separation: '^([0-9]{4})([0-9]{4})?([0-9]{4})?([0-9]{4})?$',
        hidden: '**** **** **** [0-9][0-9][0-9][0-9]',
        length: 16
    },
    {
        brand: 'Discover',
        verification: '^6(?:011|5[0-9]{2})[0-9]',
        separation: '^([0-9]{4})([0-9]{4})?([0-9]{4})?([0-9]{4})?$',
        hidden: '**** **** **** [0-9][0-9][0-9][0-9]',
        length: 16
    },
    {
        brand: 'Diners Club',
        verification: '^3(?:0[0-5]|[68][0-9])[0-9]',
        separation: '^([0-9]{4})([0-9]{4})?([0-9]{4})?(?:([0-9]{4})([0-9]{4})([0-9]{2}))?$',
        hidden: '**** **** **[0-9][0-9] [0-9][0-9]',
        length: 14
    },
    {
        brand: 'JCB',
        verification: '^(?:2131|1800|35[0-9]{3})[0-9]',
        separation: '^([0-9]{4})([0-9]{4})?([0-9]{4})?([0-9]{4})?$',
        hidden: '**** **** **** [0-9][0-9][0-9][0-9]',
        length: 16
    }
];

@Component({
    selector: 'app-add-credit-card',
    templateUrl: './add-credit-card.component.html',
    styleUrls: ['./add-credit-card.component.scss']
})
export class AddCreditCardComponent implements OnInit, OnDestroy {

    formCC: FormGroup;
    currentCardType;
    months$: Observable<number[]>;
    years$: Observable<number[]>;
    ngUnsubscribe: Subject<void> = new Subject<void>();
    currentDate = new Date();
    availableStates: Array<any>;
    availableCountries: Array<any>;

    dropdownSettings = {
        text: '',
        classes: 'tl-multipleselect',
        enableSearchFilter: true,
        badgeShowLimit: 2,
        singleSelection: true,
        enableFilterSelectAll: false,
        showCheckbox: false
    };

    @Input() showSavePayment = true;

    constructor(
        private fb: FormBuilder,
        private store: Store<AppState>,
        public dialogRef: MatDialogRef<AddCreditCardComponent>
    ) {
        this.formCC = this.fb.group({
            creditCard: new FormGroup({
                cardNumber: new FormControl(null),
                name: new FormControl(null, [Validators.pattern(/^[a-zA-z \-]*$/)]),
                expirationDate: new FormControl(null),
                cvv: new FormControl(null, [Validators.pattern(/^[0-9]{3,4}$/)]),
            }),
            address: new FormGroup({
                address: new FormControl(null, [Validators.pattern(/^.*[a-zA-Z0-9\. \-]$/)]),
                city: new FormControl(null, [Validators.pattern(/^[a-zA-z \-]*$/)]),
                zipCode: new FormControl(null, [Validators.pattern(/^\d{5}(?:[-\s]\d{4})?$/)]),
                countryId: new FormControl(null, [Validators.required]),
                stateId: new FormControl(null, [Validators.required]),
            }),
            save: [false, []]
        });

        // this.setMonths(1);
        // this.setYears(0);
    }

    ngOnDestroy(): void {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    ngOnInit() {
        this.store.select(selectCountries).pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => {
            if (data) {
                this.availableCountries = data.map(state => ({
                    id: state.id,
                    itemName: state.name
                }));
            }
        });
        this.store.select(selectStates, {
            countryId: null
        }).pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => {
            if (data) {
                this.availableStates = data.map(state => ({
                    id: state.id,
                    itemName: state.name
                }));
            }
        });

        this.store.dispatch(new LoadCountries());

        this.address.countryId.valueChanges
            .pipe(filter(value => value !== null && value !== undefined), takeUntil(this.ngUnsubscribe))
            .subscribe(() => {
                if (!this.address.countryId.value[0]) {
                    this.availableStates = [];
                } else {
                    this.store.select(selectStates, {
                        countryId: this.address.countryId.value[0].id
                    }).pipe().subscribe(data => {
                        if (data) {
                            this.availableStates = data.map(state => ({
                                id: state.id,
                                itemName: state.name
                            }));
                        }
                    });

                    this.store.dispatch(new LoadStates({
                        countryId: this.address.countryId.value[0].id
                    }));
                }

                this.address.stateId.setValue(null);
                this.address.stateId.updateValueAndValidity({onlySelf: true});
            });
    }

    get form() {
        return this.formCC.controls;
    }

    get address() {
        return (this.formCC.controls.address as FormGroup).controls;
    }

    get creditCard() {
        return (this.formCC.controls.creditCard as FormGroup).controls;
    }

    onSubmit() {
        if (this.formCC.invalid) {
            return;
        }

        const formValue = this.formCC.value;

        this.store.dispatch(new AddCreditCard({
            ...formValue,
            creditCard: {
                ...formValue.creditCard,
                cardNumber: formValue.creditCard.cardNumber.replace(/ /g, ''),
                month: formValue.creditCard.expirationDate.substring(0, 2),
                year: formValue.creditCard.expirationDate.substring(2, 4)
            },
            address: {
                ...formValue.address,
                countryId: formValue.address.countryId.id,
                stateId: formValue.address.stateId.id
            }
        }));
    }

    onCardNumberKeyDown($event) {
        const card = (this.creditCard.cardNumber.value || '').replace(/[^0-9]/g, '');
        const currentCreditCard = creditcards.find(c => card.match(new RegExp(c.verification)));
        if (currentCreditCard) {

            if (card.length >= currentCreditCard.length && ![8, 9].includes($event.keyCode)) {
                $event.preventDefault();
                return;
            }

            // Add a Space if the Regex Passes
            if (new RegExp(currentCreditCard.separation).exec(card) && $event.keyCode >= 48 && $event.keyCode <= 57) {
                this.creditCard.cardNumber.setValue(this.creditCard.cardNumber.value + ' ');
            }

            this.currentCardType = currentCreditCard.brand;

        } else {
            this.currentCardType = undefined;
        }
    }

    cancel() {
        this.dialogRef.close();
    }

}
