import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-icon-sort-az',
  templateUrl: './icon-sort-az.component.html',
  styleUrls: ['./icon-sort-az.component.scss']
})
export class IconSortAzComponent implements OnInit {

  @Input() cssClass = '';
  
  constructor() { }

  ngOnInit() {
  }

}
