import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { AppState } from 'src/app/app.reducer';
import { selectRelatedProducts } from '../../selectors/order-guide.selector';
import { LoadRelatedProducts } from '../../actions/order-guide.action';
import { PlatformType } from 'src/environments/platform-type.enum';
import { environment } from 'src/environments/environment';
import { TemplateEnum } from 'src/app/core/enums/template.enum';
import { Observable } from 'rxjs';
import { getThemeId } from '../../selectors/branding.selector';

@Component({
    selector: 'app-product-related',
    templateUrl: './product-related.component.html',
    styleUrls: ['./product-related.component.scss']
})
export class ProductRelatedComponent implements OnInit, OnChanges {

    @Input() productId: any;
    products$: any;
    template$: Observable<TemplateEnum>;
    tempplateEnum = TemplateEnum;
    PlatformType = PlatformType;
    currentPlatform = environment.platformId;

    constructor(
        private store: Store<AppState>
    ) { }

    ngOnInit() {
        this.template$ = this.store.select(getThemeId);
        this.products$ = this.store.pipe(select(selectRelatedProducts));
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (this.productId) {
            this.store.dispatch(new LoadRelatedProducts({
                productId: this.productId
            }));
        }
    }

    getDefaultImage(): string {
        switch (this.currentPlatform) {
            case PlatformType.AMAST:
                return 'default.amast';
            default:
                return 'default';
        }
    }

    getOnErrorImage($event: any) {
        $event.src = 'assets/images/categories/' + this.getDefaultImage() + '.svg';
        $event.classList.add('product-related__img--faded');
    }

}
