export enum ColumnFilterType {
    NONE = 1,
    TEXT = 2
}

export enum ColumnSortType {
    DEFAULT = 1,
    CLIENT_MONEY_SORT = 2
}

export enum ColumnAlignType {
    LEFT = 1,
    CENTER = 2,
    RIGHT = 3
}

export enum AggFunctionType {
    NONE = 0,
    SUM = 1
}