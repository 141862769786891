import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { AppState } from 'src/app/app.reducer';
import { Store } from '@ngrx/store';
import { ChangeSiteName } from '../../actions/site.actions';


@Component({
  selector: 'app-edit-site-name',
  templateUrl: './edit-site-name.component.html',
  styleUrls: ['./edit-site-name.component.scss']
})
export class EditSiteNameComponent implements OnInit {

  editForm: FormGroup;

  @Input() siteId: number;

  @Input() originalName: string;
  
  @Input() currentName: string;

  @Input() inline = false;

  constructor(
    private fb: FormBuilder,
    private store: Store<AppState>
  ) { }

  ngOnInit() {
    this.editForm = this.fb.group({
      name: [this.currentName, [Validators.required]]
    });
  }

  onRevertChanges() {
    this.editForm.controls.name.setValue(this.originalName);
  }

  onSave() {
    if (this.editForm.invalid) {
      return;
    }

    this.store.dispatch(new ChangeSiteName({
      id: this.siteId,
      name: this.editForm.controls.name.value.trim(),
      currentName: this.currentName.trim()
    }));
  }

}
