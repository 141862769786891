import { Component, OnInit, Input } from '@angular/core';
import { AppState } from 'src/app/app.reducer';
import { Store } from '@ngrx/store';
import { AssignTag, CreateAndAssignTag, LoadTags } from '../../actions/tags.actions';

@Component({
  selector: 'app-tags',
  templateUrl: './tags.component.html',
  styleUrls: ['./tags.component.scss']
})
export class TagsComponent implements OnInit {

  @Input() productId: number;

  @Input() tags: any[];

  public searchText;

  constructor(
    private store: Store<AppState>
  ) { }

  ngOnInit() {
  }

  preventPopupClose(e) {
    e.stopPropagation();
  }

  isFaded(tag) {
    return this.searchText && this.searchText.length && !tag.name.toLowerCase().includes(this.searchText);
  }

  onAssign($event, tag) {
    $event.stopPropagation();
    this.store.dispatch(new AssignTag({
      tagId: tag.id,
      isAssigned: tag.isSelected,
      productId: this.productId
    }));
  }

  onSubmit() {
    if (!this.searchText || !this.searchText.length) {
      return;
    }

    if (this.tags.find(t => t.name.toLowerCase() === this.searchText.toLowerCase().trim())) {
      return;
    }

    // assign new tag
    this.store.dispatch(new CreateAndAssignTag({
      productId: this.productId,
      name: this.searchText
    }));

    this.searchText = undefined;
  }

  get hasTagsAssigned() {
    return this.tags.find(t => t.isSelected) ? '' : 'icon--tag-empty';
  }

}
