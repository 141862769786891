import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { OrderFilterType } from 'src/app/core/enums/date-groping.enum';

@Component({
  selector: 'app-date-filter-type-selection',
  templateUrl: './date-filter-type-selection.component.html',
  styleUrls: ['./date-filter-type-selection.component.scss']
})
export class DateFilterTypeSelectionComponent implements OnInit {

  options: any[] = [
    {
      id: OrderFilterType.ORDER_DATE,
      name: 'Ordered Date'
    },
    {
      id: OrderFilterType.DELIVERY_DATE,
      name: 'Delivery Date'
    },
  ];

  selectedOption;

  @Output() selected = new EventEmitter();

  constructor() { }

  ngOnInit() {
    this.selectedOption = this.options[0].id;
    this.selected.emit(this.selectedOption);
  }

  onSelectionChange($event) {
    this.selected.emit(this.selectedOption);
  }

}
