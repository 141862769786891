import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { select, Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { AppState } from 'src/app/app.reducer';
import { GlobalProductModel } from 'src/app/core/models/product/global-product.model';
import { environment } from 'src/environments/environment';
import { PlatformType } from 'src/environments/platform-type.enum';
import { LoadProductsForComposition } from '../../actions/product.actions';
import { getProductsForComposition, loadingProductsForComposition } from '../../selectors/product.selector';
import { AddProductForCompositionModalComponent } from '../add-product-for-composition-modal/add-product-for-composition-modal.component';

@Component({
    selector: 'app-search-product-composition-item-modal',
    templateUrl: './search-product-composition-item-modal.component.html',
    styleUrls: ['./search-product-composition-item-modal.component.scss']
})
export class SearchProductCompositionItemModalComponent implements OnInit {

    quantity: number;
    loading: boolean;
    productsForComposition: Array<GlobalProductModel>;
    keyword: string;
    keyword$: Subject<string> = new Subject<string>();
    displayList: boolean;
    currentPlatform = environment.platformId;
    selectedProductForComposition: GlobalProductModel;
    submitted: boolean;

    constructor(
        public dialogRef: MatDialogRef<SearchProductCompositionItemModalComponent>,
        private dialog: MatDialog,
        private store: Store<AppState>,
        @Inject(MAT_DIALOG_DATA) public data: { excludedProductsIds: Array<number> },
    ) { }

    ngOnInit(): void {
        this.store.pipe(select(loadingProductsForComposition)).subscribe((data: boolean) => {
            this.loading = data;
        });

        this.store.pipe(select(getProductsForComposition)).subscribe((data: Array<any>) => {
            if (data) {
                this.productsForComposition = data;
            }
        });

        this.keyword$.pipe(
            debounceTime(500),
        ).subscribe((keyword: string) => {
            if (keyword.length >= 3) {
                this.displayList = true;
                this.store.dispatch(new LoadProductsForComposition({ keyword, excludedProductsIds: this.data.excludedProductsIds }));
            }
        });
    }

    save() {
        this.submitted = true;

        if (this.selectedProductForComposition && this.quantity > 0) {
            const productItemCompositionModel = new GlobalProductModel();

            productItemCompositionModel.quantity = this.quantity;
            productItemCompositionModel.productId = this.selectedProductForComposition.productId;
            productItemCompositionModel.imageUrl = this.selectedProductForComposition.imageUrl;
            productItemCompositionModel.sku = this.selectedProductForComposition.sku;
            productItemCompositionModel.productName = this.selectedProductForComposition.productName;
            productItemCompositionModel.uomSize = this.selectedProductForComposition.uomSize;
            productItemCompositionModel.uomName = this.selectedProductForComposition.uomName;

            this.dialogRef.close(productItemCompositionModel);
        }
    }

    cancel() {
        this.dialogRef.close('cancel');
    }

    addProductForComposition() {
        const dialogRef = this.dialog.open(AddProductForCompositionModalComponent, {
            id: 'add-product-for-composition'
        });

        dialogRef.afterClosed().subscribe((result: GlobalProductModel) => {
            this.selectProduct(result);
        });
    }

    getDefaultImage(): string {
        switch (this.currentPlatform) {
            case PlatformType.AMAST:
                return 'default.amast';
            default:
                return 'default';
        }
    }

    getOnErrorImage($event: any) {
        $event.src = 'assets/images/categories/' + this.getDefaultImage() + '.svg';
    }

    selectProduct(product: GlobalProductModel) {
        this.selectedProductForComposition = product;
        this.displayList = false;
        this.keyword = product.productName;
    }

    showProductsList(event: any) {
        event.stopPropagation();
        this.displayList = true;
    }

}
