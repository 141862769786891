import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/app.reducer';
import * as authSelectors from '../../shared/selectors/auth.selector';
import { COMPANY_TYPE } from '../enums/company-type.enum';

@Injectable({
    providedIn: 'root'
})
export class IsBuyerGuard implements CanActivate {
    constructor(
        private store: Store<AppState>,
        private router: Router
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        this.store.select(authSelectors.currentCompanyType)
            .subscribe((companyType: COMPANY_TYPE) => {
                if (companyType && companyType === COMPANY_TYPE.VENDOR && route.data.searchUrl === 'account/locations') {
                    this.router.navigate(['/account/team']);
                    return;
                } else if (companyType && companyType !== COMPANY_TYPE.BUYER) {
                    this.router.navigate(['/orders']);
                    return;
                }
            });

        return true;
    }
}
