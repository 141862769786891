import { PaymentAction, PaymentActionTypes } from '../actions/payment.actions';
import { PaymentSetting } from 'src/app/core/models/payment/payment-setting.model';
import { StripeDetailsModel } from 'src/app/core/models/payment/stripe-details.model';

export interface PaymentState {
    profiles: any[],
    loadingProfiles: boolean,
    loadingAdd: boolean,
    loadingTransactionHistory: boolean,
    transactionHistory: any[],
    settings: PaymentSetting[],
    stripeDetails: StripeDetailsModel
}

export const initialState: PaymentState = {
    profiles: [],
    loadingProfiles: false,
    loadingAdd: false,
    loadingTransactionHistory: false,
    transactionHistory: [],
    settings: [],
    stripeDetails: undefined
};

export function paymentReducer(state = initialState,
    action: PaymentAction): PaymentState {
    switch (action.type) {
        case PaymentActionTypes.LoadPaymentProfiles:
            return {
                ...state,
                loadingProfiles: true
            };
        case PaymentActionTypes.LoadPaymentProfilesSuccess:
            return {
                ...state,
                loadingProfiles: false,
                profiles: action.payload.profiles
            };
        case PaymentActionTypes.DeletePaymentProfile:
            return {
                ...state,
                profiles: state.profiles.filter(p => p.id !== action.payload.profileId)
            };
        case PaymentActionTypes.AddCreditCard:
            return {
                ...state,
                loadingAdd: true
            };
        case PaymentActionTypes.AddCreditCardSuccess:
            return {
                ...state,
                loadingAdd: false,
                profiles: [...state.profiles, action.payload.creditCardDetails]
            };
        case PaymentActionTypes.AddCreditCardError:
            return {
                ...state,
                loadingAdd: false
            };
        case PaymentActionTypes.LoadTransactionHistory:
            return {
                ...state,
                loadingTransactionHistory: true
            };
        case PaymentActionTypes.TransactionHistoryLoaded:
            return {
                ...state,
                loadingTransactionHistory: false,
                transactionHistory: action.payload.transactionHistory
            };
        case PaymentActionTypes.LoadPaymentSettingsSuccess:
            return {
                ...state,
                settings: action.payload.settings
            };
        case PaymentActionTypes.UpdatePaymentSettingsSuccess:
            const updatedSettings = state.settings;
            updatedSettings.forEach(setting => {
                const update = action.payload.settings.find(s => s.priceLevelId === setting.vendorId);
                if (update) {
                    update.paymentMethods.forEach(pm => {
                        setting.paymentMethods.find(spm => spm.id === pm).hasPayment = action.payload.enable;
                    });
                }
            });
            return {
                ...state,
                settings: [...updatedSettings]
            };
        case PaymentActionTypes.UpdatePaymentSettingsBatchSuccess:
            const updatedSettingsBatch = state.settings;
            updatedSettingsBatch.forEach(setting => {
                setting.paymentMethods.find(pm => pm.id === action.payload.paymentMethod).hasPayment = action.payload.enable;
            });
            return {
                ...state,
                settings: [...updatedSettingsBatch]
            };
        case PaymentActionTypes.LoadStripeSuccess:
            return {
                ...state,
                stripeDetails: action.payload
            };
        default:
            return state;
    }
}
