import { createSelector } from '@ngrx/store';

export const selectRewardsState = state => state.rewards;

export const getWallet = createSelector(
    selectRewardsState,
    state => state.wallet
);

export const getPaymentWallet = createSelector(
    selectRewardsState,
    state => state.paymentWallet
);

export const getRewardsLoading = createSelector(
    selectRewardsState,
    state => state.loading
);

export const getRewardRules = createSelector(
    selectRewardsState,
    state => state.rules
);

export const getRewardTokens = createSelector(
    selectRewardsState,
    state => state.tokens
);

export const getEditRule = createSelector(
    selectRewardsState,
    state => state.editRule
);

