import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'generalFilter',
  pure: false
})
export class GeneralFilterPipe implements PipeTransform {

  transform(items: any, property: string, filter: any, firstResult: boolean): any {
    if (!items || !filter) {
      return items;
    }
    
    return firstResult ? items.find(i => i[property] === filter) : items.filter(i => i[property] === filter);
  }

}
