
import { createSelector } from '@ngrx/store';

export const selectAuctionState = state => state.auctions;

export const selectLoadingAuctions = createSelector(
    selectAuctionState,
    state => state.loading
);

export const selectAuctions = createSelector(
    selectAuctionState,
    state => state.auctions
);

export const selectSavingAuction = createSelector(
    selectAuctionState,
    state => state.saving
);

export const selectAuctionOffers = createSelector(
    selectAuctionState,
    state => state.auctionOffers
);
