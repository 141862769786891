import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
    selector: 'app-tl-carousel',
    templateUrl: './tl-carousel.component.html',
    styleUrls: ['./tl-carousel.component.scss']
})
export class TlCarouselComponent implements OnInit {
    @Input() verticalDisplay: boolean;
    @Input() startFromIndex: number;
    @Input() images: Array<any>;
    @Output() changeImage = new EventEmitter<number>();

    originalList: Array<any>;
    displayList: Array<any>;
    selectedIndex: number;

    constructor() { }

    ngOnInit(): void {
        this.originalList = new Array<any>();
        this.images.forEach(image => {
            this.originalList.push(image);
        });
        this.selectedIndex = this.startFromIndex;
        this.displayList = this.images.slice(this.selectedIndex, this.images.length).concat(this.images.slice(0, this.selectedIndex));
    }

    onChangeImage(selectedImageIndex: number) {
        this.selectedIndex = selectedImageIndex;
        this.displayList = this.displayList.slice(this.selectedIndex, this.displayList.length)
            .concat(this.displayList.slice(0, this.selectedIndex));

        const activeImage = this.displayList[0];

        this.changeImage.emit(this.originalList.findIndex(image => image.id === activeImage.id));
    }

    goLeft() {
        this.onChangeImage(this.displayList.length - 1);
    }

    goRight() {
        this.onChangeImage(1);
    }

}
