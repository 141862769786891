import { Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { AppState } from 'src/app/app.reducer';
import { TemplateEnum } from 'src/app/core/enums/template.enum';
import { currentUserDetails } from 'src/app/shared/selectors/auth.selector';
import { getThemeId } from 'src/app/shared/selectors/branding.selector';

@Component({
    selector: 'app-page-with-sidebar-default',
    templateUrl: './page-with-sidebar.default.component.html',
    styleUrls: ['./page-with-sidebar.default.component.scss']
})
export class PageWithSidebarDefaultComponent implements OnInit {

    isSidebarOpen = true;
    currentUserDetails$: Observable<any>;
    @Input() addContentPadding = true;

    constructor(private store: Store<AppState>) { }

    ngOnInit() {
        this.currentUserDetails$ = this.store.select(currentUserDetails);
    }

    toggleSidebar() {
        this.isSidebarOpen = !this.isSidebarOpen;
    }

    onHideFilter() {
        const pageSidebar = document.getElementsByClassName('page__sidebar')[0];
        if (pageSidebar) {
            pageSidebar.classList.remove('page__sidebar--visible');
        }
        document.body.classList.remove('body-overflow-none');
    }

}
