import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-icon-main-product',
    templateUrl: './icon-main-product.component.html'
})
export class IconMainProductComponent implements OnInit {

    constructor() { }

    ngOnInit() {
    }

}
