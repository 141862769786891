import { Component, OnInit, Output, EventEmitter, Input, OnChanges } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/app.reducer';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { LoadSites } from '../../actions/site.actions';
import { sitesList } from '../../selectors/site.selector';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { TemplateEnum } from 'src/app/core/enums/template.enum';
import { getThemeId } from '../../selectors/branding.selector';

@Component({
    selector: 'app-customer-price-level',
    templateUrl: './customer-price-level.component.html',
    styleUrls: ['./customer-price-level.component.scss']
})
export class CustomerPriceLevelComponent implements OnInit, OnChanges {

    statuses$: Observable<any[]>;
    private statuses = [];
    search: string;
    templates$: Observable<TemplateEnum>;
    templateEnum = TemplateEnum;

    @Input() priceLevels: number[] = [];
    @Input() changeTitle: boolean;
    @Output() change = new EventEmitter<any>();

    constructor(
        private store: Store<AppState>
    ) { }

    ngOnInit() {
        this.templates$ = this.store.select(getThemeId);
        this.store.dispatch(new LoadSites());
    }

    ngOnChanges() {
        this.statuses$ = this.store.select(sitesList)
            .pipe(
                map((statuses) => statuses.map((s) => ({
                    ...s,
                    isSelected: this.priceLevels.includes(s.id)
                }))),
                tap(statuses => this.statuses = statuses)
            );
    }

    onSelect($event: MatCheckboxChange, priceLevel) {
        if ($event.checked) {
            this.priceLevels.push(priceLevel.id);
        } else {
            const idx = this.priceLevels.findIndex(s => s === priceLevel.id);
            this.priceLevels.splice(idx, 1);
        }

        this.emitSelect(this.priceLevels);
    }

    emitSelect(priceLevels: Array<number>) {
        this.change.emit(priceLevels);
    }

    onNameClick(priceLevel, $event?) {
        if ($event) {
            $event.preventDefault();
        }

        this.change.emit([priceLevel.id]);
    }

    onSelectAll() {
        this.change.emit([]);
    }

    getPlaceholder() {
        return this.changeTitle ? 'Search Price Groups' : 'Search Price Levels';
    }
}
