import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { Action, Store } from '@ngrx/store';
import {
    ProductActionTypes, LoadProducts, LoadProductsSuccess, SaveProductSettings, SaveProductSettingsSuccess, SaveProduct,
    SaveProductSuccess, LoadProductDetails, LoadProductDetailsSuccess, SavePriceLevelsForProduct,
    SavePriceLevelsForProductSuccess, LoadBaseCatalogsSuccess,
    LoadCategoryOptionsSuccess, LoadUomOptionsSuccess, SaveProductCatalog, SaveProductCatalogSuccess, LoadConvertedPrice,
    UploadPrices, UploadPricesSuccess,
    UploadPricesFinish, UploadPricesSuccessFinish, LoadPriceLevels, LoadPriceLevelsSuccess,
    LoadConvertedPriceSuccess, LoadRelatedUom, LoadRelatedUomSuccess, ExportProducts, ExportProductsSuccess, AddCount,
    AddCountSuccess, AddCountFailed, AddPar, AddParSuccess, AddParFailed, ConvertProductUom, ConvertProductUomFailed,
    ConvertProductUomSuccess,
    LoadAllCategoriesSuccess,
    SavePriceLevel,
    SavePriceLevelSuccess,
    UpdatePriceLevel,
    UpdatePriceLevelSuccess,
    SaveProductSettingsFailed,
    SaveProductInventorySettings,
    SaveProductInventorySettingsSuccess,
    LoadProductsForCompositionSuccess,
    LoadProductsForComposition,
    AddProductForComposition,
    AddProductForCompositionSuccess,
    CreateProductComposition,
    EditProductComposition,
    EditProductCompositionSuccess,
    CreateProductCompositionSuccess,
    DeleteProductComposition,
    DeleteProductCompositionSuccess,
    LoadProductComposition,
    LoadProductCompositionSuccess,
    AddProductForCompositionFailed,
    DeleteProductCompositionFailed,
    CreateProductCompositionFailed,
    EditProductCompositionFailed,
    LoadPreviewInheritProduct,
    SaveCalculateFromParent,
    SetVariationAsParent,
    SetVariantAsParentSuccess,
    SaveProductFailed,
    SaveCalculateFromSuccess
} from '../actions/product.actions';
import { mergeMap, map, catchError, switchMap } from 'rxjs/operators';
import { LoadCategoriesSuccess } from '../actions/product.actions';
import { ProductService } from 'src/app/core/services/product/product.service';
import { CategoryModel } from 'src/app/core/models/product/category.model';
import { ProductDetailsModel } from 'src/app/core/models/product/product-details.model';
import { ProductCatalogModel } from 'src/app/core/models/product/product-catalog.model';
import { UnitOfMeasureModel } from 'src/app/core/models/product/unit-of-measure.model';
import { CategoryOptionModel } from 'src/app/core/models/product/category-option.model';
import { ToggleInfobar } from '../actions/infobar.actions';
import { AppState } from 'src/app/app.reducer';
import { ConvertedPriceModel } from 'src/app/core/models/product/converted-price.model';
import { ProductUploadResponseModel } from 'src/app/core/models/product/upload-response.model';
import * as moment from 'moment';
import { ProductParCountModel } from 'src/app/core/models/product/product-par-count.model';
import { NotifierService } from 'angular-notifier';
import { PriceLevelInfo } from 'src/app/core/models/product/price-level-info.model';
import { ProductCompositionModel } from 'src/app/core/models/product/product-composition.model';
import { MatDialog } from '@angular/material/dialog';
import { GlobalProductModel } from 'src/app/core/models/product/global-product.model';

@Injectable()
export class ProductEffects {
    constructor(
        private actions$: Actions,
        private productService: ProductService,
        private store: Store<AppState>,
        private notifier: NotifierService,
        private matDialog: MatDialog
    ) { }

    @Effect()
    loadCategories$: Observable<Action> = this.actions$.pipe(
        ofType(ProductActionTypes.LoadCategories),
        mergeMap(() => this.productService.getCategoriesSearch().pipe(
            map((data: Array<CategoryModel>) => {
                return new LoadCategoriesSuccess(data);
            })
        ))
    );

    @Effect()
    loadAllCategories$: Observable<Action> = this.actions$.pipe(
        ofType(ProductActionTypes.LoadAllCategories),
        mergeMap(() => this.productService.getAllCategories().pipe(
            map((data: Array<CategoryModel>) => {
                return new LoadAllCategoriesSuccess(data);
            })
        ))
    );

    @Effect()
    loadProducts$: Observable<Action> = this.actions$.pipe(
        ofType(ProductActionTypes.LoadProducts),
        switchMap((action: LoadProducts) => this.productService.getProducts(action.payload).pipe(
            map((products: ProductDetailsModel) => {
                return new LoadProductsSuccess(products);
            })
        ))
    );

    @Effect()
    saveProductSettings$: Observable<Action> = this.actions$.pipe(
        ofType(ProductActionTypes.SaveProductSettings),
        mergeMap((action: SaveProductSettings) => this.productService.saveProductSettings(action.payload).pipe(
            map(() => new SaveProductSettingsSuccess(action.payload.productId)),
            catchError(err => {
                this.notifier.notify('error', err.error.message);
                return of(new SaveProductSettingsFailed());
            }),
        ))
    );

    @Effect()
    saveProduct$: Observable<Action> = this.actions$.pipe(
        ofType(ProductActionTypes.SaveProduct),
        mergeMap((action: SaveProduct) => this.productService.saveProduct(action.payload).pipe(
            map((data: ProductCatalogModel) => { return new SaveProductSuccess(data) }),
            catchError(err => {
                this.notifier.notify('error', err.error.message);
                return of(new SaveProductFailed(action.payload));
            })
        ))
    );

    @Effect()
    saveCalculateFromParent$: Observable<any> = this.actions$.pipe(
        ofType(ProductActionTypes.SaveCalculateFromParent),
        mergeMap((action: SaveCalculateFromParent) => this.productService.saveCalculateFromParent(action.payload).pipe(
            map((data: any) => { return new SaveCalculateFromSuccess(data) })
        ))
    );

    @Effect()
    loadProductCatalog$: Observable<Action> = this.actions$.pipe(
        ofType(ProductActionTypes.LoadProductDetails),
        mergeMap((action: LoadProductDetails) => this.productService.loadPriceLevelsForProduct(action.payload).pipe(
            map((data: ProductCatalogModel) => new LoadProductDetailsSuccess(data))
        ))
    );

    @Effect()
    saveBaseCatalog$: Observable<Action> = this.actions$.pipe(
        ofType(ProductActionTypes.SavePriceLevelsForProduct),
        mergeMap((action: SavePriceLevelsForProduct) => this.productService.savePriceLevelsForProduct(action.payload).pipe(
            map((data: ProductCatalogModel) => {
                this.store.dispatch(new ToggleInfobar({
                    open: false
                }));

                return new SavePriceLevelsForProductSuccess(data);
            })
        ))
    );

    @Effect()
    convertedPrice$: Observable<Action> = this.actions$.pipe(
        ofType(ProductActionTypes.LoadConvertedPrice),
        mergeMap((action: LoadConvertedPrice) => this.productService.getConvertedPrice(action.payload).pipe(
            map((data: Array<ConvertedPriceModel>) => {
                if (action.payload[0].productId) {
                    data[0].productId = action.payload[0].productId;
                }
                return new LoadConvertedPriceSuccess(data);
            })
        ))
    );

    @Effect()
    loadBaseCatalogs$: Observable<Action> = this.actions$.pipe(
        ofType(ProductActionTypes.LoadBaseCatalogs),
        mergeMap(() => this.productService.getBaseCatalogs().pipe(
            map((data: Array<PriceLevelInfo>) => new LoadBaseCatalogsSuccess(data))
        ))
    );

    @Effect()
    loadCategoryOptions$: Observable<Action> = this.actions$.pipe(
        ofType(ProductActionTypes.LoadCategoryOptions),
        mergeMap(() => this.productService.getCategoryOptions().pipe(
            map((data: Array<CategoryOptionModel>) => new LoadCategoryOptionsSuccess(data))
        ))
    );

    @Effect()
    loadUomOptions$: Observable<Action> = this.actions$.pipe(
        ofType(ProductActionTypes.LoadUomOptions),
        mergeMap(() => this.productService.getUomOptions().pipe(
            map((data: Array<UnitOfMeasureModel>) => new LoadUomOptionsSuccess(data))
        ))
    );

    @Effect()
    saveProductCatalog$: Observable<Action> = this.actions$.pipe(
        ofType(ProductActionTypes.SaveProductCatalog),
        mergeMap((action: SaveProductCatalog) => this.productService.saveProductCatalog(action.payload).pipe(
            map((data: ProductCatalogModel) => {
                this.store.dispatch(new ToggleInfobar({
                    open: false
                }));
                return new SaveProductCatalogSuccess(data);
            })
        ))
    );

    @Effect()
    addProductForComposition$: Observable<Action> = this.actions$.pipe(
        ofType(ProductActionTypes.AddProductForComposition),
        mergeMap((action: AddProductForComposition) => this.productService.saveProductForComposition(action.payload).pipe(
            map((response: any) => {
                const modalInstance = this.matDialog.openDialogs.find(d => d.id === 'add-product-for-composition');
                if (modalInstance) {
                    const productItemComposition = new GlobalProductModel();
                    productItemComposition.productId = response.productId;
                    productItemComposition.imageUrl = response.imageUrl;
                    productItemComposition.sku = response.vendorSku;
                    productItemComposition.productName = response.name;
                    productItemComposition.uomName = response.uomName;

                    modalInstance.close(productItemComposition);
                }

                return new AddProductForCompositionSuccess();
            }),
            catchError(err => {
                this.notifier.notify('error', err.error.message);
                return of(new AddProductForCompositionFailed());
            })
        ))
    );

    @Effect()
    loadRelatedUom$: Observable<Action> = this.actions$.pipe(
        ofType(ProductActionTypes.LoadRelatedUom),
        mergeMap((action: LoadRelatedUom) => this.productService.getRelatedUom(action.payload).pipe(
            map((data: Array<UnitOfMeasureModel>) => new LoadRelatedUomSuccess(data))
        ))
    );

    @Effect()
    uploadPrices$: Observable<Action> = this.actions$.pipe(
        ofType(ProductActionTypes.UploadPrices),
        mergeMap((action: UploadPrices) => this.productService.uploadProductsPrices(action.payload).pipe(
            map((data: ProductUploadResponseModel) => new UploadPricesSuccess(data))
        ))
    );

    @Effect()
    saveUpload$: Observable<Action> = this.actions$.pipe(
        ofType(ProductActionTypes.UploadPricesFinish),
        mergeMap((action: UploadPricesFinish) => this.productService.saveUploadProductsPrices(action.payload).pipe(
            map(() => {
                this.store.dispatch(new ToggleInfobar({
                    open: false
                }));

                return new UploadPricesSuccessFinish();
            })
        ))
    );

    @Effect()
    loadPriceLevels$ = this.actions$.pipe(
        ofType<LoadPriceLevels>(ProductActionTypes.LoadPriceLevels),
        mergeMap(() => this.productService.getPriceLevels().pipe(
            map((priceLevels: Array<PriceLevelInfo>) => new LoadPriceLevelsSuccess(priceLevels))
        ))
    );

    @Effect()
    exportProducts$: Observable<Action> = this.actions$.pipe(
        ofType(ProductActionTypes.ExportProducts),
        mergeMap((action: ExportProducts) => this.productService.exportProducts(action.payload).pipe(
            map((data: any) => {
                const filename = 'Products_Export ' + moment(new Date().toString()).format('MMMM Do YYYY, h:mm:ss a') + '.csv';
                const blob = new Blob([data], { type: 'application/vnd.ms.excel' });

                if (window.navigator.msSaveOrOpenBlob) {
                    window.navigator.msSaveOrOpenBlob(blob, filename);
                } else {
                    const url = window.URL.createObjectURL(blob);
                    const a = document.createElement('a');
                    document.body.appendChild(a);
                    a.href = url;
                    a.download = filename;
                    a.click();
                    setTimeout(() => {
                        window.URL.revokeObjectURL(url);
                        document.body.removeChild(a);
                    }, 0);
                }

                return new ExportProductsSuccess(data);
            })
        ))
    );

    @Effect()
    addProductCount$ = this.actions$.pipe(
        ofType(ProductActionTypes.AddCount),
        mergeMap((action: AddCount) => this.productService.addProductCount(action.payload.warehouseId,
            action.payload.locationId, action.payload.count).pipe(
                map((data: any) => {
                    this.notifier.notify('success', 'Count was added/updated.');
                    return new AddCountSuccess({ count: data.warehouseCount, onHandTotal: data.onHand, onOrderTotal: data.OnOrder, liveInventory: data.liveInventory });
                }),
                catchError(err => {
                    this.notifier.notify('error', err.error.message);
                    return of(new AddCountFailed());
                })),
        )
    );

    @Effect()
    addProductPar$ = this.actions$.pipe(
        ofType(ProductActionTypes.AddPar),
        mergeMap((action: AddPar) => this.productService.addProductPar(action.payload.warehouseId,
            action.payload.locationId, action.payload.parTemplateId, action.payload.par).pipe(
                map((data: ProductParCountModel) => {
                    this.notifier.notify('success', 'Par was added/updated.');
                    return new AddParSuccess(data);
                }),
                catchError(err => {
                    this.notifier.notify('error', err.error.message);
                    return of(new AddParFailed());
                })),
        )
    );

    @Effect()
    convertProductUom$ = this.actions$.pipe(
        ofType(ProductActionTypes.ConvertProductUom),
        mergeMap((action: ConvertProductUom) => this.productService.convertProductUom(
            action.payload.uomId, action.payload.uomSize, action.payload.productId).pipe(
                map((data: any) => {
                    this.notifier.notify('success', 'Product UOM update successfully.');
                    return new ConvertProductUomSuccess({ onHand: data.onHand, productId: data.productId });
                }),
                catchError(err => {
                    this.notifier.notify('error', err.error.message);
                    return of(new ConvertProductUomFailed());
                })
            )
        )
    );

    @Effect()
    savePriceLevel$: Observable<Action> = this.actions$.pipe(
        ofType(ProductActionTypes.SavePriceLevel),
        mergeMap((action: SavePriceLevel) => this.productService.savePriceLevel(action.payload).pipe(
            map((priceLevel: PriceLevelInfo) => {
                this.store.dispatch(new ToggleInfobar({
                    open: false
                }));

                return new SavePriceLevelSuccess(priceLevel);
            })
        ))
    );

    @Effect()
    updatePriceLevel$: Observable<Action> = this.actions$.pipe(
        ofType(ProductActionTypes.UpdatePriceLevel),
        mergeMap((action: UpdatePriceLevel) => this.productService.updatePriceLevel(action.payload).pipe(
            map((priceLevel: PriceLevelInfo) => {
                this.store.dispatch(new ToggleInfobar({
                    open: false
                }));

                return new UpdatePriceLevelSuccess(priceLevel);
            })
        ))
    );

    @Effect()
    saveProductInventorySettings$: Observable<Action> = this.actions$.pipe(
        ofType(ProductActionTypes.SaveProductInventorySettings),
        mergeMap((action: SaveProductInventorySettings) => this.productService
            .saveProductInventorySettings(action.payload.warehouseId, action.payload.locationId, action.payload.settings).pipe(
                map(() => new SaveProductInventorySettingsSuccess())
            ))
    );

    @Effect()
    loadProductsForComposition$: Observable<Action> = this.actions$.pipe(
        ofType(ProductActionTypes.LoadProductsForComposition),
        mergeMap((action: LoadProductsForComposition) => this.productService.getProductsGlobalList(action.payload.keyword,
            action.payload.excludedProductsIds).pipe(
                map((response: Array<GlobalProductModel>) => new LoadProductsForCompositionSuccess(response))
            ))
    );

    @Effect()
    createProductComposition$: Observable<Action> = this.actions$.pipe(
        ofType(ProductActionTypes.CreateProductComposition),
        mergeMap((action: CreateProductComposition) => this.productService.createComposition(action.payload).pipe(
            map((response: ProductCompositionModel) => {
                this.store.dispatch(new ToggleInfobar({
                    open: false
                }));

                return new CreateProductCompositionSuccess(response);
            }),
            catchError(err => {
                this.notifier.notify('error', err.error.message);
                return of(new CreateProductCompositionFailed());
            })
        ))
    );

    @Effect()
    editComposition$: Observable<Action> = this.actions$.pipe(
        ofType(ProductActionTypes.EditProductComposition),
        mergeMap((action: EditProductComposition) => this.productService.editComposition(action.payload).pipe(
            map((response: any) => {
                this.store.dispatch(new ToggleInfobar({
                    open: false
                }));

                return new EditProductCompositionSuccess(response);
            }),
            catchError(err => {
                this.notifier.notify('error', err.error.message);
                return of(new EditProductCompositionFailed());
            })
        ))
    );

    @Effect()
    deleteComposition$: Observable<Action> = this.actions$.pipe(
        ofType(ProductActionTypes.DeleteProductComposition),
        mergeMap((action: DeleteProductComposition) => this.productService.deleteComposition(action.payload.compositionId).pipe(
            map(() => {
                this.store.dispatch(new ToggleInfobar({
                    open: false
                }));

                this.notifier.notify('success', 'Product composition was successfully deleted.');

                return new DeleteProductCompositionSuccess(action.payload.productId);
            }),
            catchError(err => {
                this.notifier.notify('error', err.error.message);
                return of(new DeleteProductCompositionFailed());
            })
        ))
    );

    @Effect()
    loadProductComposition$: Observable<Action> = this.actions$.pipe(
        ofType(ProductActionTypes.LoadProductComposition),
        mergeMap((action: LoadProductComposition) => this.productService.loadProductComposition(action.payload.productId,
            action.payload.productCompositionId).pipe(
                map((response: ProductCompositionModel) => new LoadProductCompositionSuccess(response))
            ))
    );

    @Effect()
    loadPreviewInheritProduct$: Observable<any> = this.actions$.pipe(
        ofType(ProductActionTypes.LoadPreviewInheritProduct),
        switchMap((action: LoadPreviewInheritProduct) => this.productService.loadPreviewInheritProduct(action.payload).pipe(
            map((products) => {
                return products;
            })
        ))
    );

    @Effect()
    setVariationAsParent$: Observable<Action> = this.actions$.pipe(
        ofType(ProductActionTypes.SetVariationAsParent),
        switchMap((action: SetVariationAsParent) => this.productService.setVariationAsParent(action.payload.mainProductId, action.payload.productId).pipe(
            map(() => new SetVariantAsParentSuccess(action.payload))
        ))
    );
}
