import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Cacheable } from 'ngx-cacheable';
import { Observable, Subject } from 'rxjs';
import { SelectedVendorRequest } from '../../models/vendor/selected-vendor-request.model';

const vendorsCacheBuster$ = new Subject<void>();

@Injectable({
  providedIn: 'root'
})
export class VendorService {

  constructor(
    private http: HttpClient
  ) { }

  setVendorFavorite(vendorId: number, isFavorite: boolean) {
    return this.http.post(`${environment.api.content}/Vendor/${vendorId}/set_favorite`, {
      isFavorite
    });
  }

  // @Cacheable({
  //   cacheBusterObserver: vendorsCacheBuster$
  // })
  getVendors() {
    return this.http.get(`${environment.api.content}/Vendor`);
  }

  getVendorsList(params: any) {
    return this.http.post(`${environment.api.content}/Vendor/search`, {
      page: params.page,
      categories: params.categories,
      isMasterCategory: params.isMasterCategory,
      type: params.type,
      keyword: params.keyword
    });
  }

  manageRelation(params: any) {
    return this.http.post(`${environment.api.content}/Vendor/manageRelation`, {
      vendorId: params.vendorId,
      vendorSiteId: params.vendorSiteId,
      vendorAccountId: params.vendorAccountId,
      action: params.action,
      operationType: params.operationType,
      isFinalized: params.isFinalized,
      vendorRequestId: params.vendorRequestId,
    });
  }

  selectVendor(vendorId: number, selectedVendorRequest: SelectedVendorRequest): Observable<any> {
      return this.http.post(`${environment.api.content}/Vendor/${vendorId}/set_favorite_selected`, selectedVendorRequest);
  }

  @Cacheable({
    cacheBusterObserver: vendorsCacheBuster$
  })
  getCategories() {
    return this.http.get(`${environment.api.content}/Category/search`);
  }

  resetCache() {
    vendorsCacheBuster$.next();
  }

}
