import { Directive, OnInit, ElementRef } from '@angular/core';
import { AppState } from 'src/app/app.reducer';
import { Store } from '@ngrx/store';
import { currentUserDetails } from '../selectors/auth.selector'
import { AppUser } from 'src/app/core/models/user.model';

@Directive({
    selector: '[appIsGuest]'
})
export class IsGuestDirective implements OnInit {

    constructor(
        private store: Store<AppState>,
        private elementRef: ElementRef
    ) { }


    ngOnInit(): void {
        this.store.select(currentUserDetails).subscribe((userDetails: AppUser) => {
            if (userDetails) {
                this.elementRef.nativeElement.style.display = userDetails.isGuest ? 'none' : 'flex';
            }
        });
    }
}
