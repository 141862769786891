import { RegisterActionTypes, RegisterActions } from '../actions/register.actions';


export const registerFeatureKey = 'register';

export interface RegisterState {
    data: any;
    loading: boolean;
    loadingVerifyAccount: boolean;
    redirecting: boolean;
    verifyAccountError: string;
    loadingProfiles: boolean;
}

export const initialState: RegisterState = {
    data: undefined,
    loading: false,
    loadingVerifyAccount: false,
    redirecting: false,
    verifyAccountError: undefined,
    loadingProfiles: false
};

export function registerReducer(
    state = initialState,
    action: RegisterActions): RegisterState {
    switch (action.type) {
        case RegisterActionTypes.Register:
            return {
                ...state,
                data: action.payload.account,
                loading: true
            };
        case RegisterActionTypes.UpdateRegisterLoading:
            return {
                ...state,
                loading: action.payload.loading
            };
        case RegisterActionTypes.RegisterInfo:
            return {
                ...state,
                loading: true
            };
        case RegisterActionTypes.VerifyAccount:
            return {
                ...state,
                loadingVerifyAccount: true
            };
        case RegisterActionTypes.VerifyAccountSuccess:
            return {
                ...state,
                loadingVerifyAccount: false,
                redirecting: true
            };
        case RegisterActionTypes.VerifyAccountError:
            return {
                ...state,
                loadingVerifyAccount: false,
                redirecting: false,
                verifyAccountError: action.payload.message
            };
        default:
            return state;
    }
}
