import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-icon-small-arrow',
    templateUrl: './icon-small-arrow.component.html'
})
export class IconSmallArrowComponent implements OnInit {

    constructor() { }

    ngOnInit() {
    }

}
