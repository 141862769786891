import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-icon-verified',
    templateUrl: './icon-verified.component.html'
})
export class IconVerifiedComponent implements OnInit {

    constructor() { }

    ngOnInit() {
    }

}
