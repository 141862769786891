export class CompanyDetailsRequestModel {
    companyName: string;
    phone: string;
    email: string;
    webSite: string;
    timezoneId: number;

    constructor(data?: any) {
        if (data) {
            this.companyName = data.name;
            this.phone = data.phone;
            this.email = data.email;
            this.webSite = data.webSite;
            this.timezoneId = data.timezonId;
        }
    }
}
