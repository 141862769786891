export enum PAYMENT_STATUS {
    NotPaid = 1,
    PartialyPaid = 2,
    Paid = 3,
    Authorized = 4,
    Canceled = 5,
    Refunded = 6,
    NotAuthorized = 7,
    Captured = 8,
    ReAuthorized = 9,
    PartialRefunded = 11
}
