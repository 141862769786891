import { Component, EventEmitter, Input, OnInit, Output, Renderer2 } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { EntityType } from 'src/app/core/enums/entity-type.enum';
import { VendorModel } from 'src/app/core/models/vendor/vendor.model';

@Component({
    selector: 'app-vendors-filter-temp1',
    templateUrl: './vendors-filter.temp1.component.html',
    styleUrls: ['./vendors-filter.temp1.component.scss']
})
export class VendorsFilterTemp1Component implements OnInit {
    @Input() showItemsCount: number;
    @Input() showMore: boolean;
    @Input() cutoffDetails: Map<number, any>;
    @Input() vendors: Array<VendorModel>;
    @Input() DEFAULT_VENDORS_COUNT: number;
    @Input() selectedVendors: number[] = [];
    @Output() selectAll = new EventEmitter();
    @Output() selectFavorite = new EventEmitter();
    @Output() select = new EventEmitter<Array<number>>();
    @Output() nameClick = new EventEmitter<any>();
    @Output() setVendorFavorite = new EventEmitter<any>();
    @Output() openConversation = new EventEmitter<any>();

    EntityType = EntityType;

    private enteredButton = false;
    private isMatMenuOpen = false;
    private prevButtonTrigger;

    constructor(private ren: Renderer2) { }

    ngOnInit(): void {
    }

    onSelectAll() {
        this.selectAll.emit();
    }

    onSelectFavorite() {
        this.selectFavorite.emit();
    }

    onSelect($event: MatCheckboxChange, vendor) {
        if ($event.checked) {
            this.selectedVendors.push(vendor.customerSiteID);
        } else {
            const idx = this.selectedVendors.findIndex(v => v === vendor.customerSiteID);
            this.selectedVendors.splice(idx, 1);
        }

        this.select.emit(this.selectedVendors);
    }

    buttonEnter(trigger) {
        setTimeout(() => {
            if (this.prevButtonTrigger && this.prevButtonTrigger !== trigger) {
                this.prevButtonTrigger.closeMenu();
                this.prevButtonTrigger = trigger;
                this.isMatMenuOpen = false;
                trigger.openMenu();
            } else if (!this.isMatMenuOpen) {
                this.enteredButton = true;
                this.prevButtonTrigger = trigger;
                trigger.openMenu();
            } else {
                this.enteredButton = true;
                this.prevButtonTrigger = trigger;
            }
        });
    }

    buttonLeave(trigger, button) {
        setTimeout(() => {
            if (this.enteredButton && !this.isMatMenuOpen) {
                trigger.closeMenu();
                this.ren.removeClass(button, 'cdk-focused');
                this.ren.removeClass(button, 'cdk-program-focused');
            }
            if (!this.isMatMenuOpen) {
                trigger.closeMenu();
                this.ren.removeClass(button, 'cdk-focused');
                this.ren.removeClass(button, 'cdk-program-focused');
            } else {
                this.enteredButton = false;
            }
        }, 100);
    }


    onNameClick($event, vendor: VendorModel) {
        $event.preventDefault();
        this.nameClick.emit(vendor);
    }

    menuenter() {
        this.isMatMenuOpen = true;
    }

    menuLeave(trigger, button) {
        setTimeout(() => {
            if (!this.enteredButton) {
                this.isMatMenuOpen = false;
                trigger.closeMenu();
                this.ren.removeClass(button, 'cdk-focused');
                this.ren.removeClass(button, 'cdk-program-focused');
            } else {
                this.isMatMenuOpen = false;
            }
        }, 80);
    }

    onSetVendorFavorite($event: any, vendor: any) {
        $event.stopPropagation();

        this.setVendorFavorite.emit(vendor);
    }

    openConversationWithCustomer(vendor) {
        this.openConversation.emit(vendor);
    }

    toggleMore() {
        this.showMore = !this.showMore;
        this.showItemsCount = this.showMore ? this.vendors.length : this.DEFAULT_VENDORS_COUNT;
    }
}
