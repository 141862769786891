import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-icon-message-out',
  templateUrl: './icon-message-out.component.html',
  styleUrls: ['./icon-message-out.component.scss']
})
export class IconMessageOutComponent implements OnInit {
  
  @Input() cssClass = '';

  constructor() { }

  ngOnInit() {
  }

}
