import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SubmitOrderSuccessModel } from 'src/app/core/models/order-guide/submit-order-success.model';

@Component({
    selector: 'app-order-success-b2b2c',
    templateUrl: './order-success.b2b2c.component.html',
    styleUrls: ['./order-success.b2b2c.component.scss']
})
export class OrderSuccessB2b2cComponent implements OnInit {

    @Input() order: SubmitOrderSuccessModel;
    @Input() title: string;
    @Output() printOrder: EventEmitter<number> = new EventEmitter<number>();
    @Output() closeWindow: EventEmitter<void> = new EventEmitter<void>();

    constructor() { }

    ngOnInit(): void {
    }

    onPrintOrder(orderId: number): void {
        this.printOrder.emit(orderId);
    }

    close(): void {
        this.closeWindow.emit();
    }
}
