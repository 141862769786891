import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'app-icon-protection-interior',
    templateUrl: './icon-protection-interior.component.html'
})
export class IconProtectionInteriorComponent implements OnInit {

    @Input() cssClass = 'icon--18';

    constructor() { }

    ngOnInit() {
    }

}
