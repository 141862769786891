import { Action } from '@ngrx/store';
import { PaymentSetting } from 'src/app/core/models/payment/payment-setting.model';
import { PaymentSettingRequest } from 'src/app/core/models/payment/payment-setting-request.model';
import { PAYMENT_METHOD } from 'src/app/core/enums/payment-method.enum';
import { PaymentProfileModel } from 'src/app/core/models/cart/payment-profile.model';
import { StripeDetailsModel } from 'src/app/core/models/payment/stripe-details.model';
import { CreditCardModel } from 'src/app/core/models/credit-card/credit-card.model';
import { AddressModel } from 'src/app/core/models/customer/address.model';

export enum PaymentActionTypes {
    AddCreditCard = '[Modal] AddCreditCard',
    AddCreditCardSuccess = '[Modal] AddCreditCardSuccess',
    AddCreditCardError = '[Modal] AddCreditCardError',
    LoadPaymentProfiles = '[Cart Payment Modal] LoadPaymentProfiles',
    LoadPaymentProfilesSuccess = '[Cart Payment Modal] LoadPaymentProfilesSuccess',
    DeletePaymentProfile = '[Global] DeletePaymentProfile',
    DeletePaymentProfileSuccess = '[Global] DeletePaymentProfileSuccess',
    DeletePaymentProfileError = '[Global] DeletePaymentProfileError',
    LoadTransactionHistory = '[Payment Info] LoadTransactionHistory',
    TransactionHistoryLoaded = '[Payment Info Effect] TransactionHistoryLoaded',
    LoadPaymentSettings = '[PaymentSettings] LoadPaymentSettings',
    LoadPaymentSettingsSuccess = '[PaymentSettings] LoadPaymentSettingsSuccess',
    UpdatePaymentSettings = '[PaymentSettings] UpdatePaymentSettings',
    UpdatePaymentSettingsSuccess = '[PaymentSettings] UpdatePaymentSettingsSuccess',
    UpdatePaymentSettingsBatch = '[PaymentSettings] UpdatePaymentSettingsBatch',
    UpdatePaymentSettingsBatchSuccess = '[PaymentSettings] UpdatePaymentSettingsBatchSuccess',
    BehalfOrderReauthorization = '[Order] Behalf re-authorization',
    BehalfOrderReauthorizationSuccess = '[Order] Behalf re-authorization success',
    LoadStripe = '[Payments] Load Stripe',
    LoadStripeSuccess = '[Payments] Load Stripe Success',
    FinishStripeAccount = '[Payments] Finish Stripe Account',
    FinishStripeAccountSuccess = '[Payments] Finish Stripe Account Success'
}

export class AddCreditCard implements Action {
    readonly type = PaymentActionTypes.AddCreditCard;

    constructor(public payload: { creditCard: CreditCardModel, address: AddressModel; }) { }
}

export class AddCreditCardSuccess implements Action {
    readonly type = PaymentActionTypes.AddCreditCardSuccess;

    constructor(public payload: { creditCardDetails: any; }) { }
}

export class AddCreditCardError implements Action {
    readonly type = PaymentActionTypes.AddCreditCardError;
}


export class LoadPaymentProfiles implements Action {
    readonly type = PaymentActionTypes.LoadPaymentProfiles;
}

export class LoadPaymentProfilesSuccess implements Action {
    readonly type = PaymentActionTypes.LoadPaymentProfilesSuccess;

    constructor(public payload: { profiles: Array<PaymentProfileModel>; }) { }
}

export class DeletePaymentProfile implements Action {
    readonly type = PaymentActionTypes.DeletePaymentProfile;

    constructor(public payload: { profileId: any; }) { }
}

export class DeletePaymentProfileSuccess implements Action {
    readonly type = PaymentActionTypes.DeletePaymentProfile;

    constructor(public payload: { profileId: any; }) { }
}

export class DeletePaymentProfileError implements Action {
    readonly type = PaymentActionTypes.DeletePaymentProfile;

    constructor(public payload: { profileId: any; }) { }
}

export class LoadTransactionHistory implements Action {
    readonly type = PaymentActionTypes.LoadTransactionHistory;

    constructor(public payload: { transactionId: number; }) { }
}

export class TransactionHistoryLoaded implements Action {
    readonly type = PaymentActionTypes.TransactionHistoryLoaded;

    constructor(public payload: { transactionHistory: any[]; }) { }
}

export class LoadPaymentSettings implements Action {
    readonly type = PaymentActionTypes.LoadPaymentSettings;
}

export class LoadPaymentSettingsSuccess implements Action {
    readonly type = PaymentActionTypes.LoadPaymentSettingsSuccess;

    constructor(public payload: { settings: PaymentSetting[]; }) { }
}

export class UpdatePaymentSettings implements Action {
    readonly type = PaymentActionTypes.UpdatePaymentSettings;

    constructor(public payload: { settings: PaymentSettingRequest[], enable: boolean; }) { }
}

export class UpdatePaymentSettingsSuccess implements Action {
    readonly type = PaymentActionTypes.UpdatePaymentSettingsSuccess;

    constructor(public payload: { settings: PaymentSettingRequest[], enable: boolean; }) { }
}

export class UpdatePaymentSettingsBatch implements Action {
    readonly type = PaymentActionTypes.UpdatePaymentSettingsBatch;

    constructor(public payload: { paymentMethod: PAYMENT_METHOD, enable: boolean; }) { }
}

export class UpdatePaymentSettingsBatchSuccess implements Action {
    readonly type = PaymentActionTypes.UpdatePaymentSettingsBatchSuccess;

    constructor(public payload: { paymentMethod: PAYMENT_METHOD, enable: boolean; }) { }
}

export class BehalfOrderReauthorization implements Action {
    readonly type = PaymentActionTypes.BehalfOrderReauthorization;

    constructor(public payload: { orderID: number, transactionID: number, paymentDataBehalf: any; }) { }
}

export class BehalfOrderReauthorizationSuccess implements Action {
    readonly type = PaymentActionTypes.BehalfOrderReauthorizationSuccess;
}

export class LoadStripeSuccess implements Action {
    readonly type = PaymentActionTypes.LoadStripeSuccess;

    constructor(public payload: StripeDetailsModel) { }
}

export class LoadStripe implements Action {
    readonly type = PaymentActionTypes.LoadStripe;
}

export class FinishStripeAccount implements Action {
    readonly type = PaymentActionTypes.FinishStripeAccount;

    constructor(public payload: { merchantAccount: string, accountSecretKey: string; }) { }
}

export class FinishStripeAccountSuccess implements Action {
    readonly type = PaymentActionTypes.FinishStripeAccountSuccess;

    constructor(public payload: any) { }
}

export type PaymentAction =
    AddCreditCard
    | AddCreditCardSuccess
    | AddCreditCardError
    | LoadPaymentProfiles
    | LoadPaymentProfilesSuccess
    | DeletePaymentProfile
    | DeletePaymentProfileSuccess
    | DeletePaymentProfileError
    | LoadTransactionHistory
    | TransactionHistoryLoaded
    | LoadPaymentSettings
    | LoadPaymentSettingsSuccess
    | UpdatePaymentSettings
    | UpdatePaymentSettingsSuccess
    | UpdatePaymentSettingsBatch
    | UpdatePaymentSettingsBatchSuccess
    | LoadStripe
    | LoadStripeSuccess
    | FinishStripeAccount
    | FinishStripeAccountSuccess;
