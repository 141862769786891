import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { AppState } from 'src/app/app.reducer';
import { AccountTypes } from 'src/app/core/enums/account-type.enum';
import { COMPANY_TYPE } from 'src/app/core/enums/company-type.enum';
import { BrandingModel } from 'src/app/core/models/branding/branding.model';
import { Platform } from 'src/app/core/models/branding/platform.model';
import { AppUser } from 'src/app/core/models/user.model';
import { getPlatformConfiguration } from 'src/app/shared/selectors/branding.selector';
import { PlatformType } from 'src/environments/platform-type.enum';

@Component({
    selector: 'app-page-sidebar-temp1',
    templateUrl: './page-sidebar.temp1.component.html',
    styleUrls: ['./page-sidebar.temp1.component.scss']
})
export class PageSidebarTemp1Component implements OnInit {

    @Input() isMenuOpen: boolean;
    @Input() currentPlatform: PlatformType;
    @Input() branding: BrandingModel;
    @Input() currentUser: AppUser;
    @Input() logo: string;
    @Input() currentCompanyType: COMPANY_TYPE;
    @Input() newOrdersCount: number;
    @Input() accountType: AccountTypes;
    @Input() isCrispAvailable: boolean;
    @Input() poweredBy: string;
    @Output() toggleMenu = new EventEmitter();
    @Output() openSupportChat = new EventEmitter();
    @Output() logout = new EventEmitter();

    PlatformType = PlatformType;
    COMPANY_TYPE = COMPANY_TYPE;
    AccountTypes = AccountTypes;

    platform$: Observable<Platform>;

    constructor(private store: Store<AppState>) { }

    ngOnInit(): void {
        this.platform$ = this.store.select(getPlatformConfiguration);
    }

    onToggleMenu() {
        this.toggleMenu.emit();
    }

    onOpenSupportChat() {
        this.openSupportChat.emit();
    }

    onLogout() {
        this.logout.emit();
    }

    getPlatformIcon(): string {
        switch (this.currentPlatform) {
            case PlatformType.AMAST:
                return 'icon--reverse';
            case PlatformType.ACADIA_MARKET:
                return 'icon--reverse';
            default:
                return '';
        }
    }

    getPlatformMenu(): string {
        switch (this.currentPlatform) {
            case PlatformType.ACADIA_MARKET:
                return 'menu__item--active--acadia';
            case PlatformType.AMAST:
                return 'menu__item--active--amast';
            default:
                return 'menu__item--active';
        }
    }

}
