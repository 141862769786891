import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { exhaustMap, map, tap, withLatestFrom } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { AppState } from '../../app.reducer';
import { ShippingService } from '../../core/services/shipping/shipping.service';
import { ShippingSettings } from '../../core/models/shipping/shipping-settings.model';
import {
    LoadShippingSettings,
    LoadShippingSettingsSuccess,
    ShippingActionTypes,
    UpdateShippingSetting,
    UpdateShippingSettingsBatch,
    UpdateShippingSettingsBatchSuccess,
    UpdateShippingSettingSuccess
} from '../actions/shipping.actions';
import { selectShippingSettings } from '../selectors/shipping.selector';
import { NotifierService } from 'angular-notifier';
import { UpdateShippingSettingsRequest } from '../../core/models/shipping/update-shipping-settings-request.model';

@Injectable()
export class ShippingEffects {

    constructor(
        private actions$: Actions,
        private store: Store<AppState>,
        private shippingService: ShippingService,
        private notifyService: NotifierService,
    ) {
    }

    @Effect()
    loadShippingSettings$ = this.actions$.pipe(
        ofType<LoadShippingSettings>(ShippingActionTypes.LoadShippingSettings),
        exhaustMap((action) => this.shippingService.loadShippingSettings()),
        map((settings: ShippingSettings[]) => new LoadShippingSettingsSuccess({ settings }))
    );

    @Effect()
    updateShippingSettingBatch$ = this.actions$.pipe(
        ofType<UpdateShippingSettingsBatch>(ShippingActionTypes.UpdateShippingSettingsBatch),
        withLatestFrom(this.store.select(selectShippingSettings)),
        exhaustMap(([action, settings]) => {
            const updateSettings: UpdateShippingSettingsRequest[] = [];
            settings.forEach(setting => {
                const carrierSetting = setting.carrierSettings.find(cs => cs.carrierId === action.payload.carrierId);
                updateSettings.push({
                        priceGroupId: setting.priceGroupId,
                        carrierId: carrierSetting.carrierId,
                        isEnabled: action.payload.isEnabled
                    }
                );
            });

            return this.shippingService.updateShippingSettings(updateSettings)
                .pipe(
                    tap(() => {
                        this.notifyService.hideNewest();
                        this.notifyService.show({
                            type: 'success',
                            message: 'Shipping Settings updated'
                        });
                    }),
                    map(() => new UpdateShippingSettingsBatchSuccess(action.payload))
                );
        }),
    );

    @Effect()
    updateShippingSetting$ = this.actions$.pipe(
        ofType<UpdateShippingSetting>(ShippingActionTypes.UpdateShippingSetting),
        exhaustMap((action) => this.shippingService.updateShippingSettings([action.payload.setting])
            .pipe(
                tap(() => {
                    this.notifyService.hideNewest();
                    this.notifyService.show({
                        type: 'success',
                        message: 'Shipping Settings updated'
                    });
                }),
                map(() => new UpdateShippingSettingSuccess(action.payload))
            )),
    );
}
