import { Action } from '@ngrx/store';
import { Platform } from 'src/app/core/models/branding/platform.model';

export enum BrandingActionTypes {
    LoadBranding = '[Branding] Load Branding',
    LoadPlatformConfigurationSuccess = '[Branding] Load Platform Configuration Success'
}

export class LoadBranding implements Action {
    readonly type = BrandingActionTypes.LoadBranding;
}

export class LoadPlatformConfigurationSuccess implements Action {
    readonly type = BrandingActionTypes.LoadPlatformConfigurationSuccess;

    constructor(public payload: Platform) { }
}

export type BrandingActions =
    | LoadBranding
    | LoadPlatformConfigurationSuccess;
