
import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { exhaustMap, map, withLatestFrom, tap, mergeMap, catchError, switchMap, debounceTime } from 'rxjs/operators';
import { OrderGuideService } from 'src/app/core/services/order-guide/order-guide.service';
// tslint:disable-next-line:max-line-length
import { OrderGuideActionTypes, LoadOrderGuide, OrderGuideLoaded, SetProductFavorite, LoadCustomersListSuccess, SetProductVendorFavorite, ChangeProductName, ChangeProductNameSuccess, PrintOrderGuide, AddToCart, AddToCartSuccess, LoadCategories, LoadCategoriesSuccess, LoadVendorsSuccess, LoadVendors, ReloadOrderGuide, LoadProductPurchaseHistory, LoadProductPurchaseHistorySuccess, LoadProduct, ProductLoaded, SetVendorFavorite, LoadRelatedProducts, RelatedProductsLoaded, LoadPriceTrend, PriceTrendLoaded, VendorCutOffsLoaded, SaveProductGroupSuccess, SaveProductGroup, LoadProductGroup, LoadProductGroupSuccess, UpdateProductsList, SaveProductGroupFailed, LoadBuyersSuccess, AddToCartFailed, PrintOrderGuideLoading, LoadCustomersList, SaveCustomerOrderGuideSuccess, SaveCustomerOrderGuide, LoadCartSummaryOrderGuide, LoadProductsOrderGuide, LoadProductsOrderGuideSuccess, SaveProductSpecialPrice, SaveProductSpecialPriceSuccess, ResetProductSpecialPrice } from '../actions/order-guide.action';
import { AppState } from 'src/app/app.reducer';
import { Store, Action } from '@ngrx/store';
// tslint:disable-next-line:max-line-length
import { selectCategoryHistory, selectCategories, selectOrderGuideDetails } from '../selectors/order-guide.selector';
import { of } from 'rxjs';
import { ProductService } from 'src/app/core/services/product/product.service';
import { LoadCartSummary, UpdateShoppingCartAfterAddSuccess, LoadCartTotals, CartSummaryLoaded, StartCartSummaryLoading } from '../actions/cart.actions';
import { CartService } from 'src/app/core/services/cart/cart.service';
import { LoadTags } from '../actions/tags.actions';
import { Router } from '@angular/router';
import { currentSiteID, getAccountTypeId } from '../selectors/auth.selector';
import { VendorService } from 'src/app/core/services/vendor/vendor.service';
import { AccountTypes } from 'src/app/core/enums/account-type.enum';
import { ViewModes } from 'src/app/core/enums/view-mode.enum';
import { ProductGroupModel } from 'src/app/core/models/product/product-group.model';
import { NotifierService } from 'angular-notifier';
import { ToggleInfobar } from '../actions/infobar.actions';
import { InfobarProductGroupComponents, InfobarComponents } from 'src/app/core/enums/infobar-components.enum';
import { BuyerService } from 'src/app/core/services/buyer/buyer.service';
import { CustomerItemOrderGuide } from 'src/app/core/models/customer-item-order-guide.model';
import { ProductCatalogModel } from 'src/app/core/models/product/product-catalog.model';
import { SearchResponse } from 'src/app/core/models/search-response.model';

@Injectable()
export class OrderGuideEffects {

    @Effect()
    orderGuide$ = this.actions$.pipe(
        ofType<LoadOrderGuide>(OrderGuideActionTypes.LoadOrderGuideAction),
        withLatestFrom(this.store.select(currentSiteID)),
        withLatestFrom(this.store.select(getAccountTypeId)),
        switchMap(([[action, currentSiteID], accountTypeId]) => {
            const payload = { ...action.payload, currentSiteID };
            // If consumer query for only priced products
            if (accountTypeId === AccountTypes.Consumer) {
                payload.viewModes.push(ViewModes.WithPrice);
            }

            const response = this.orderGuideSvc.getOrderGuide(action.payload);
            return response.pipe(
                map((orderGuideDetails) => ({
                    orderGuideDetails,
                    filters: payload
                })),
                tap(() => {
                    this.store.dispatch(new LoadCategories({ search: action.payload }));
                })
            );
        }),
        map(response => new OrderGuideLoaded(response)),
    );

    @Effect({ dispatch: false })
    setProductFavorite$ = this.actions$.pipe(
        ofType<SetProductFavorite>(OrderGuideActionTypes.SetProductFavorite),
        exhaustMap((action) => this.productSvc.setProductFavorite(action.payload.productId, action.payload.isFavorite, action.payload.customerAccountId, action.payload.customerSiteId))
    );

    @Effect({ dispatch: false })
    setProductVendorFavorite$ = this.actions$.pipe(
        ofType<SetProductVendorFavorite>(OrderGuideActionTypes.SetProductVendorFavorite),
        exhaustMap((action) => this.productSvc.setProductVendorFavorite(action.payload.productId,
            action.payload.vendorId, action.payload.isFavorite))
    );

    @Effect({ dispatch: false })
    setVendorFavorite$ = this.actions$.pipe(
        ofType<SetVendorFavorite>(OrderGuideActionTypes.SetVendorFavorite),
        exhaustMap((action) => this.vendorSvc.setVendorFavorite(action.payload.vendorId, action.payload.isFavorite)),
        tap(() => this.vendorSvc.resetCache())
    );

    @Effect()
    changeProductName$ = this.actions$.pipe(
        ofType<ChangeProductName>(OrderGuideActionTypes.ChangeProductName),
        exhaustMap((action) => {
            const response = action.payload.newName !== action.payload.currentName ?
                this.productSvc.changeName(action.payload.productId, action.payload.newName) : of();
            return response.pipe(
                map(() => ({
                    productId: action.payload.productId,
                    name: action.payload.newName
                })
                ));
        }),
        map((response) => new ChangeProductNameSuccess(response))
    );

    @Effect({ dispatch: false })
    print$ = this.actions$.pipe(
        ofType<PrintOrderGuide>(OrderGuideActionTypes.PrintOrderGuide),
        exhaustMap((action: PrintOrderGuide) => this.orderGuideSvc.print(action.payload)),
        tap((response: any) => {
            const blob = new Blob([response], { type: 'application/pdf' });
            const a = document.createElement('a');
            const objectUrl = URL.createObjectURL(blob);
            a.href = objectUrl;
            a.download = 'OrderGuidePrint.pdf';
            a.click();
            URL.revokeObjectURL(objectUrl);

            this.store.dispatch(new PrintOrderGuideLoading({ loading: false }));
        })
    );

    @Effect({ dispatch: false })
    addToCart$ = this.actions$.pipe(
        ofType<AddToCart>(OrderGuideActionTypes.AddToCart),
        exhaustMap((action) => this.cartSvc.addToCart(action.payload)
            .pipe(
                map(response => {
                    if (action.payload.displayMaxQuantityMessage) {
                        this.notifier.notify('success', `Item was added in cart with max available quantity: ${action.payload.quantity}`);
                    }

                    return {
                        ...response,
                        productIdFromGroup: action.payload.productIdFromGroup,
                        reloadCartVendor: action.payload.reloadCartVendor,
                        orderGuideSeller: action.payload.orderGuideSeller,
                        customerAccountId: action.payload.customerAccountId,
                        customerSiteId: action.payload.customerSiteId
                    };
                }),
                catchError(error => {
                    this.notifier.notify('error', error.error.message);
                    this.store.dispatch(new AddToCartFailed());
                    return of();
                })
            )
        ),
        map((response: any) => {
            this.store.dispatch(new AddToCartSuccess({
                productId: response.productId,
                productIdFromGroup: response.productIdFromGroup,
                vendorId: response.vendorId,
                quantity: response.quantity,
                orderGuideSeller: response.orderGuideSeller,
                customerAccountId: response.customerAccountId,
                customerSiteId: response.customerSiteId
            }));

            this.store.dispatch(new UpdateShoppingCartAfterAddSuccess({
                productId: response.productId,
                productIdFromGroup: response.productIdFromGroup,
                vendorId: response.vendorId,
                quantity: response.quantity
            }));

            if (response.reloadCartVendor) {
                this.store.dispatch(new LoadCartTotals({
                    vendorId: response.vendorId,
                    customerAccountId: response.customerAccountId,
                    customerSiteId: response.customerSiteId
                }));
            }
        })
    );

    @Effect({ dispatch: false })
    addToCartSuccess$ = this.actions$.pipe(
        ofType<AddToCartSuccess>(OrderGuideActionTypes.AddToCartSuccess),
        map((action: AddToCartSuccess) => { if (action.payload.orderGuideSeller !== true) { this.store.dispatch(new LoadCartSummary()) } else { this.store.dispatch(new LoadCartSummaryOrderGuide({ customerSiteId: action.payload.customerSiteId, customerAccountId: action.payload.customerAccountId })) } })
    );

    @Effect()
    categories$ = this.actions$.pipe(
        ofType<LoadCategories>(OrderGuideActionTypes.LoadCategories),
        withLatestFrom(this.store.select(selectCategoryHistory)),
        map(([action, categoryHistory]) => {
            const continueLoadCategories = true;
            // if (categoryHistory !== undefined
            //     && categoryHistory.vendors
            //     && categoryHistory.viewModes
            //     && categoryHistory.sort === action.payload.search.sort
            //     && (JSON.stringify((categoryHistory.vendors || []).sort()) === JSON.stringify((action.payload.search.vendors
            //  || []).sort()))
            //     && (JSON.stringify((categoryHistory.viewModes || []).sort()) === JSON.stringify((action.payload.search.viewModes
            //  || []).sort()))
            //     //&& categoryHistory.page !== action.payload.search.page
            // ) {
            //     continueLoadCategories = false;
            // }

            return [action, categoryHistory, continueLoadCategories];
        }),
        exhaustMap(([action, categoryHistory, continueLoadCategories]) => {
            const response = continueLoadCategories ? this.orderGuideSvc.getCategories(action.payload.search) :
                this.store.select(selectCategories);
            return response.pipe(
                map(categories => ({
                    categories,
                    search: action.payload.search
                }))
            );
        }),
        map((response: any) => new LoadCategoriesSuccess({
            categories: response.categories,
            search: response.search
        }))
    );

    @Effect()
    vendors$ = this.actions$.pipe(
        ofType<LoadVendors>(OrderGuideActionTypes.LoadVendors),
        tap((action) => action.resetCache ? this.vendorSvc.resetCache() : undefined),
        exhaustMap((action) => this.vendorSvc.getVendors()),
        map((response: any) => new LoadVendorsSuccess({
            vendors: response
        }))
    );

    @Effect()
    vendorsSuccess$ = this.actions$.pipe(
        ofType<LoadVendorsSuccess>(OrderGuideActionTypes.LoadVendorsSuccess),
        exhaustMap((action) => this.cartSvc.getCuttoff(action.payload.vendors.map(v => v.customerSiteID))),
        map((cutoffDetails: any[]) => new VendorCutOffsLoaded({
            cutoffDetails
        }))
    );

    @Effect({ dispatch: false })
    reloadOrderGuide$ = this.actions$.pipe(
        ofType<ReloadOrderGuide>(OrderGuideActionTypes.ReloadOrderGuide),
        map((action) => {
            this.store.dispatch(new LoadVendors(true));
            this.store.dispatch(new LoadCategories({ search: {} }));
            this.store.dispatch(new LoadTags());
            this.router.navigate(['/order-guide']);
        })
    );

    @Effect()
    orderGuideLoaded$ = this.actions$.pipe(
        ofType<OrderGuideLoaded>(OrderGuideActionTypes.OrderGuideLoadedAction),
        withLatestFrom(this.store.select(selectOrderGuideDetails)),
        map(([action, orderGuide]) => new LoadProductPurchaseHistory({ products: orderGuide?.items.map(i => i.id) }))
    );

    @Effect()
    loadProductPurchaseHistory$ = this.actions$.pipe(
        ofType<LoadProductPurchaseHistory>(OrderGuideActionTypes.LoadProductPurchaseHistory),
        exhaustMap((action) => this.productSvc.loadPuchaseHistory(action.payload.products)),
        map(purchaseHistory => new LoadProductPurchaseHistorySuccess({ purchaseHistory }))
    );

    @Effect()
    product$ = this.actions$.pipe(
        ofType<LoadProduct>(OrderGuideActionTypes.LoadProductAction),
        exhaustMap((action) => this.productSvc.getProductDetails((action.payload.product))),
        map(product => new ProductLoaded({ productDetails: product }))
    );

    @Effect()
    productLoaded$ = this.actions$.pipe(
        ofType<ProductLoaded>(OrderGuideActionTypes.ProductLoadedAction),
        map(response => new LoadProductPurchaseHistory({
            products: [response.payload.productDetails.id]
        }))
    );

    @Effect()
    relatedProducts$ = this.actions$.pipe(
        ofType<LoadRelatedProducts>(OrderGuideActionTypes.LoadRelatedProductsAction),
        exhaustMap((action) => this.productSvc.getRelatedProducts((action.payload.productId))),
        map(products => new RelatedProductsLoaded({ relatedProducts: products }))
    );

    @Effect()
    priceTrends$ = this.actions$.pipe(
        ofType<LoadPriceTrend>(OrderGuideActionTypes.LoadPriceTrendAction),
        exhaustMap((action) => this.productSvc.getPriceTrends(action.payload.productId)),
        map(priceTrends => new PriceTrendLoaded({ priceTrends }))
    );

    @Effect()
    saveProductGroup = this.actions$.pipe(
        ofType(OrderGuideActionTypes.SaveProductGroup, OrderGuideActionTypes.DeleteProductGroup),
        mergeMap((action: SaveProductGroup) => this.productSvc.saveProductGroup(action.payload).pipe(
            map(newProduct => {
                this.notifier.notify('success', 'Product Group Saved.');
                this.store.dispatch(new ToggleInfobar({
                    open: false
                }));
                if (action.payload.productGroupId <= 0) {
                    this.store.dispatch(new UpdateProductsList(newProduct));
                }
                return new SaveProductGroupSuccess(new ProductGroupModel());
            }),
            catchError(err => {
                this.notifier.notify('error', err.error.message);
                this.store.dispatch(new SaveProductGroupFailed(action.payload));
                return err;
            })),
        )
    );

    @Effect()
    loadProductGroup$ = this.actions$.pipe(
        ofType(OrderGuideActionTypes.LoadProductGroup),
        mergeMap((action: LoadProductGroup) => this.productSvc.getProductGroup(action.payload).pipe(
            map(productGroup => {
                this.store.dispatch(new ToggleInfobar({
                    open: true,
                    title: `Product Group`,
                    params: {
                        [InfobarProductGroupComponents.PAGE_NAME]: true,
                        [InfobarComponents.SITE]: null
                    }
                }));
                return new LoadProductGroupSuccess(new ProductGroupModel(productGroup));
            })
        ))
    );

    @Effect()
    loadBuyers$ = this.actions$.pipe(
        ofType(OrderGuideActionTypes.LoadBuyers),
        mergeMap(() => this.buyerService.getBuyers().pipe(
            map(data => new LoadBuyersSuccess(data))
        ))
    );

    @Effect()
    loadCustomersList$ = this.actions$.pipe(
        ofType(OrderGuideActionTypes.LoadCustomersList),
        mergeMap((action: LoadCustomersList) => this.orderGuideSvc.loadCustomersList(action.payload).pipe(
            map((data: CustomerItemOrderGuide[]) => new LoadCustomersListSuccess(data))
        ))
    )

    @Effect()
    saveCustomer$ = this.actions$.pipe(
        ofType(OrderGuideActionTypes.SaveCustomerOrderGuide),
        mergeMap((action: SaveCustomerOrderGuide) => this.orderGuideSvc.saveCustomer(action.payload.vendorCustomerId, action.payload.request).pipe(
            map((data: CustomerItemOrderGuide[]) => new SaveCustomerOrderGuideSuccess(data))
        ))
    )

    @Effect({ dispatch: false })
    loadSummaryOrderGuide$ = this.actions$.pipe(
        ofType(OrderGuideActionTypes.LoadSummaryOrderGuide),
        switchMap((action: LoadCartSummaryOrderGuide) => this.cartSvc.loadCartSummaryOrderGuide(action.payload).pipe(
            map((summary) => {
                this.store.dispatch(new CartSummaryLoaded({ summary }));
            })
        ))
    )

    @Effect({ dispatch: false })
    loadProductsOrderGuide$ = this.actions$.pipe(
        ofType(OrderGuideActionTypes.LoadProductsOrderGuide),
        debounceTime(500),
        mergeMap((action: LoadProductsOrderGuide) => this.orderGuideSvc.getOrderGuide(action.payload).pipe(
            map((response: SearchResponse<ProductCatalogModel>) => {
                this.store.dispatch(new LoadCategories({ search: action.payload }));
                this.store.dispatch(new LoadProductsOrderGuideSuccess(response))
            })
        ))
    )

    @Effect({ dispatch: false })
    saveProductSpecialPrice$ = this.actions$.pipe(
        ofType(OrderGuideActionTypes.SaveProductSpecialPrice),
        mergeMap((action: SaveProductSpecialPrice) => this.orderGuideSvc.saveSpecialPrice(action.payload.vendorCustomerId, action.payload.request).pipe(
            map((response: number) => {
                this.store.dispatch(new SaveProductSpecialPriceSuccess({ productId: action.payload.request.productId, setId: response }));
                this.store.dispatch(new StartCartSummaryLoading);
                this.store.dispatch(new LoadCartSummaryOrderGuide({ customerSiteId: action.payload.request.customerSelectedSiteId, customerAccountId: action.payload.request.customerSelectedAccountId }));
            })
        ))
    )

    @Effect({ dispatch: false })
    resetProductSpecialPrice$ = this.actions$.pipe(
        ofType(OrderGuideActionTypes.ResetProductSpecialPrice),
        mergeMap((action: ResetProductSpecialPrice) => this.orderGuideSvc.resetSpecialPrice(action.payload).pipe(
            map((response: boolean) => {
                if (response) {
                    this.store.dispatch(new StartCartSummaryLoading);
                    this.store.dispatch(new LoadCartSummaryOrderGuide({ customerSiteId: action.payload.customerSelectedSiteId, customerAccountId: action.payload.customerSelectedAccountId }))
                }
            })
        ))
    )

    constructor(
        private actions$: Actions,
        private store: Store<AppState>,
        private orderGuideSvc: OrderGuideService,
        private productSvc: ProductService,
        private cartSvc: CartService,
        private vendorSvc: VendorService,
        private router: Router,
        private notifier: NotifierService,
        private buyerService: BuyerService
    ) { }

}
