import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'app-icon-chat-plus',
    templateUrl: './icon-chat-plus.component.html',
    styleUrls: ['./icon-chat-plus.component.scss']
})
export class IconChatPlusComponent implements OnInit {

    @Input() cssClass: string;

    constructor() { }

    ngOnInit(): void {
    }

}
