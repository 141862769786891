import { createSelector } from '@ngrx/store';

export const selectUserState = state => state.users;

export const selectUsers = createSelector(
    selectUserState,
    state => state.users
);

export const selectUserById = (id) => createSelector(
    selectUsers,
    (allUsers) => {
        if (allUsers) {
            return allUsers.find(item => item.userId === id);
        } else {
            return undefined;
        }
    });

export const selectLoadingUsers = createSelector(
    selectUserState,
    state => state.loadingUsers
);

export const addUserLoading = createSelector(
    selectUserState,
    state => state.addUserLoading
);

export const selectCustomerUsers = createSelector(
    selectUserState,
    state => state.customerUsers
);

