import { createFeatureSelector, createSelector } from '@ngrx/store';
import { SettingsState } from '../reducers/settings.reducer';

const selectSettingsState = createFeatureSelector<SettingsState>('settings');


export const settingsOrderMin = createSelector(
    selectSettingsState,
    state => state.settingsOrderMin
);

export const settingsOrderWeight = createSelector(
    selectSettingsState,
    state => state.settingsOrderWeight
);

export const loadingSettingsOrderMin = createSelector(
    selectSettingsState,
    state => state.loadingSettingsOrderMin
);

export const loadingSettingsOrderWeight = createSelector(
    selectSettingsState,
    state => state.loadingSettingsOrderWeight
);