import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { GenericModalData } from '../../../core/models/success-modal-data.model';
import { ModalIconType } from './modal-icon.type';
import { Observable } from 'rxjs';

@Component({
    selector: 'app-generic-modal',
    templateUrl: './generic-modal.component.html',
    styleUrls: ['./generic-modal.component.scss']
})
export class GenericModalComponent implements OnInit {
    iconType = ModalIconType;

    constructor(public dialogRef: MatDialogRef<GenericModalComponent>,
                @Inject(MAT_DIALOG_DATA) public data: GenericModalData) {
    }

    ngOnInit(): void {
    }

    onClose(): void {
        this.dialogRef.close(false);
    }

    onConfirm(): void {
        this.dialogRef.close(true);
    }
}
