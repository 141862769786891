import { Action } from '@ngrx/store';
import { GeneralItemModel } from '../models/general-item.model';

export enum TagsActionTypes {
    LoadTagsAction = '[Global] Load Tags',
    TagsLoadedAction = '[Global] Tags Loaded',
    EditTagAction = '[Global] Tag Edit',
    ReloadTagsAction = '[Global] Reload Tags',
    DeleteTagAction = '[Global] Tag Delete',
    AssignTag = '[Global] AssignTag',
    CreateAndAssignTag = '[Global] CreateAndAssignTag'
}

export enum TagUIAction {
    Add,
    Edit,
    Delete
}

export class LoadTags implements Action {
    readonly type = TagsActionTypes.LoadTagsAction;
}

export class TagsLoaded implements Action {
    readonly type = TagsActionTypes.TagsLoadedAction;

    constructor(public payload: { tags: any }) { }
}

export class EditTag implements Action {
    readonly type = TagsActionTypes.EditTagAction;

    constructor(public payload: { tag: GeneralItemModel }) { }
}

export class ReloadTagsAction implements Action {
    readonly type = TagsActionTypes.ReloadTagsAction;

    constructor(public payload: { tag: any, type: TagUIAction }) { }
}

export class DeleteTag implements Action {
    readonly type = TagsActionTypes.DeleteTagAction;

    constructor(public payload: { tag: GeneralItemModel }) { }
}

export class AssignTag implements Action {
    readonly type = TagsActionTypes.AssignTag;

    constructor(public payload: { tagId: number, productId: number, isAssigned: boolean, name?: string }) { }
}

export class CreateAndAssignTag implements Action {
    readonly type = TagsActionTypes.CreateAndAssignTag;

    constructor(public payload: { name: string, productId: number }) { }
}

export type TagsActions =
    LoadTags
    | TagsLoaded
    | EditTag
    | ReloadTagsAction
    | DeleteTag
    | AssignTag
    | CreateAndAssignTag;
