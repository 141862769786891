import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-icon-manage-account',
  templateUrl: './icon-manage-account.component.html',
  styleUrls: ['./icon-manage-account.component.scss']
})
export class IconManageAccountComponent {

  @Input() cssClass = 'icon--24';

}
