import { createSelector, createFeatureSelector } from '@ngrx/store';
import { PriceRequestsState } from '../reducers/price-requests.reducer';

export const selectPriceRequest = createFeatureSelector<PriceRequestsState>('priceRequests');

export const selectLoadingPriceRequests = createSelector(
    selectPriceRequest,
    state => state.loading
);

export const selectPriceRequests = createSelector(
    selectPriceRequest,
    state => state.priceRequests
);

export const selectVendorPriceRequest = createSelector(
    selectPriceRequest,
    state => state.vendorPriceRequests
);
