import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'app-tl-time-picker',
    templateUrl: './tl-time-picker.component.html',
    styleUrls: ['./tl-time-picker.component.scss']
})
export class TlTimePickerComponent implements OnInit {

    @Input() dateTimeInput: Date;
    @Input() minDateTimeInput: Date;
    @Output() dateTimeChanged: EventEmitter<Date> = new EventEmitter<Date>();

    dateTime: Date;
    minDateTime: Date;

    constructor() { }

    ngOnInit() {
        if (!this.dateTimeInput) {
            this.dateTime = new Date();
        } else {
            this.dateTime = new Date(this.dateTimeInput.toString());
        }

        if (this.minDateTimeInput) {
            this.minDateTime = new Date(this.minDateTimeInput.toString());
        }
    }

    timeChanged() {
        this.dateTimeChanged.emit(this.dateTime);
        document.body.click();
    }

    onCancel() {
        document.body.click();
    }
}

