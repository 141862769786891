import { LoadCompatiblePriceLevels } from './../../actions/customer.actions';
import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { LeadsStatus } from 'src/app/core/enums/leads-status.enum';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/app.reducer';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SaveLeadsStatus } from '../../actions/customer.actions';
import { Observable } from 'rxjs';
import { sitesList } from '../../selectors/site.selector';
import { currentSiteID } from '../../selectors/auth.selector';
import { LoadCustomerUsers } from '../../actions/user.actions';
import { selectCustomerUsers } from '../../selectors/user.selector';
import { LeadsInfoModel } from 'src/app/core/models/customer/leads-info.model';
import { ToggleInfobar } from '../../actions/infobar.actions';
import { priceLevelsList } from '../../selectors/customer.selector';

@Component({
    selector: 'app-leads-accept-modal',
    templateUrl: './leads-accept-modal.component.html',
    styleUrls: ['./leads-accept-modal.component.scss']
})
export class LeadsAcceptModalComponent implements OnInit {

    leadsAcceptFrom: FormGroup;
    LeadsStatus = LeadsStatus;
    customerUsers$: Observable<any>;
    currentSiteId: number;
    priceLevels$: Observable<any>;

    constructor(
        private store: Store<AppState>,
        public dialogRef: MatDialogRef<LeadsAcceptModalComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private fb: FormBuilder) { }

    ngOnInit() {
        this.leadsAcceptFrom = this.fb.group({
            notes: [null],
            buyerSiteCode: [null],
            priceLevelId: [null, Validators.required],
            accountManagerId: [null, Validators.required],
        });

        this.store.select(currentSiteID).subscribe(siteId => {
            this.currentSiteId = siteId;
            this.store.dispatch(new LoadCustomerUsers(siteId));
        });

        this.store.dispatch(new LoadCompatiblePriceLevels(this.data.platformId));
        this.priceLevels$ = this.store.select(priceLevelsList);

        this.customerUsers$ = this.store.select(selectCustomerUsers);
    }

    get leadsAccept() { return this.leadsAcceptFrom.controls; }

    onSave() {
        if (this.leadsAcceptFrom.invalid) {
            return;
        }

        const leadsInfo: LeadsInfoModel = {
            leadId: this.data.requestId,
            isProcessing: false,
            statusId: LeadsStatus.Accepted,
            customerSiteId: this.data.id,
            notes: this.leadsAccept.notes.value,
            priceLevelId: this.leadsAccept.priceLevelId.value,
            representativeId: this.leadsAccept.accountManagerId.value,
            buyerSiteCode: this.leadsAccept.buyerSiteCode.value,
            currentSiteId: this.currentSiteId,
            displayName: this.data.displayName
        };

        this.dialogRef.close();
        this.store.dispatch(
            new ToggleInfobar({ open: false })
        );
        this.store.dispatch(new SaveLeadsStatus({ leadsInfo }));
    }

    close(): void {
        this.dialogRef.close();
    }
}
