import { EntityChanel } from './entity-chanel.model';
import { MessageModel } from './message.model';

export class ChatConversation {
    channel: EntityChanel = new EntityChanel();
    member: any;
    messages: Array<MessageModel> = new Array<MessageModel>();

    constructor(data?: { channel: EntityChanel, messages: { messages: Array<{ message: MessageModel }> } }) {
        if (data) {
            this.channel = data.channel;

            if (data.messages && data.messages.messages) {
                data.messages.messages.map(message => this.messages.push(new MessageModel(message.message)));
            }
        }
    }
}
