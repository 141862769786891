import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-icon-submit-and-pay',
  templateUrl: './icon-submit-and-pay.component.html',
  styleUrls: ['./icon-submit-and-pay.component.scss']
})
export class IconSubmitAndPayComponent implements OnInit {

  @Input() cssClass = '';

  constructor() { }

  ngOnInit() {
  }

}
