import { createSelector, select } from '@ngrx/store';
import { UserPermissionGroup } from 'src/app/core/models/user-permission-group.model';
import { AppUser } from 'src/app/core/models/user.model';

export const selectAuthState = state => state.auth;

export const isLoggedIn = createSelector(
    selectAuthState,
    auth => auth.loggedIn
);

export const isLoggedOut = createSelector(
    isLoggedIn,
    loggedIn => !loggedIn
);

export const token = createSelector(
    selectAuthState,
    auth => (auth.loginData || {}).accessToken
);

export const refreshToken = createSelector(
    selectAuthState,
    auth => (auth.loginData || {}).refreshToken
);

export const currentUserDetails = createSelector(
    selectAuthState,
    auth => auth.userDetails
);

export const currentSiteID = createSelector(
    selectAuthState,
    auth => auth.currentSiteID
);

export const userSites = createSelector(
    currentUserDetails,
    userDetails => (userDetails || {}).sites || []
);

export const currentSite = createSelector(
    userSites,
    (sites: Array<any>, props) => {
        return sites.find(site => site.id === props.currentSiteId);
    }
);

export const getCurrentUserID = createSelector(
    selectAuthState,
    auth => auth.userDetails ? auth.userDetails.userId : undefined
);

export const currentCompanyType = createSelector(
    selectAuthState,
    auth => auth.companyType
);

export const getShowLocationSelection = createSelector(
    selectAuthState,
    auth => auth.showLocationSelection
);

export const selectHomeUrl = createSelector(
    selectAuthState,
    state => state.homeUrl
);

export const isAccountVerified = createSelector(
    selectAuthState,
    auth => auth.userDetails ? auth.userDetails.hasValidEmail : false
);

export const userPermissions = createSelector(
    selectAuthState,
    state => state.userPermissions
);

export const userAddons = createSelector(
    currentUserDetails,
    state => (state || {}).addons || []
);

export const userHasPermissions = createSelector(
    userPermissions,
    (state: Array<UserPermissionGroup>, props) => {
        const permissionGroup = state && state
            .find(userPermissionGroup => userPermissionGroup.key === props.permissionGroup) || new UserPermissionGroup();
        return permissionGroup.permission ?
            (permissionGroup.permission.find(permission => permission.key === props.permissionKey) || { isAssign: false }).isAssign : false;
    }
);

export const userHasAddon = createSelector(
    userAddons,
    (state: Array<string>, props) => {
        return state.find(addon => addon === props.addon);
    }
);

export const getAccountTypeId = createSelector(
    currentUserDetails,
    ud => (ud || {}).userAccountTypeId
);

export const getLoading = createSelector(
    selectAuthState,
    state => state.loading
);

export const getCurrentSite = createSelector(
    selectAuthState,
    state => state.userDetails.sites.find(site => site.id === state.currentSiteID)
);
