import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-icon-prod-advisor',
  templateUrl: './icon-prod-advisor.component.html',
  styleUrls: ['./icon-prod-advisor.component.scss']
})
export class IconProdAdvisorComponent implements OnInit {
  @Input() cssClass = '';

  constructor() { }

  ngOnInit(): void {
  }

}
