import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/app.reducer';
import { ProductGroupModel } from 'src/app/core/models/product/product-group.model';
import { selectProductGroup, selectOrderGuideDetailsLoading } from 'src/app/shared/selectors/order-guide.selector';
import {
    UpdateProductGroup,
    SaveProductGroup,
    DeleteProductGroup,
    LastProductGroupDeleted
} from 'src/app/shared/actions/order-guide.action';
import { ToggleInfobar } from 'src/app/shared/actions/infobar.actions';
import { Subject, Observable } from 'rxjs';
import { takeUntil, share } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationModalComponent } from 'src/app/shared/components/confirmation-modal/confirmation-modal.component';
import { PlatformType } from 'src/environments/platform-type.enum';
import { environment } from 'src/environments/environment';
import { InfobarState } from 'src/app/shared/reducers/infobar.reducer';

@Component({
    selector: 'app-product-group',
    templateUrl: './product-group.component.html',
    styleUrls: ['./product-group.component.scss']
})
export class ProductGroupComponent implements OnInit, OnDestroy {

    @Input() infobarConfigs: InfobarState;

    groupName: string;
    productGroup: ProductGroupModel;
    skipValidation: boolean;
    private ngUnsubscribe: Subject<void> = new Subject<void>();
    loading$: Observable<boolean>;
    PlatformType = PlatformType;
    currentPlatform = environment.platformId;
    productGroupClone: string;

    constructor(private store: Store<AppState>, private dialog: MatDialog) { }

    ngOnInit() {
        this.productGroupClone = JSON.stringify(new ProductGroupModel());

        this.store.select(selectProductGroup).pipe(takeUntil(this.ngUnsubscribe)).subscribe((data: ProductGroupModel) => {
            if (data) {
                this.skipValidation = data.selectedProducts && data.selectedProducts.length ? false : true;
                this.productGroup = data;

                if (this.productGroup.productGroupId > 0) {
                    this.productGroupClone = JSON.stringify(this.productGroup);
                }

                this.checkMainProductIList();
            }
        });

        this.loading$ = this.store.select(selectOrderGuideDetailsLoading).pipe(share());
    }

    checkMainProductIList() {
        if (this.productGroup.mainProductId &&
            !this.productGroup.selectedProducts.find(selectedProduct =>
                (selectedProduct.id || selectedProduct.productId) === this.productGroup.mainProductId)) {

            if (this.productGroup.selectedProducts.length > 0) {
                this.productGroup.mainProductId = this.productGroup.selectedProducts[0].id
                    || this.productGroup.selectedProducts[0].productId;
                this.productGroup.productGroupName = this.productGroup.selectedProducts[0].name
                    || this.productGroup.selectedProducts[0].productName;
            }

        }
    }

    ngOnDestroy() {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    setMainProduct(product: any) {
        this.productGroup.mainProductId = product.id || product.productId;

        this.store.dispatch(new UpdateProductGroup(this.productGroup));
    }

    saveProductGroup() {
        const productIds = new Array<number>();
        this.productGroup.selectedProducts.forEach(product => {
            productIds.push(product.id || product.productId);
        });

        this.productGroup.productIds = productIds;
        this.productGroup.productGroupId = this.productGroup.productGroupId ? this.productGroup.productGroupId : -1;
        this.store.dispatch(new SaveProductGroup(this.productGroup));
    }

    cancel() {
        this.store.dispatch(new ToggleInfobar({
            open: false
        }));
        this.productGroup = null;

        this.store.dispatch(new UpdateProductGroup(this.productGroup));
    }

    enableDelete() {
        return this.productGroup.selectedProducts.length > 1;
    }

    enableSaveButton(): boolean {
        return this.productGroup && this.productGroup.mainProductId
            && this.productGroup.productGroupName && this.productGroup.selectedProducts.length > 0;
    }
    getIsMainProduct(product): boolean {
        return (product.id || product.productId) === this.productGroup.mainProductId;
    }

    deleteGroup() {
        const confirmRef = this.dialog.open(ConfirmationModalComponent, {
            data: {
                message: `Are you sure you want to remove this product group?`
            },
        });
        confirmRef.afterClosed().subscribe(isConfirmed => {
            if (isConfirmed) {
                this.productGroup.deleted = true;
                this.store.dispatch(new LastProductGroupDeleted(this.productGroup.productGroupId));
                this.store.dispatch(new DeleteProductGroup(this.productGroup));
            }
        });
    }

    removeItemFromList(product: any) {
        const confirmRef = this.dialog.open(ConfirmationModalComponent, {
            data: {
                message: `Are you sure you want to remove this product?`
            },
        });
        confirmRef.afterClosed().subscribe(isConfirmed => {
            if (isConfirmed) {
                const index = this.productGroup.selectedProducts.findIndex(selectedProduct =>
                    (selectedProduct.id || selectedProduct.productId) === (product.id || product.productId));
                this.productGroup.selectedProducts.splice(index, 1);

                if (this.getIsMainProduct(product)) {
                    const newIndex = this.productGroup.selectedProducts.length >= index ? 0 : index;
                    const nextMainProduct = this.productGroup.selectedProducts[newIndex];
                    this.productGroup.mainProductId = nextMainProduct.id || nextMainProduct.productId;
                    this.productGroup.productGroupName = nextMainProduct.name || nextMainProduct.productName;
                }

                this.store.dispatch(new UpdateProductGroup(this.productGroup));
            }
        });
    }

    getDefaultImage(): string {
        switch (this.currentPlatform) {
            case PlatformType.AMAST:
                return 'default.amast';
            default:
                return 'default';
        }
    }

    getOnErrorImage($event: any) {
        $event.src = 'assets/images/categories/' + this.getDefaultImage() + '.svg';
    }

    disableSave(): boolean {
        return this.productGroupClone === JSON.stringify(this.productGroup);
    }
}
