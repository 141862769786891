import { DraftActions, DraftActionTypes } from '../actions/draft.actions';
import { Draft } from 'src/app/core/models/draft/draft.model';
import { DraftSettingType } from 'src/app/core/enums/draft-setting.enum';
import { getDateWithSetTimes } from '../constants/global.contants';

export interface DraftState {
    newDraftsCount: number;
    loading: boolean;
    drafts: Array<Draft>;
    draftsCount: number;
}

export const initialState: DraftState = {
    newDraftsCount: 0,
    loading: false,
    drafts: [],
    draftsCount: 0
};

export function draftReducer(state = initialState, action: DraftActions): DraftState {
    switch (action.type) {
        case DraftActionTypes.LoadDraft:
            return {
                ...state,
                loading: true
            };
        case DraftActionTypes.LoadDraftSuccess:
            return {
                ...state,
                loading: false,
                drafts: action.payload
            };
        case DraftActionTypes.LoadDraftsCountSuccess:
            return {
                ...state,
                draftsCount: action.payload.count
            };
        case DraftActionTypes.HandleConflicts:
            const newDrafts = state.drafts;

            newDrafts.forEach(draft => {
                const conflictDraft = action.payload.conflicts.find(c => c.id === draft.id);
                if (conflictDraft) {
                    draft.isConflict = true;
                    draft.conflictResolutionType = DraftSettingType.UseDraft;
                    draft.customDeliveryDate = new Date(draft.deliveryDate);
                    draft.customDeliveryFromTime = getDateWithSetTimes(draft.deliveryFromTimeFormatted);
                    draft.customDeliveryToTime = getDateWithSetTimes(draft.deliveryToTimeFormatted);
                    draft.scDeliveryDate = new Date(conflictDraft.scDeliveryDate);
                    draft.scDeliveryFromTime = getDateWithSetTimes(conflictDraft.scDeliveryFromTimeFormatted);
                    draft.scDeliveryToTime = getDateWithSetTimes(conflictDraft.scDeliveryToTimeFormatted);
                }
            });
            return {
                ...state,
                drafts: newDrafts
            };
        default:
            return state;
    }
}
