import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { GRID_TYPE } from '../../enums/grid-type.enum';
import { map } from 'rxjs/operators';
import { ColumnFilterType, ColumnSortType, ColumnAlignType, AggFunctionType } from '../../enums/column.enum';
import { CurrencyPipe } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class GridService {

  constructor(
    private http: HttpClient,
    private currencyPipe: CurrencyPipe
  ) { }

  getHeader(gridId: GRID_TYPE, params) {
    return this.http.post(`${environment.api.content}/Reports/header/${gridId}`, params)
      .pipe(
        map((columnDefs: any[]) => {

          // adjust definitions based on filter types
          columnDefs.forEach(cd => {
            switch (cd.filterType) {
              case ColumnFilterType.TEXT:
                cd.filter = 'agTextColumnFilter';
                cd.filterParams = { defaultOption: 'contains' };
                cd.floatingFilterComponentParams = { suppressFilterButton: true };
                break;
            }

            switch (cd.sortType) {
              case ColumnSortType.CLIENT_MONEY_SORT:
                cd.comparator = (valueA, valueB) => {
                  const decimalValueA = parseFloat(valueA.replace('$', '').replace(',', ''));
                  const decimalValueB = parseFloat(valueB.replace('$', '').replace(',', ''));
                  return decimalValueA - decimalValueB;
                };
                break;
            }

            switch(cd.formatType)
            {
              case 1:     
                  cd.valueFormatter = (params) => {
                    return this.currencyPipe.transform(params.value);
                  };
                break;
            }

            switch (cd.columnAlignType) {
              case ColumnAlignType.CENTER:
                cd.cellStyle = {
                  textAlign: "center"
                };
                break;
              case ColumnAlignType.RIGHT:
                  cd.cellStyle = {
                    textAlign: "right"
                  };
                  break;
            }

            switch (cd.headerAlignType) {
              case ColumnAlignType.CENTER:
                cd.headerClass = "grid-header-cell-center";
                break;
            }

            switch (cd.aggFunctionType) {
              case AggFunctionType.SUM:
                cd.aggFunc = "sum";
                break;
            }

          });

          return columnDefs;
        })
      );
  }
}
