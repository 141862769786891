import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { AppState } from 'src/app/app.reducer';
import { ProductCatalogModel } from 'src/app/core/models/product/product-catalog.model';
import { UnitOfMeasureModel } from 'src/app/core/models/product/unit-of-measure.model';
import { environment } from 'src/environments/environment';
import { PlatformType } from 'src/environments/platform-type.enum';
import { LoadAllCategories, LoadRelatedUom, LoadUomOptions, AddProductForComposition } from '../../actions/product.actions';
import { getProductCatalogLoading, getProductCategories, getRelatedUom, getUomOptions } from '../../selectors/product.selector';

@Component({
    selector: 'app-add-product-for-composition-modal',
    templateUrl: './add-product-for-composition-modal.component.html',
    styleUrls: ['./add-product-for-composition-modal.component.scss']
})
export class AddProductForCompositionModalComponent implements OnInit {

    productCatalog: ProductCatalogModel;
    url: string;
    file: File;
    currentPlatform = environment.platformId;
    form: FormGroup;
    availableCategories: Array<any> = [];
    submitted: boolean;
    uomOptions$: Observable<Array<UnitOfMeasureModel>>;
    relatedUomOptions$: Observable<Array<UnitOfMeasureModel>>;
    loading: boolean;

    categoriesSettings = {
        text: '',
        classes: 'tl-multipleselect invalid-input',
        badgeShowLimit: 2,
        groupBy: 'masterCategory',
        singleSelection: true
    };

    constructor(
        private fb: FormBuilder,
        private dialogRef: MatDialogRef<AddProductForCompositionModalComponent>,
        private store: Store<AppState>
    ) { }

    ngOnInit(): void {
        this.store.pipe(select(getProductCatalogLoading)).subscribe((data: boolean) => {
            this.loading = data;
        });

        this.uomOptions$ = this.store.select(getUomOptions);
        this.relatedUomOptions$ = this.store.select(getRelatedUom);

        this.store.pipe(select(getProductCategories)).subscribe((categories) => {
            if (categories) {
                this.availableCategories = [];
                categories.forEach(c => {
                    if (c.subCategories && c.subCategories.length) {
                        c.subCategories.forEach(sc => this.availableCategories.push({
                            id: sc.id,
                            itemName: sc.name,
                            masterCategory: c.name
                        }));
                    } else {
                        this.availableCategories.push({
                            id: c.id,
                            itemName: c.name,
                            masterCategory: c.name
                        });
                    }
                });
            }

            this.createForm();
        });

        this.store.dispatch(new LoadUomOptions());
        this.store.dispatch(new LoadAllCategories());
    }

    createForm() {
        this.form = this.fb.group({
            name: ['', Validators.required],
            category: ['', Validators.required],
            description: [''],
            sku: [''],
            packSize: ['', Validators.required],
            unitSize: ['', Validators.required],
            packSizeUom: ['', Validators.required],
            unitSizeUom: ['', Validators.required],
        });
    }

    uploadFile() {
        document.getElementById('selectedFile').click();
    }

    processFile(files: FileList) {
        if (files.length === 0) {
            return;
        }

        const fileReader: FileReader = new FileReader();
        fileReader.readAsDataURL(files.item(0));

        fileReader.onload = (event: any) => {
            this.url = event.target.result;
        };

        this.file = files.item(0);
    }

    getDefaultImage(): string {
        switch (this.currentPlatform) {
            case PlatformType.AMAST:
                return 'default.amast';
            default:
                return 'default';
        }
    }

    getOnErrorImage($event: any) {
        $event.src = 'assets/images/categories/' + this.getDefaultImage() + '.svg';
    }

    save() {
        this.submitted = true;
        this.form.markAllAsTouched();

        if (this.form.invalid) {
            return;
        }

        const productCatalog = new ProductCatalogModel();

        productCatalog.name = this.form.value.name;
        productCatalog.categoryId = this.form.value.category[0].id;
        productCatalog.shortDescription = this.form.value.description;
        productCatalog.vendorSku = this.form.value.sku;
        productCatalog.uomSize = this.form.value.packSize;
        productCatalog.unitUomSize = this.form.value.unitSize;
        productCatalog.uomId = this.form.value.packSizeUom;
        productCatalog.unitUomId = this.form.value.unitSizeUom;

        this.store.dispatch(new AddProductForComposition(
            {
                product: productCatalog,
                file: this.file
            }
        ));

    }

    cancel() {
        this.dialogRef.close();
    }

    loadRelatedUom(event: any) {
        this.store.dispatch(new LoadRelatedUom(event.value));
    }
}
