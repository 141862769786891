import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { AppState } from 'src/app/app.reducer';
import { SiteModel } from 'src/app/core/models/site.model';
import { InfobarState } from 'src/app/shared/reducers/infobar.reducer';
import { sitesList } from 'src/app/shared/selectors/site.selector';
import { SaveRule } from 'src/app/shared/actions/rewards.actions';
import { Observable } from 'rxjs';
import { TokenModel } from 'src/app/core/models/rewards/token.model';
import { getEditRule, getRewardTokens } from 'src/app/shared/selectors/rewards.selector';
import { RewardsOperationsEnum } from 'src/app/core/enums/rewards-operations.enum';
import { RewardsBeneficiariesEnum } from 'src/app/core/enums/rewards-beneficiaries.enum';
import { RewardsRuleActions } from 'src/app/core/enums/rewards-rule-action.enum';
import { RuleModel } from 'src/app/core/models/rewards/rule.model';
import { EditRuleResponse } from 'src/app/core/models/rewards/edit-rule.model';
import { CustomerService } from '../../../../core/services/customer/customer.service';
import { CustomerLight } from '../../../../core/models/customer/customer-light.model';

@Component({
    selector: 'app-add-edit-rule',
    templateUrl: './add-edit-rule.component.html',
    styleUrls: ['./add-edit-rule.component.scss']
})
export class AddEditRuleComponent implements OnInit {

    @Input() infobarConfigs: InfobarState;
    isEditMode: boolean;
    form: FormGroup;
    // Price Level multiple select
    priceLevelsSettings = {
        text: '',
        selectAllText: 'Select All',
        unSelectAllText: 'UnSelect All',
        classes: 'tl-multipleselect',
        enableSearchFilter: true,
        badgeShowLimit: 2
    };
    selectedPriceLevels: Array<any> = [];
    availablePriceLevels: Array<any> = [];

    // Price Level multiple select
    customersSettings = {
        text: '',
        selectAllText: 'Select All',
        unSelectAllText: 'UnSelect All',
        classes: 'tl-multipleselect',
        enableSearchFilter: true,
        badgeShowLimit: 2,
        disabled: true,
        labelKey: 'name',
    };
    selectedCustomers: CustomerLight[] = [];
    availableCustomers: CustomerLight[] = [];

    tokens$: Observable<TokenModel>;
    operations = [];
    // beneficiaries = [];
    actions = [];
    rule: RuleModel;
    isLoading: boolean;
    editRule: EditRuleResponse;
    RewardsOperationsEnum = RewardsOperationsEnum;

    NUMERIC_VALUE_REGEX = '^[1-9][0-9]*$';

    constructor(
        private formBuilder: FormBuilder,
        private customerService: CustomerService,
        private store: Store<AppState>) {
    }

    ngOnInit(): void {
        this.isEditMode = this.infobarConfigs.params.isEditMode;

        this.tokens$ = this.store.select(getRewardTokens);
        this.store.pipe(select(sitesList)).subscribe((data: Array<SiteModel>) => {
            if (data) {
                this.availablePriceLevels = data.map(site => ({
                    id: site.id,
                    itemName: site.name
                }));
            }
        });

        if (this.isEditMode) {
            this.store.pipe(select(getEditRule)).subscribe((data: EditRuleResponse) => {
                this.editRule = data;
                this.isLoading = this.editRule.isLoading;
                if (!this.editRule.isLoading && this.editRule.rule) {
                    this.createForm(this.editRule);
                    this.selectedPriceLevels = data.beneficiaryPriceGroups
                        .map(priceGroup => ({ id: priceGroup.id, itemName: priceGroup.name }));

                    this.selectedCustomers = data.beneficiaryBusinessUnits
                        .map(businessUnit => ({ id: businessUnit.id, name: businessUnit.name }));

                    const search = {
                        page: 1,
                        priceLevels: data.beneficiaryPriceGroups.map(priceGroup => priceGroup.id)
                    };
                    this.customerService.getAllCustomers(search)
                        .subscribe(result => this.availableCustomers = result);
                }
            });
        }

        this.getEnumDropdownsData();

        if (!this.isEditMode) {
            this.createForm();
        }
    }

    createForm(editRule?: EditRuleResponse) {
        this.form = this.formBuilder.group({
            amount: [editRule ? editRule.rule.givenAmount : '', [Validators.required, Validators.pattern(this.NUMERIC_VALUE_REGEX)]],
            currency: [editRule ? editRule.rule.givenTokenId : '', Validators.required],
            beneficiary: [editRule ? editRule.rule.beneficiaryEntityTypeId : RewardsBeneficiariesEnum.User, Validators.required],
            action: [editRule ? editRule.rule.actionId : this.actions[0].id, Validators.required],
            operation: [editRule ? editRule.rule.actionOperationId : '', Validators.required],
            actionValue: [editRule ? editRule.rule.actionOperationValue : '',
                [Validators.required, Validators.pattern(this.NUMERIC_VALUE_REGEX)]],
            selectedCustomers: [editRule ? this.selectedCustomers : ''],
            selectedPriceLevels: [editRule ? this.selectedPriceLevels : '']
        });

        this.form.controls.selectedPriceLevels.valueChanges.subscribe(data => {
            if (data && data.length > 0) {
                const search = { page: 1, priceLevels: data.map(priceLevel => priceLevel.id) };
                this.customerService.getAllCustomers(search)
                    .subscribe(result => this.availableCustomers = result);

                this.customersSettings = {
                    ...this.customersSettings,
                    disabled: false
                };
            } else {
                this.selectedCustomers = [];
                this.form.controls.selectedCustomers.setValue(null);
                this.customersSettings = {
                    ...this.customersSettings,
                    disabled: true
                };
            }
        });

        this.form.controls.operation.valueChanges.subscribe(operation => {
            if (operation === RewardsOperationsEnum['For Every']) {
                this.form.controls.actionValue.setValue(1);
                this.form.updateValueAndValidity();
            }
        });
    }

    getEnumDropdownsData() {
        for (const key in Object.keys(RewardsOperationsEnum)) {
            if (isNaN(Number(key)) === false && RewardsOperationsEnum[key] !== undefined) {
                this.operations.push({
                    id: Number(key),
                    value: RewardsOperationsEnum[key]
                });
            }
        }

        // for (const key in Object.keys(RewardsBeneficiariesEnum)) {
        //     if (isNaN(Number(key)) === false && RewardsBeneficiariesEnum[key] !== undefined) {
        //         this.beneficiaries.push({
        //             id: Number(key),
        //             value: RewardsBeneficiariesEnum[key]
        //         });
        //     }
        // }

        for (const key in Object.keys(RewardsRuleActions)) {
            if (isNaN(Number(key)) === false && RewardsRuleActions[key] !== undefined) {
                this.actions.push({
                    id: Number(key),
                    value: RewardsRuleActions[key]
                });
            }
        }
    }

    onDeSelectAllPriceLevels(items: Array<any>) {
        this.selectedPriceLevels = items;
    }

    onDeSelectAllCustomers(items: Array<any>) {
        this.selectedCustomers = items;
    }

    save() {
        const saveRuleModel = new RuleModel(this.form.value);
        saveRuleModel.id = this.editRule ? this.editRule.rule.id : undefined;

        this.store.dispatch(new SaveRule({ rule: saveRuleModel, isEditMode: this.isEditMode }));
    }
}
