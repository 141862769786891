import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { Platform } from '../../models/branding/platform.model';

const VENDOR_STORE_KEY = 'oewijf43iod-dsifdsf4-sfdsf4-sf4s4';

@Injectable({
    providedIn: 'root'
})
export class BrandingService {

    constructor(private http: HttpClient) { }

    getPlatformConfiguration(): Observable<Platform> {
        return this.http.get<Platform>(`${environment.api.content}/Branded/configurations`);
    }

    getPrepareAccountDetails(componentId: number) {
        return this.http.get(`${environment.api.content}/Branded/custom_component/${componentId}`);
    }
}
