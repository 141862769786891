import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'app-stepper',
    templateUrl: './stepper.component.html',
    styleUrls: ['./stepper.component.scss']
})
export class StepperComponent implements OnInit {

    @Input() steps: string[];
    @Input() currentStepIndex: number;

    constructor() {
    }

    ngOnInit(): void {
    }

}
