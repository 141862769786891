import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Draft } from '../../models/draft/draft.model';
import { environment } from 'src/environments/environment';
import { DatePipe } from '@angular/common';
import { DraftRequest } from '../../models/draft/draft-request.model';
import { ProcessDraftsResponse } from '../../models/draft/process-drafts-response.model';
import { DraftOrderStatusType } from '../../enums/draft-status.enum';

@Injectable({
    providedIn: 'root'
})
export class DraftService {

    constructor(
        private http: HttpClient,
        private datePipe: DatePipe) { }

    getDrafts(params: { page: number, pageSize: number, statusId: DraftOrderStatusType, start: Date, end: Date; }): Observable<Array<Draft>> {
        const parameters = {
            page: params.page,
            pageSize: params.pageSize,
            statusId: params.statusId,
            start: this.datePipe.transform(params.start, 'MM/dd/yyyy'),
            end: this.datePipe.transform(params.end, 'MM/dd/yyyy')
        };

        return this.http.post<Array<Draft>>(`${environment.api.content}/order/drafts`, parameters);
    }

    processDrafts(params: { drafts: DraftRequest[]; }): Observable<ProcessDraftsResponse> {
        return this.http.post<ProcessDraftsResponse>(`${environment.api.content}/order/drafts/load_to_cart`, params);
    }

    getDraftsCount() {
        return this.http.get(`${environment.api.content}/order/drafts/count`);
    }
}
