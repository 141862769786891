import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/app.reducer';
import { LoadStatuses } from '../../actions/order.actions';
import { Observable } from 'rxjs';
import { selectStatuses } from '../../selectors/order.selector';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { map, tap } from 'rxjs/operators';
import { LeadsStatusFilter } from 'src/app/core/enums/leads-status.enum';

@Component({
    selector: 'app-customer-leads-status-filter',
    templateUrl: './customer-leads-status-filter.component.html',
    styleUrls: ['./customer-leads-status-filter.component.scss']
})
export class CustomerLeadsStatusFilterComponent implements OnInit {

    @Input() customerStatus: LeadsStatusFilter[] = [];
    @Output() change = new EventEmitter<any>();


    showPending = false;
    showApproved = false;
    showRejected = false;

    constructor(
        private store: Store<AppState>
    ) { }

    ngOnInit() {
    }

    ngOnChanges() {
        this.showPending = false;
        this.showApproved = false;
        this.showRejected = false;

        this.customerStatus.forEach(cs => {
            switch (cs) {
                case LeadsStatusFilter.Pending:
                    this.showPending = true;
                    break;
                case LeadsStatusFilter.Approved:
                    this.showApproved = true;
                    break;
                case LeadsStatusFilter.Rejected:
                    this.showRejected = true;
                    break;
            }
        });
    }

    onSelectPending($event: MatCheckboxChange) {
        this.showPending = $event.checked;
        this.triggerChange();
    }

    onSelectApproved($event: MatCheckboxChange) {
        this.showApproved = $event.checked;
        this.triggerChange();
    }

    onSelectRejected($event: MatCheckboxChange) {
        this.showRejected = $event.checked;
        this.triggerChange();
    }

    onSelectAll() {
        this.showPending = false;
        this.showApproved = false;
        this.triggerChange();
    }

    private triggerChange() {
        const customerStatus = [];
        if (this.showPending) {
            customerStatus.push(LeadsStatusFilter.Pending);
        }

        if (this.showApproved) {
            customerStatus.push(LeadsStatusFilter.Approved);
        }
        if (this.showRejected) {
            customerStatus.push(LeadsStatusFilter.Rejected);
        }

        this.change.emit(customerStatus);
    }
}
