import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { PlatformType } from 'src/environments/platform-type.enum';

@Component({
  selector: 'app-reports-lists',
  templateUrl: './reports-lists.component.html',
  styleUrls: ['./reports-lists.component.scss']
})
export class ReportsListsComponent implements OnInit {

  PlatformType = PlatformType;

  constructor() { }

  ngOnInit() {
  }

  get currentPlatform() { return environment.platformId; }
}
