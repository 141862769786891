import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ViewModes } from '../../../../../core/enums/view-mode.enum';

@Component({
    selector: 'app-show-items-temp1',
    templateUrl: './show-items.temp1.component.html',
    styleUrls: ['./show-items.temp1.component.scss']
})
export class ShowItemsTemp1Component implements OnInit {
    @Input() showWithPriceItemsFilter: boolean;
    @Input() viewModes: any[];
    @Output() selectAll = new EventEmitter();
    @Output() select = new EventEmitter<any>();
    @Output() modifyAll = new EventEmitter<boolean>();

    ViewModes = ViewModes;

    constructor() {
    }

    ngOnInit(): void {
    }

    onSelectAll(): void {
        this.selectAll.emit();
    }

    onSelect($event: any, viewMode: any): void {
        this.select.emit({ isSelected: $event.checked, viewModeId: viewMode.id });
    }

    onModifyAll(value: boolean): void {
        this.modifyAll.emit(value);
    }
}
