import { ModalIconType } from './../../../../shared/components/generic-modal/modal-icon.type';
import { GenericModalComponent } from './../../../../shared/components/generic-modal/generic-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { UserService } from 'src/app/core/services/user/user.service';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { AppState } from 'src/app/app.reducer';
import { AccountTypes } from 'src/app/core/enums/account-type.enum';
import { COMPANY_TYPE } from 'src/app/core/enums/company-type.enum';
import { TemplateEnum } from 'src/app/core/enums/template.enum';
import { BrandingModel } from 'src/app/core/models/branding/branding.model';
import { Platform } from 'src/app/core/models/branding/platform.model';
import { AppUser } from 'src/app/core/models/user.model';
import { Logout } from 'src/app/shared/actions/auth.actions';
import { LoadNewOrdersCount } from 'src/app/shared/actions/order.actions';
import { currentCompanyType, currentUserDetails, getAccountTypeId } from 'src/app/shared/selectors/auth.selector';
import { getPlatformConfiguration, getThemeId } from 'src/app/shared/selectors/branding.selector';
import { newOrdersCount } from 'src/app/shared/selectors/order.selector';
import { environment } from 'src/environments/environment';
import { PlatformType } from 'src/environments/platform-type.enum';

@Component({
    selector: 'app-page-sidebar',
    templateUrl: './page-sidebar.component.html',
    styleUrls: ['./page-sidebar.component.scss']
})
export class PageSidebarComponent implements OnInit {

    @Input() isMenuOpen = false;
    @Output() toggleMenuEmit = new EventEmitter();
    template$: Observable<TemplateEnum>;
    templateEnum = TemplateEnum;
    currentPlatform = environment.platformId;
    PlatformType = PlatformType;
    branding: BrandingModel;
    currentUser: AppUser;
    currentCompanyType$: Observable<COMPANY_TYPE>;
    COMPANY_TYPE = COMPANY_TYPE;
    newOrdersCount$: Observable<number>;
    accountType$: Observable<AccountTypes>;
    AccountTypes = AccountTypes;
    platform: Platform;

    constructor(private store: Store<AppState>,
        private userService: UserService,
        private dialog: MatDialog) { }

    ngOnInit(): void {
        this.store.pipe(select(getPlatformConfiguration)).subscribe((platform: Platform) => {
            this.platform = platform;
            if (platform && platform.branding) {
                this.branding = platform.branding;
            }
        });
        this.template$ = this.store.select(getThemeId);
        this.store.pipe(select(currentUserDetails)).subscribe(userDetails => {
            if (userDetails) {
                this.currentUser = userDetails;
            }
        });

        this.currentCompanyType$ = this.store.select(currentCompanyType);
        this.newOrdersCount$ = this.store.select(newOrdersCount);
        this.accountType$ = this.store.select(getAccountTypeId);

        this.store.dispatch(new LoadNewOrdersCount());
    }

    get logo() { return `${environment.assets.baseUrl}/logo.png`; }

    get isCrispAvailable() { return window['$crisp']; }

    openSupportChat() {
        const isVisible = window['$crisp'].is("chat:visible");
        window['$crisp'].push(["do", `chat:${isVisible ? 'hide' : 'show'}`]);
        if (!isVisible) {
            window['$crisp'].push(["do", "chat:open"]);
        }
    }

    getPoweredBy(): string {
        switch (this.currentPlatform) {
            case PlatformType.AMAST:
                return 'Powered By Amast';
            case PlatformType.ACADIA_MARKET:
                return 'Powered By AcadiaMarket';
            default:
                return 'Powered By DineMarket';
        }
    }

    logout() {
        this.store.dispatch(new Logout());
    }

    toggleMenu() {
        this.isMenuOpen = !this.isMenuOpen;
        this.toggleMenuEmit.emit();
    }

    getPlatformMenu(): string {
        switch (this.currentPlatform) {
            case PlatformType.ACADIA_MARKET:
                return 'menu__item--active--acadia';
            case PlatformType.AMAST:
                return 'menu__item--active--amast';
            default:
                return 'menu__item--active';
        }
    }

    getPlatformIcon(): string {
        switch (this.currentPlatform) {
            case PlatformType.AMAST:
                return 'icon--reverse';
            case PlatformType.ACADIA_MARKET:
                return 'icon--reverse';
            default:
                return '';
        }
    }

}
