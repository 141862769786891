export class ProductSettingsModel {
    productId: number;
    priceLevelId: number;
    isOffered: boolean;
    isAvailable: boolean;
    isAvailableLocked: boolean;

    constructor(productId: number, priceLevelId: number, isOffered: boolean, isAvailable: boolean, isAvailableLocked: boolean) {
        this.productId = productId;
        this.priceLevelId = priceLevelId;
        this.isAvailable = isAvailable;
        this.isOffered = isOffered;
        this.isAvailableLocked = isAvailableLocked;
    }
}
