import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Observable } from 'rxjs';
import { Action, Store } from '@ngrx/store';
import * as draftActions from '../actions/draft.actions';
import { mergeMap, map, catchError, tap } from 'rxjs/operators';
import { DraftService } from 'src/app/core/services/draft/draft.service';
import { Draft } from 'src/app/core/models/draft/draft.model';
import { ProcessDraftsResponse } from 'src/app/core/models/draft/process-drafts-response.model';
import { Router } from '@angular/router';
import { AppState } from 'src/app/app.reducer';

@Injectable()
export class DraftEffects {

    constructor(
        private actions$: Actions,
        private router: Router,
        private store: Store<AppState>,
        private draftService: DraftService) { }

    @Effect()
    drafts$: Observable<Action> = this.actions$.pipe(
        ofType(draftActions.DraftActionTypes.LoadDraft),
        mergeMap((action: draftActions.LoadDrafts) => this.draftService.getDrafts(action.payload).pipe(
            map((drafts: Array<Draft>) => new draftActions.LoadDraftSuccess(drafts))
        ))
    );

    @Effect()
    processDrafts$: Observable<Action> = this.actions$.pipe(
        ofType(draftActions.DraftActionTypes.ProcessDrafts),
        mergeMap((action: draftActions.ProcessDrafts) => this.draftService.processDrafts(action.payload).pipe(
            map((response: ProcessDraftsResponse) => new draftActions.ProcessDraftsSuccess({ response }))
        ))
    );

    @Effect()
    processDraftsSuccess$: Observable<Action> = this.actions$.pipe(
        ofType(draftActions.DraftActionTypes.ProcessDraftsSuccess),
        tap((action: draftActions.ProcessDraftsSuccess) => {
            if (action.payload.response.isSuccess) {
                this.router.navigate(['/cart']);
            }
        }
        ),
        map((action: draftActions.ProcessDraftsSuccess) => {
            if (action.payload.response.isSuccess) {
                return new draftActions.LoadDraftsCount();
            } else {
                // TODO: Handle conflicts
                return new draftActions.HandleConflicts({ conflicts: action.payload.response.conflicts});

            }
        })
    );

    @Effect()
    draftsCount$: Observable<Action> = this.actions$.pipe(
        ofType(draftActions.DraftActionTypes.LoadDraftsCount),
        mergeMap((action: draftActions.LoadDrafts) => {
            return this.draftService.getDraftsCount().pipe(
                map((count: number) => new draftActions.LoadDraftsCountSuccess({ count }))
            );
        })
    );

}
