import { state } from '@angular/animations';
import { createSelector } from '@ngrx/store';

export const selectOrderGuideState = state => state.orderGuide;

export const selectOrderGuideDetails = createSelector(
    selectOrderGuideState,
    state => state.orderGuideDetails
);

export const selectOrderGuideDetailsLoading = createSelector(
    selectOrderGuideState,
    state => state.loading
);

export const selectCategories = createSelector(
    selectOrderGuideState,
    state => state.categories
);

export const selectVendors = createSelector(
    selectOrderGuideState,
    state => state.vendors
);

export const selectCategoryHistory = createSelector(
    selectOrderGuideState,
    state => state.categoryHistory
);
export const selectProductDetails = createSelector(
    selectOrderGuideState,
    state => state.productDetails
);

export const selectGridViewMode = createSelector(
    selectOrderGuideState,
    state => state.gridViewMode
);

export const selectRelatedProducts = createSelector(
    selectOrderGuideState,
    state => state.relatedProducts
);

export const selectProductDetailsPurchaseHistory = createSelector(
    selectOrderGuideState,
    state => state.productDetailsPurchaseHistory
);

export const selectProductPriceTrends = createSelector(
    selectOrderGuideState,
    state => state.productDetailsPriceTrends
);

export const selectVendorsCutoff = createSelector(
    selectOrderGuideState,
    state => state.cutoffDetails
);

export const selectShowPricedItemsFilter = createSelector(
    selectOrderGuideState,
    state => state.showPricedItemsFilter
);

export const selectProductGroup = createSelector(
    selectOrderGuideState,
    state => state.productGroup
);

export const selectLastProductGroupDeleted = createSelector(
    selectOrderGuideState,
    state => state.lastProductGroupDeleted
);

export const selectBuyers = createSelector(
    selectOrderGuideState,
    state => state.buyers
);

export const printLoading = createSelector(
    selectOrderGuideState,
    state => state.printLoading
);

export const customersList = createSelector(
    selectOrderGuideState,
    state => state.customersList
)

export const loadProductsOrderGuide = createSelector(
    selectOrderGuideState,
    state => state.productsOrderGuide
)

export const selectModifiedPrice = createSelector(
    selectOrderGuideState,
    state => state.modifiedPrice
)