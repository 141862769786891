export class MessageModel {
    id: number;
    memberKey: string;
    typeId: number;
    channelUuid: string;
    channelId: number;
    text: string;
    displayName: string;
    acronym: string;
    fromBuyerSide: boolean;
    createdDate: string;
    shortDescriptionBuyerVendor: string;

    constructor(data?: MessageModel) {
        if (data) {
            this.id = data.id;
            this.memberKey = data.memberKey;
            this.typeId = data.typeId;
            this.channelUuid = data.channelUuid;
            this.channelId = data.channelId;
            this.text = data.text;
            this.displayName = data.displayName;
            this.fromBuyerSide = data.fromBuyerSide;
            this.createdDate = data.createdDate;
            this.acronym = data.acronym;
        }
    }
}
