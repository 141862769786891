import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/app.reducer';
import { RatingService } from 'src/app/core/services/rating/rating.service';
import { map, exhaustMap, catchError} from 'rxjs/operators';
import { LoadRatingCategories, LoadRatingCategoriesSuccess, RatingActionTypes, AddRating, AddRatingSuccess, LoadRatingSuccess, LoadRating, AddRatingError } from '../actions/rating.actions';
import { NotifierService } from 'angular-notifier';
import { MatDialog } from '@angular/material/dialog';
import { of } from 'rxjs';

@Injectable()
export class RatingEffects {

  @Effect()
  loadRatingCategories$ = this.actions$.pipe(
      ofType<LoadRatingCategories>(RatingActionTypes.LoadRatingCategoriesAction),
      exhaustMap((action) => this.ratingSvc.getRatingCategories(action.payload.entityTypeId)),
      map((categories: any[]) => new LoadRatingCategoriesSuccess ({ categories }))
  );


  @Effect({ dispatch: false })
  addRating$ = this.actions$.pipe(
      ofType<AddRating>(RatingActionTypes.AddRatingAction),
      exhaustMap((action) => this.ratingSvc.addRating(action.payload)
        .pipe(
          map(() => {
            const modalInstance = this.matDialog.openDialogs.find(d => d.id === 'add-rating-modal');
            if (modalInstance) {
                modalInstance.close();
            }
            this.notifier.notify('success', 'Thank you! Your feedback is appreciated');
          }),
          catchError(err => {
            this.notifier.notify('error', 'Rating: something went wrong');        
            return err;
          }),
        ))
  );

  @Effect()
  loadRating$ = this.actions$.pipe(
      ofType<LoadRating>(RatingActionTypes.LoadRatingAction),
      exhaustMap((action) => this.ratingSvc.getRating(action.payload.entityTypeId, action.payload.entityId)),
      map((rating: any[]) => new LoadRatingSuccess ({ rating }))
  );

  constructor(
    private actions$: Actions,
    private ratingSvc: RatingService,
    private notifier: NotifierService,
    private matDialog: MatDialog,
  ) { }
}
