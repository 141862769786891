export class GeoAddressModel {
    address1: string;
    address2: string;
    city: string;
    zipCode: string;
    state: string;
    country: string;
    latitude: number;
    longitude: number;
    googleLocationId: string;
    utcOffset: number;
}
