import { Action } from '@ngrx/store';
import { VendorCategoryFilter } from 'src/app/core/enums/vendor-cat-filter.enum';

export enum VendorActionTypes {
  LoadVendorsAction = '[Vendor] Load Vendor',
  VendorsLoadedAction = '[Vendor] Vendor Loaded Success',
  SetVendorFavorite = '[Vendor] Set Vendor Favorite',
  ManageRelationAction  = '[Vendor] Manage Relation',
  ManageRelationSuccess  = '[Vendor] Manage Relation Success',
  LoadCategoriesAction = '[Vendor] Load Categories',
  LoadCategoriesSuccessAction = '[Vendor] Load Categories Success',
}

export class LoadVendors implements Action {
  readonly type = VendorActionTypes.LoadVendorsAction;

  constructor(public payload: { page: number, categories?: number[], type?: VendorCategoryFilter }) { }
}

export class VendorsLoaded implements Action {
  readonly type = VendorActionTypes.VendorsLoadedAction;

  constructor(public payload: { vendors: any }) { }
}

export class SetVendorToFavorite implements Action {
  readonly type = VendorActionTypes.SetVendorFavorite;

  constructor(public payload: { vendorId: number, isFavorite: boolean }) { }
}

export class ManageRelation implements Action {
  readonly type = VendorActionTypes.ManageRelationAction;

  constructor(
    public payload: { vendorSiteId: any, vendorAccountId: any, action: any, operationType: any, isFinalized: any, vendorRequestId: any }) { }
}

export class ManageRelationSuccess implements Action {
  readonly type = VendorActionTypes.ManageRelationSuccess;

  constructor(public payload: { response: any }) { }
}

export class LoadCategories implements Action {
  readonly type = VendorActionTypes.LoadCategoriesAction;
}

export class LoadCategoriesSuccess implements Action {
  readonly type = VendorActionTypes.LoadCategoriesSuccessAction;

  constructor( public payload: { categories: any[] } ) {}
}

export type VendorActions = 
LoadVendors
| VendorsLoaded
| SetVendorToFavorite
| ManageRelation 
| ManageRelationSuccess
| LoadCategories
| LoadCategoriesSuccess;
