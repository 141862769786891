import {Component, OnInit, Input} from '@angular/core';
import {environment} from 'src/environments/environment';
import {PlatformType} from 'src/environments/platform-type.enum';

@Component({
    selector: 'app-icon-cart',
    templateUrl: './icon-cart.component.html',
    styleUrls: ['./icon-cart.component.scss']
})
export class IconCartComponent implements OnInit {

    @Input() cssClass = '';

    currentPlatform = environment.platformId;
    PlatformType = PlatformType;

    constructor() {}

    ngOnInit() {}

}
