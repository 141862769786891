import { Component, OnInit, Input } from '@angular/core';
import { environment } from 'src/environments/environment';
import { PlatformType } from 'src/environments/platform-type.enum';

@Component({
    selector: 'app-icon-play',
    templateUrl: './icon-play.component.html',
    styleUrls: ['./icon-play.component.scss']
})
export class IconPlayComponent implements OnInit {

    @Input() cssClass = '';

    currentPlatform = environment.platformId;
    PlatformType = PlatformType;

    constructor() { }

    ngOnInit() {
    }

}
