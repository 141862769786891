import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ChatState } from '../reducers/chat.reducer';
import { ChatConversation } from 'src/app/core/models/chat/chat-conversation.model';
import { EntityChanel } from 'src/app/core/models/chat/entity-chanel.model';

const getChatState = createFeatureSelector<ChatState>('chat');

export const getChatLoading = createSelector(
    getChatState,
    state => state.loading
);

export const getChatChannels = createSelector(
    getChatState,
    state => state.entityChannels
);

export const getChatDisplayStatus = createSelector(
    getChatState,
    state => state.chatDisplayStatus
);

export const getEntityChannel = createSelector(
    getChatChannels,
    (state: Array<EntityChanel>, props) => {
        return state.find(channel => channel.entityId === props.entityId);
    }
);

export const getConversations = createSelector(
    getChatState,
    state => state.conversations
);

export const getBuyersVendors = createSelector(
    getChatState,
    state => state.buyersVendors
);

export const getConversationsByChannel = createSelector(
    getConversations,
    (state: Array<ChatConversation>, props) => {
        return state && state.find(conversation => conversation.channel.id === props.channelId);
    }
);
