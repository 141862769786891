import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { Cacheable } from 'ngx-cacheable';

@Injectable({
  providedIn: 'root'
})
export class MarketingService {

  constructor(
    private http: HttpClient
  ) { }

  @Cacheable()
  getLoginUrl(): Observable<any> {
    return this.http.get<any>(`${environment.api.content}/User/marketing_login_url`);
  }
  
  @Cacheable()
  getHomeUrl(): Observable<any> {
    return this.http.get<any>(`${environment.api.content}/User/marketing_home_url`);
  }
}
