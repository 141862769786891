import { Component, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/app.reducer';
import { selectCreditRequestApp } from '../../selectors/site.selector';
import { filter, map, takeUntil } from 'rxjs/operators';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CreditApplication } from '../../actions/site.actions';
import { BusinessType } from 'src/app/core/enums/business-type.enum';
import { TitleType } from 'src/app/core/enums/title-type.enum';
import { selectCustomerLeads } from '../../selectors/customer.selector';
import { randomColor } from '../../constants/global.contants';
import { LeadsStatus } from 'src/app/core/enums/leads-status.enum';
import { MatDialog } from '@angular/material/dialog';
import { LeadsRejectModalComponent } from '../leads-reject-modal/leads-reject-modal.component';
import { LeadsAcceptModalComponent } from '../leads-accept-modal/leads-accept-modal.component';
import { InfobarState } from '../../reducers/infobar.reducer';

@Component({
    selector: 'app-leads-details',
    templateUrl: './leads-details.component.html',
    styleUrls: ['./leads-details.component.scss']
})
export class LeadsDetailsComponent implements OnInit, OnChanges, OnDestroy {
    @Input() infobarConfigs: InfobarState;

    leadsInfo: any;
    users$: Observable<any>;
    lead$: Observable<any>;
    states$: Observable<any>;
    formLead: FormGroup;
    LeadsStatus = LeadsStatus;

    private ngUnsubscribe: Subject<void> = new Subject<void>();

    constructor(
        private store: Store<AppState>,
        private fb: FormBuilder,
        private dialog: MatDialog) { }

    ngOnInit() {
        this.leadsInfo = this.infobarConfigs.params.leadsInfo;

        this.formLead = this.fb.group({
            business: this.fb.group({
                corporateName: [null, [Validators.required]],
                dbaName: [null, [Validators.required]],
                typeOfBusiness: [null],
                authorizedPurchasers: [null],
                federalTaxId: [null],
                liquorLicenceNumber: [null],
                accountPayableContact: this.fb.group({
                    firstName: [null, [Validators.required]],
                    lastName: [null, [Validators.required]],
                    email: [null, [Validators.required]],
                    phone: [null, [Validators.required]],
                }),
                corporateAddress: this.fb.group({
                    address1: [null, [Validators.required]],
                    address2: [null],
                    city: [null, [Validators.required]],
                    zipCode: [null, [Validators.required]],
                    email: [null],
                    company: [null],
                    stateName: [null, [Validators.required]],
                    firstName: [null],
                    lastName: [null],
                    phone: [null, [Validators.required]],
                    country: [null],
                    phone2: [null],
                    fax: [null],
                }),
            }),
            deliveryInformation: this.fb.group({
                deliveryAddress: this.fb.group({
                    address1: [null, [Validators.required]],
                    address2: [null],
                    city: [null, [Validators.required]],
                    zipCode: [null, [Validators.required]],
                    email: [null],
                    company: [null],
                    stateName: [null, [Validators.required]],
                    firstName: [null],
                    lastName: [null],
                    phone: [null, [Validators.required]],
                    country: [null],
                    phone2: [null],
                    fax: [null],
                }),
                earliestDeliveryTime: [null, [Validators.required, Validators.pattern(/^([1-9]|1[0-2]):([0-5]\d)\s?(AM|PM)?$/i)]],
                latestDeliveryTime: [null, [Validators.required, Validators.pattern(/^([1-9]|1[0-2]):([0-5]\d)\s?(AM|PM)?$/i)]],
                specialInstructions: [null],
            }),
            ownerInformation: this.fb.group({
                title: [null],
                position: [null],
                name: [null],
                ssn: [null],
                // firstName: [null],
                // lastName: [null],
                address: this.fb.group({
                    address1: [null],
                    address2: [null],
                    city: [null],
                    zipCode: [null],
                    email: [null],
                    company: [null],
                    stateName: [null],
                    firstName: [null],
                    lastName: [null],
                    phone: [null],
                    country: [null],
                    phone2: [null],
                    fax: [null],
                    // authorizedPurchasers: [null],
                    // federalTaxId: [null],
                    // liquorLicenceNumber: [null]
                })
            }),
            officeInformation: this.fb.group({
                title: [null],
                position: [null],
                name: [null],
                ssn: [null],
                // firstName: [null],
                // lastName: [null],
                address: this.fb.group({
                    address1: [null],
                    address2: [null],
                    city: [null],
                    zipCode: [null],
                    email: [null],
                    company: [null],
                    stateName: [null],
                    firstName: [null],
                    lastName: [null],
                    phone: [null],
                    country: [null],
                    phone2: [null],
                    fax: [null]
                    // authorizedPurchasers: [null],
                    // federalTaxId: [null],
                    // liquorLicenceNumber: [null]
                })
            }),
            financialInformation: this.fb.group({
                bankName: [null],
                bankRoutingNumber: [null],
                accountNumber: [null],
                bankAddress: this.fb.group({
                    address1: [null],
                    address2: [null],
                    city: [null],
                    zipCode: [null],
                    email: [null],
                    company: [null],
                    stateName: [null],
                    firstName: [null],
                    lastName: [null],
                    phone: [null],
                    country: [null],
                    phone2: [null],
                    fax: [null],
                })
            }),
            tradeReferences: this.fb.group({
                vendorName: [null],
                vendorAddress: this.fb.group({
                    address1: [null],
                    address2: [null],
                    city: [null],
                    zipCode: [null],
                    email: [null],
                    company: [null],
                    stateName: [null],
                    firstName: [null],
                    lastName: [null],
                    phone: [null],
                    country: [null],
                    phone2: [null],
                    fax: [null],
                })
            }),
            tradeReferences1: this.fb.group({
                vendorName: [null],
                vendorAddress: this.fb.group({
                    address1: [null],
                    address2: [null],
                    city: [null],
                    zipCode: [null],
                    email: [null],
                    company: [null],
                    stateName: [null],
                    firstName: [null],
                    lastName: [null],
                    phone: [null],
                    country: [null],
                    phone2: [null],
                    fax: [null],
                })
            }),
            tradeReferences2: this.fb.group({
                vendorName: [null],
                vendorAddress: this.fb.group({
                    address1: [null],
                    address2: [null],
                    city: [null],
                    zipCode: [null],
                    email: [null],
                    company: [null],
                    stateName: [null],
                    firstName: [null],
                    lastName: [null],
                    phone: [null],
                    country: [null],
                    phone2: [null],
                    fax: [null],
                })
            })
        });

        this.store.select(selectCreditRequestApp).pipe(
            takeUntil(this.ngUnsubscribe),
            filter(creditReqApp => creditReqApp !== undefined),
        ).subscribe((creditReqApp) => {
            if (creditReqApp) {
                this.formLead.patchValue(creditReqApp);
            }
        });

    }

    ngOnChanges() {
        this.lead$ = this.store.select(selectCustomerLeads).pipe(
            map(lead => lead?.items?.filter(l => l.id === this.leadsInfo.id).map(lc => ({
                ...lc,
                randomColor: randomColor()
            }))),
        );

        if (this.leadsInfo) {
            this.store.dispatch(new CreditApplication({ siteId: this.leadsInfo.id }));
        }
    }

    ngOnDestroy() {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    get formDeliveryInfo() { return (this.formLead.controls.deliveryInformation as FormGroup).controls; }
    get businessTypeEnum() { return Object.keys(BusinessType); }
    get titleTypeEnum() { return Object.keys(TitleType); }


    onReject(lead) {
        this.dialog.open(LeadsRejectModalComponent, {
            data: lead
        });
    }

    onAccept(lead) {
        this.dialog.open(LeadsAcceptModalComponent, {
            data: lead
        });
    }
}
