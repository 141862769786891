import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'app-no-results-temp1',
    templateUrl: './no-results.temp1.component.html',
    styleUrls: ['./no-results.temp1.component.scss']
})
export class NoResultsTemp1Component {
    @Output() clearFilters = new EventEmitter<void>();
    @Input() url = '/order-guide';

    onClearFilters(): void {
        this.clearFilters.emit();
    }
}
