import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { AppState } from 'src/app/app.reducer';
import { TemplateEnum } from 'src/app/core/enums/template.enum';
import { getThemeId } from '../../selectors/branding.selector';

@Component({
    selector: 'app-confirmation-modal',
    templateUrl: './confirmation-modal.component.html',
    styleUrls: ['./confirmation-modal.component.scss']
})
export class ConfirmationModalComponent implements OnInit {

    template$: Observable<TemplateEnum>;
    templateEnum = TemplateEnum;

    constructor(
        public dialogRef: MatDialogRef<ConfirmationModalComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private store: Store<AppState>
    ) { }

    ngOnInit() {
        this.template$ = this.store.select(getThemeId);
    }

    close(confirm = false): void {
        this.dialogRef.close(confirm);
    }

}
