import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LeadsStatus } from 'src/app/core/enums/leads-status.enum';
import { SaveLeadsStatus } from '../../actions/customer.actions';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/app.reducer';
import { LeadsInfoModel } from 'src/app/core/models/customer/leads-info.model';
import { currentSiteID } from '../../selectors/auth.selector';
import { LoadCustomerUsers } from '../../actions/user.actions';
import { ToggleInfobar } from '../../actions/infobar.actions';

@Component({
    selector: 'app-leads-reject-modal',
    templateUrl: './leads-reject-modal.component.html',
    styleUrls: ['./leads-reject-modal.component.scss']
})
export class LeadsRejectModalComponent implements OnInit {

    leadsRejectFrom: FormGroup;
    LeadsStatus = LeadsStatus;
    currentSiteId: number;

    constructor(
        private store: Store<AppState>,
        public dialogRef: MatDialogRef<LeadsRejectModalComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private fb: FormBuilder) { }

    ngOnInit() {
        this.leadsRejectFrom = this.fb.group({
            declineReason: [null, [Validators.required]],
        });

        this.store.select(currentSiteID).subscribe(siteId => {
            this.currentSiteId = siteId;
        });
    }

    onSave() {
        if (this.leadsRejectFrom.invalid) {
            return;
        }

        const leadsInfo: LeadsInfoModel = {
            leadId: this.data.requestId,
            isProcessing: false,
            statusId: LeadsStatus.Rejected,
            customerSiteId: this.data.id,
            notes: this.leadsRejectFrom.controls.declineReason.value,
            priceLevelId: -1,
            representativeId: -1,
            currentSiteId: this.currentSiteId,
            displayName: this.data.displayName
        };

        this.dialogRef.close();
        this.store.dispatch(
            new ToggleInfobar({ open: false })
        );
        this.store.dispatch(new SaveLeadsStatus({ leadsInfo }));
    }

    close(): void {
        this.dialogRef.close();
    }
}
