import { Component, OnInit, Input } from '@angular/core';

@Component({
    selector: 'app-icon-file',
    templateUrl: './icon-file.component.html',
    styleUrls: ['./icon-file.component.scss']
})
export class IconFileComponent implements OnInit {

    @Input() cssClass = '';

    constructor() { }

    ngOnInit() {
    }

}
