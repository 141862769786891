import { Action } from '@ngrx/store';
import { AccountInfoModel } from 'src/app/core/models/account/account-info.model';
import { RegisterAccountModel } from 'src/app/core/models/account/account.model';

export enum RegisterActionTypes {
    Register = '[Register] Register Account',
    RegisterInfo = '[Register Info ] Register Info',
    UpdateRegisterLoading = '[Register] Register Account Error',
    VerifyAccount = '[VerifyAccount] VerifyAccount',
    VerifyAccountSuccess = '[VerifyAccount] VerifyAccountSuccess',
    VerifyAccountError = '[VerifyAccount] VerifyAccountError',
}

export class Register implements Action {
    readonly type = RegisterActionTypes.Register;

    constructor(public payload: { account: RegisterAccountModel; }) { }
}

export class RegisterInfo implements Action {
    readonly type = RegisterActionTypes.RegisterInfo;

    constructor(public payload: { account: AccountInfoModel; }) { }
}

export class UpdateRegisterLoading implements Action {
    readonly type = RegisterActionTypes.UpdateRegisterLoading;

    constructor(public payload: { loading?: boolean; }) { }
}

export class VerifyAccount implements Action {
    readonly type = RegisterActionTypes.VerifyAccount;

    constructor(public payload: { token: string; }) { }
}

export class VerifyAccountSuccess implements Action {
    readonly type = RegisterActionTypes.VerifyAccountSuccess;
}

export class VerifyAccountError implements Action {
    readonly type = RegisterActionTypes.VerifyAccountError;

    constructor(public payload: { message: string; }) { }
}

export type RegisterActions = Register
    | RegisterInfo
    | UpdateRegisterLoading
    | VerifyAccount
    | VerifyAccountSuccess
    | VerifyAccountError;
