import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: "roundUp",
})
export class RoundUpPipe implements PipeTransform {
    transform(value: number, ...args: any[]) {
        if (!value) {
            return;
        }
        let decimals = 100;
        if (args[0] && args[0] >= 0) {
            decimals = Math.pow(10, args[0]);
        }
        return Math.ceil(value * decimals) / decimals;
    }
}
