import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/app.reducer';
import { UserService } from 'src/app/core/services/user/user.service';
import {
    UserActionTypes, LoadUsers, LoadUsersSuccess, SetUserActive,
    AddUser, AddUserSuccess, EditUserSuccess, EditUser, AssignLocation, AssignLocationSuccess, SetEmailSettings,
    SetEmailSettingsSuccess, LoadCustomerUsersSuccess, LoadCustomerUsers, AddUserFailed, DeleteUser, DeleteUserSuccess
} from '../actions/user.actions';
import { exhaustMap, map, mergeMap, catchError } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { ChangeSiteAvailability } from '../actions/site.actions';
import { combineLatest, of } from 'rxjs';
import { getCurrentUserID } from '../selectors/auth.selector';
import { LoadUserDetails } from '../actions/auth.actions';
import { NotifierService } from 'angular-notifier';
import { AppUser } from 'src/app/core/models/user.model';
import { GenericModalComponent } from '../components/generic-modal/generic-modal.component';
import { ModalIconType } from '../components/generic-modal/modal-icon.type';



@Injectable()
export class UserEffects {

    @Effect()
    loadUsers$ = this.actions$.pipe(
        ofType<LoadUsers>(UserActionTypes.LoadUsersAction),
        exhaustMap(() => this.userSvc.loadUsers().pipe(
            map((users: Array<AppUser>) => new LoadUsersSuccess({ users }))
        ))
    );

    @Effect({ dispatch: false })
    setUserActive$ = this.actions$.pipe(
        ofType<SetUserActive>(UserActionTypes.SetUserActive),
        exhaustMap((action) => this.userSvc.setUserActive(action.payload.userId, action.payload.isActive))
    );

    @Effect()
    addUser$ = this.actions$.pipe(
        ofType<AddUser>(UserActionTypes.AddUserAction),
        exhaustMap((action) => this.userSvc.addUser(action.payload.user)
            .pipe(
                map((newUser) => {
                    const modalInstance = this.matDialog.openDialogs.find(d => d.id === 'add-user');
                    if (modalInstance) {
                        modalInstance.close();
                    }
                    this.matDialog.open(GenericModalComponent, {
                        id: 'success-modal',
                        data: {
                            hasTitle: true,
                            title: 'Success!',
                            message: 'A new user has been added.',
                            cancelButtonText: 'Close',
                            hasCancelButton: true,
                            hasConfirmButton: false,
                            hasCloseButton: true,
                            iconType: ModalIconType.SUCCESS
                        },
                    });
                    return new AddUserSuccess({ newUser });
                }), catchError(err => {
                    this.notifier.notify('error', err.error.message);
                    return of(new AddUserFailed());
                })),
        )
    );

    @Effect()
    deleteUser$ = this.actions$.pipe(
        ofType<DeleteUser>(UserActionTypes.DeleteUser),
        exhaustMap((action) => this.userSvc.deleteUser(action.payload)
            .pipe(
                map(response => new DeleteUserSuccess(action.payload))
            )
        )
    );

    @Effect({ dispatch: false })
    editUser$ = this.actions$.pipe(
        ofType<EditUser>(UserActionTypes.EditUserAction),
        exhaustMap((action) => this.userSvc.editUser(action.payload)
            .pipe(
                map((user) => {
                    const modalInstance = this.matDialog.openDialogs.find(d => d.id === 'edit-user');
                    if (modalInstance) {
                        modalInstance.close();
                    }
                    this.store.dispatch(new EditUserSuccess({ user }));
                    this.store.dispatch(new LoadUserDetails({ userDetails: user }));
                })
            ))
    );

    @Effect()
    assignLocation$ = this.actions$.pipe(
        ofType<AssignLocation>(UserActionTypes.AssignLocation),
        exhaustMap((action) => this.userSvc.assignLocation(action.payload.userId, action.payload.siteId, action.payload.isAssigned)
            .pipe(
                map(response => new AssignLocationSuccess(action.payload))
            )
        )
    );

    @Effect({ dispatch: false })
    assignLocationSuccess$ = combineLatest(
        this.actions$.pipe(ofType<AssignLocationSuccess>(UserActionTypes.AssignLocationSuccess)),
        this.store.select(getCurrentUserID)
    ).pipe(
        map(([action, currentUserId]) => {
            if (action.payload.userId === currentUserId) {
                this.store.dispatch(new ChangeSiteAvailability(action.payload));
            }
        })
    );

    @Effect()
    setEmailSettings$ = this.actions$.pipe(
        ofType<SetEmailSettings>(UserActionTypes.SetEmailSettings),
        exhaustMap((action) => this.userSvc.setEmailSettings(action.payload.userId, action.payload.siteId,
            action.payload.isEnabled, action.payload.emailSettings)
            .pipe(
                map(response => new SetEmailSettingsSuccess(action.payload))
            )
        )
    );

    @Effect()
    loadCustomerUsers$ = this.actions$.pipe(
        ofType(UserActionTypes.LoadCustomerUsers),
        mergeMap((action: LoadCustomerUsers) => this.userSvc.getCustomerUsers(action.payload).pipe(
            map((users: Array<any>) => new LoadCustomerUsersSuccess(users))
        ))
    );

    constructor(
        private actions$: Actions,
        private store: Store<AppState>,
        private userSvc: UserService,
        private matDialog: MatDialog,
        private notifier: NotifierService) { }
}
