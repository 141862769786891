import { Component, OnInit, Input } from '@angular/core';
import { environment } from 'src/environments/environment';
import { PlatformType } from 'src/environments/platform-type.enum';

@Component({
    selector: 'app-icon-star',
    templateUrl: './icon-star.component.html',
    styleUrls: ['./icon-star.component.scss']
})
export class IconStarComponent implements OnInit {

    @Input() cssClass = '';
    @Input() fill = false;

    currentPlatform = environment.platformId;
    PlatformType = PlatformType;

    constructor() { }

    ngOnInit() {
    }

}
