import { Router, NavigationStart } from '@angular/router';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { COMPANY_TYPE } from 'src/app/core/enums/company-type.enum';
import { AppUser } from 'src/app/core/models/user.model';
import { Platform } from '../../../../../../core/models/branding/platform.model';

@Component({
    selector: 'app-page-header-temp1',
    templateUrl: './page-header.temp1.component.html',
    styleUrls: ['./page-header.temp1.component.scss']
})
export class PageHeaderTemp1Component implements OnInit {
    @Input() currentUser: AppUser;
    @Input() showSearch: boolean;
    @Input() showOnlyFilter: boolean;
    @Input() newMessages: number;
    @Input() showLocationSelection: boolean;
    @Input() currentCompanyType: COMPANY_TYPE;
    @Input() platform: Platform;
    @Input() customersTab: boolean;
    @Input() orderGuideSelected: boolean;
    @Output() showFilter = new EventEmitter();
    @Output() sendVerificationEmail: EventEmitter<any> = new EventEmitter<any>();
    @Output() openChat = new EventEmitter();
    @Output() openAIChat = new EventEmitter();
    @Output() zipCodeChange = new EventEmitter();
    @Output() toggleMenu = new EventEmitter();

    showFilterIcon = true;
    COMPANY_TYPE = COMPANY_TYPE;

    constructor(private router: Router) {}

    ngOnInit(): void {
        this.router.events
        .subscribe(
          (event: NavigationStart) => {
            if(event instanceof NavigationStart) {
              this.showFilterIcon = event.url === '/payments' ? false : true;
            }
      });

    }

    onShowFilter() {
        this.showFilter.emit();
    }

    onSendVerificationEmail($event) {
        this.sendVerificationEmail.emit($event);
    }

    onOpenChat() {
        this.openChat.emit();
    }

    changeZipCode() {
        this.zipCodeChange.emit();
    }

    onToggleMenu() {
        this.toggleMenu.emit();
    }

    openAIChatPanel() {
        this.openAIChat.emit();
    }
}
