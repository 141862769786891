import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'app-icon-undo',
    templateUrl: './icon-undo.component.html',
    styleUrls: ['./icon-undo.component.scss']
})
export class IconUndoComponent implements OnInit {

    @Input() cssClass: string;

    constructor() { }

    ngOnInit(): void {
    }

}
