import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/app.reducer';
import { Observable } from 'rxjs';
import { EntityType } from 'src/app/core/enums/entity-type.enum';
import { ratingCategories, loadingRatingCat, selectRating } from '../../selectors/rating.selector';
import { LoadRatingCategories, LoadRating } from '../../actions/rating.actions';
import { Rating } from 'src/app/core/models/rating.model';

@Component({
  selector: 'app-rating-details',
  templateUrl: './rating-details.component.html',
  styleUrls: ['./rating-details.component.scss']
})
export class RatingDetailsComponent implements OnInit, OnChanges {

  @Input()
  entityTypeId: EntityType;

  @Input()
  entityId: number;


  categories$: Observable<any>;
  loading$: Observable<boolean>;
  rating$: Observable<Rating>;

  constructor(
    private store: Store<AppState>,) { }

  ngOnInit() { }

  ngOnChanges() {
    const { entityId, entityTypeId } = this;
    this.rating$ = this.store.select(selectRating(entityId, entityTypeId));
  }

}
