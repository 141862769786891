import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-icon-papergoods',
  templateUrl: './icon-papergoods.component.html',
  styleUrls: ['./icon-papergoods.component.scss']
})
export class IconPapergoodsComponent implements OnInit {

  @Input() cssClass = 'icon--18';
  
  constructor() { }

  ngOnInit() {
  }

}
