import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';


@Injectable({
    providedIn: 'root'
})
export class BuyerService {

    constructor(
        private http: HttpClient
    ) { }

    getBuyers() {
        return this.http.get(`${environment.api.content}/Order/vendor/connected_buyers`);
    }
}
