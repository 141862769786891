import { Component, Input, OnInit } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { PlatformType } from 'src/environments/platform-type.enum';

@Component({
  selector: 'app-icon-shipping',
  templateUrl: './icon-shipping.component.html',
  styleUrls: ['./icon-shipping.component.scss']
})
export class IconShippingComponent implements OnInit {

    @Input() cssClass = 'icon--18';

    constructor() { }

    ngOnInit() {
    }
}
