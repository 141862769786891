import { CategoryModel } from '../product/category.model';
import { SiteModel } from '../site.model';

export class SaveCouponModel {
    id?: number;
    code: string;
    name: string;
    value: number;
    valueType: number;
    startDate: Date;
    endDate: Date;
    minOrderAmount: number;
    maxOrderAmount: number;
    usageLimit: number;
    usageLimitPerCustomer: number;
    applyOnlyToNewCustomers: boolean;
    priceLevels: Array<number>;
    categories: Array<number>;
    customers: Array<SiteModel>;
    customerSiteId: Array<number>;
    category: Array<CategoryModel>;
    active: boolean;
    numberOfUsagesPerCoupon: number;
    numberOfUsagesPerUser: number;
    selectedDateArray: Array<Date>;

    constructor(data: SaveCouponModel) {
        if (data) {
            this.code = data.code;
            this.name = data.name;
            this.value = data.value;
            this.valueType = data.valueType;
            this.startDate = data.selectedDateArray[0];
            this.endDate = data.selectedDateArray[1];
            this.minOrderAmount = data.minOrderAmount;
            this.maxOrderAmount = data.maxOrderAmount;
            this.usageLimit = data.usageLimit;
            this.usageLimitPerCustomer = data.usageLimitPerCustomer;
            this.applyOnlyToNewCustomers = data.applyOnlyToNewCustomers;
            this.priceLevels = data.priceLevels;
            this.categories = data.categories;
            this.active = data.active;
        }
    }
}
