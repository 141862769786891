import { Directive, Input, OnInit, ElementRef } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { AppState } from 'src/app/app.reducer';
import * as authSelectors from '../selectors/auth.selector';

@Directive({
    selector: '[appHasPermission]'
})
export class HasPermissionDirective implements OnInit {

    @Input() permissionGroup: string;
    @Input() permissionKey: string;

    constructor(
        private store: Store<AppState>,
        private elementRef: ElementRef
    ) { }

    ngOnInit(): void {
        this.store.select(authSelectors.userHasPermissions, { permissionGroup: this.permissionGroup, permissionKey: this.permissionKey })
            .subscribe((hasPermission: boolean) => {
                if (!hasPermission) {

                    const el: HTMLElement = this.elementRef.nativeElement;

                    if (el.parentNode) {
                        el.parentNode.removeChild(el);
                    }
                }
            });
    }
}
