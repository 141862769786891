import { Component, OnInit, Input } from '@angular/core';
import { environment } from 'src/environments/environment';
import { PlatformType } from 'src/environments/platform-type.enum';

@Component({
    selector: 'app-icon-account',
    templateUrl: './icon-account.component.html',
    styleUrls: ['./icon-account.component.scss']
})
export class IconAccountComponent implements OnInit {

    @Input() cssClass = '';

    currentPlatform = environment.platformId;
    PlatformType = PlatformType;

    constructor() { }

    ngOnInit() {
    }

}
