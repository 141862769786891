import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { LoadStates, LoadCountries } from 'src/app/shared/actions/utils.actions';
import { selectStates, selectCountries } from '../../../shared/selectors/utils.selector';
import { AppState } from 'src/app/app.reducer';
import { Store, select } from '@ngrx/store';
import { combineLatest, Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MustMatch } from 'src/app/shared/validators/mustmatch.validator';
import { CompleteAccountModel } from 'src/app/core/models/auth/complete-account.model';
import { GuestUpdateInfo } from 'src/app/shared/actions/auth.actions';
import { getLoading, currentUserDetails } from '../../../shared/selectors/auth.selector';
import { AppUser } from 'src/app/core/models/user.model';
import { InfobarState } from 'src/app/shared/reducers/infobar.reducer';
import { getPlatformConfiguration } from 'src/app/shared/selectors/branding.selector';
import { BrandingModel } from 'src/app/core/models/branding/branding.model';

@Component({
    selector: 'app-complete-account',
    templateUrl: './complete-account.component.html',
    styleUrls: ['./complete-account.component.scss']
})
export class CompleteAccountComponent implements OnInit, OnDestroy {
    @Input() infobarConfigs: InfobarState;

    form: FormGroup;
    countries$: Observable<any>;
    loading$: Observable<boolean>;
    ngUnsubscribe: Subject<void> = new Subject<void>();
    zipCode: string;
    currentUser: AppUser;
    availableStates: Array<any>;
    taxIdSettings: { showTaxId: boolean, taxIdRegex: string; };
    branding: BrandingModel;

    statesSettings = {
        text: '',
        classes: 'tl-multipleselect',
        enableSearchFilter: true,
        badgeShowLimit: 2,
        singleSelection: true,
        enableFilterSelectAll: false,
        showCheckbox: false
    };

    constructor(
        private formBuilder: FormBuilder,
        private store: Store<AppState>
    ) { }

    ngOnInit() {
        this.loading$ = this.store.select(getLoading);
        this.countries$ = this.store.select(selectCountries);
        this.store.select(selectStates, {
            countryId: 1
        }).pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => {
            if (data) {
                this.availableStates = data.map(state => ({
                    id: state.id,
                    itemName: state.name
                }));
            }
        });

        combineLatest([
            this.store.pipe(select(currentUserDetails)),
            this.store.pipe(select(getPlatformConfiguration))
        ]).subscribe(([currentUser, platform]) => {
            if (currentUser && platform) {
                this.currentUser = currentUser;
                this.branding = platform.branding;
                this.taxIdSettings = {
                    showTaxId: platform.showTaxId,
                    taxIdRegex: platform.taxIdRegex
                };

                this.createForm();
            }
        });

        this.store.dispatch(new LoadCountries());
        this.store.dispatch(new LoadStates({
            countryId: 1
        }));
    }

    ngOnDestroy(): void {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    createForm() {
        this.form = this.formBuilder.group({
            email: ['', Validators.required],
            password: ['', Validators.required],
            confirmPassword: ['', Validators.required],
            businessName: ['', this.branding.isVendorStore ? [Validators.required] : []],
            firstName: ['', Validators.required],
            lastName: ['', Validators.required],
            phoneNumber: ['', Validators.required],
            city: ['', Validators.required],
            address: ['', Validators.required],
            zipCode: [this.currentUser.sites[0].shippingAddress.zipCode, Validators.required],
            countryId: [1, Validators.required],
            state: ['', Validators.required],
            taxId: ['', this.taxIdSettings.showTaxId ? [Validators.required, Validators.pattern(this.taxIdSettings.taxIdRegex)] : []]
        }, {
            validator: MustMatch('password', 'confirmPassword')
        });
    }

    save() {
        if (!this.form.valid) {
            return;
        }

        const completeAccount = new CompleteAccountModel();
        completeAccount.email = this.form.controls.email.value;
        completeAccount.password = this.form.controls.password.value;
        completeAccount.confirmPassword = this.form.controls.confirmPassword.value;
        completeAccount.companyName = this.form.controls.businessName.value;
        completeAccount.firstName = this.form.controls.firstName.value;
        completeAccount.lastName = this.form.controls.lastName.value;
        completeAccount.phoneNumber = this.form.controls.phoneNumber.value;
        completeAccount.city = this.form.controls.city.value;
        completeAccount.countryId = this.form.controls.countryId.value;
        completeAccount.stateId = this.form.controls.state.value[0].id;
        completeAccount.street = this.form.controls.address.value;
        completeAccount.taxIdRegex = this.taxIdSettings.taxIdRegex;
        completeAccount.taxId = this.form.controls.taxId.value ? this.form.controls.taxId.value : null;
        completeAccount.showTaxId = this.taxIdSettings.showTaxId;

        this.store.dispatch(new GuestUpdateInfo(completeAccount));
    }
}
