import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { PriceRequestsSearchRequests } from '../../models/price-request/price-requests-search.model';
import { Observable } from 'rxjs';
import { PriceRequestModel, UpdatePriceRequestModel } from '../../models/price-request/price-request.model';
import { SearchResponse } from '../../models/search-response.model';

@Injectable({
    providedIn: 'root'
})
export class PriceRequestsService {

    constructor(
        private http: HttpClient
    ) { }

    search(params: PriceRequestsSearchRequests): Observable<SearchResponse<PriceRequestModel>> {
        return this.http.post<SearchResponse<PriceRequestModel>>(`${environment.api.content}/PriceRequest/search`, params);
    }

    archive(id: number): Observable<any> {
        return this.http.delete<any>(`${environment.api.content}/PriceRequest/${id}/archive`);
    }

    update(params: UpdatePriceRequestModel) {
        return this.http.put(`${environment.api.content}/PriceRequest/solveRequest`, params);
    }

    vendorPriceRequest(productId: number) {
        return this.http.get(`${environment.api.content}/PriceRequest/sellers/${productId}`);
    }

    apply(productId: number, vendorId: number) {
        return this.http.post(`${environment.api.content}/PriceRequest/apply`, {
            productId,
            vendorIds: [vendorId]
        });
    }
}
