import { Action } from '@ngrx/store';
import { UserPermissionGroup } from 'src/app/core/models/user-permission-group.model';
import { ChangePasswordModel } from 'src/app/core/models/change-password.model';
import { CompleteAccountModel } from 'src/app/core/models/auth/complete-account.model';

export enum AuthActionTypes {
    LoginAction = '[Login] Action',
    LogoutAction = '[Logout] Action',
    LoadUserDetailsAction = '[Global] Load User Details Action',
    UpdateSelectedSiteAction = '[Global] Update selected site Action',
    LoadHomeUrl = '[Global] LoadHomeUrl',
    HomeUrlLoaded = '[Global] HomeUrlLoaded',
    SendVerificationEmail = '[Global] SendVerificationEmail',
    LoadUserPermissions = '[Global] Load User Permissions',
    LoadUserPermissionsSuccess = '[Global] Load User Permission Success',
    ChangePassword = '[Global] Change Password',
    ChangePasswordSuccess = '[Global] Change Password Success',
    ChangePasswordError = '[Global] Change Password Error',
    GuestRegister = '[Register Guest] Register Guest',
    GuestRegisterSuccess = '[Register Guest] Register Guest Success',
    GuestUpdateInfo = '[Register Guest] Update Info',
    GuestUpdateInfoSuccess = '[Register Guest] Update Info Success',
    GuestUpdateInfoFail = '[Register Guest] Update Info Failed',
    ReloadUserDetails = '[Register Update Info] Reload User Details',
    ToggleShowLocationSelection = '[Global] ToggleShowLocationSelection'
}

export class Login implements Action {
    readonly type = AuthActionTypes.LoginAction;

    constructor(public payload: { loginResponse: any, userPermissionGroups?: any, zipCode?: string }) {
    }
}

export class Logout implements Action {
    readonly type = AuthActionTypes.LogoutAction;

    constructor(public payload?: { forceLogout?: boolean, message?: string, changeZip?: boolean, returnUrl?: string }) {
    }
}

export class LoadUserDetails implements Action {
    readonly type = AuthActionTypes.LoadUserDetailsAction;

    constructor(public payload: { userDetails: any }) {
    }
}

export class UpdateSelectedSite implements Action {
    readonly type = AuthActionTypes.UpdateSelectedSiteAction;

    constructor(public payload: { siteId: number, companyTypeId?: number }) {
    }
}

export class LoadHomeUrl implements Action {
    readonly type = AuthActionTypes.LoadHomeUrl;
}

export class HomeUrlLoaded implements Action {
    readonly type = AuthActionTypes.HomeUrlLoaded;

    constructor(public payload: { url: string }) {
    }
}

export class SendVerificationEmail implements Action {
    readonly type = AuthActionTypes.SendVerificationEmail;

    constructor(public payload: { userId?: number, showNotification?: boolean } = {}) {
    }
}

export class LoadUserPermissions implements Action {
    readonly type = AuthActionTypes.LoadUserPermissions;

    constructor(public payload: number) {
    }
}

export class LoadUserPermissionsSuccess implements Action {
    readonly type = AuthActionTypes.LoadUserPermissionsSuccess;

    constructor(public payload: Array<UserPermissionGroup>) {
    }
}

export class ChangePassword implements Action {
    readonly type = AuthActionTypes.ChangePassword;

    constructor(public payload: ChangePasswordModel) {
    }
}

export class ChangePasswordSuccess implements Action {
    readonly type = AuthActionTypes.ChangePasswordSuccess;

    constructor(public payload: string) {
    }
}

export class ChangePasswordError implements Action {
    readonly type = AuthActionTypes.ChangePasswordError;

    constructor() {
    }
}

export class GuestRegister implements Action {
    readonly type = AuthActionTypes.GuestRegister;

    constructor(public payload: { zipCode: string, goPage?: string }) {
    }
}

export class GuestRegisterSuccess implements Action {
    readonly type = AuthActionTypes.GuestRegisterSuccess;
}

export class GuestUpdateInfo implements Action {
    readonly type = AuthActionTypes.GuestUpdateInfo;

    constructor(public payload: CompleteAccountModel) {
    }
}

export class GuestUpdateInfoSuccess implements Action {
    readonly type = AuthActionTypes.GuestUpdateInfoSuccess;

    constructor(public payload: any) {
    }
}

export class GuestUpdateInfoFail implements Action {
    readonly type = AuthActionTypes.GuestUpdateInfoFail;
}

export class ReloadUserDetails implements Action {
    readonly type = AuthActionTypes.ReloadUserDetails;
}

export class ToggleShowLocationSelection implements Action {
    readonly type = AuthActionTypes.ToggleShowLocationSelection;

    constructor(public payload: { show: boolean }) {
    }
}

export type AuthActions = Login | Logout | LoadUserDetails | UpdateSelectedSite
    | LoadHomeUrl
    | HomeUrlLoaded
    | SendVerificationEmail
    | LoadUserPermissions
    | LoadUserPermissionsSuccess
    | ChangePassword
    | ChangePasswordSuccess
    | ChangePasswordError
    | GuestRegister
    | GuestRegisterSuccess
    | GuestUpdateInfo
    | GuestUpdateInfoSuccess
    | ReloadUserDetails
    | GuestUpdateInfoFail
    | ToggleShowLocationSelection;
