import { Component, OnInit, Input } from '@angular/core';
import { environment } from 'src/environments/environment';
import { PlatformType } from 'src/environments/platform-type.enum';

@Component({
    selector: 'app-icon-home',
    templateUrl: './icon-home.component.html',
    styleUrls: ['./icon-home.component.scss']
})
export class IconHomeComponent implements OnInit {

    @Input() cssClass = '';

    currentPlatform = environment.platformId;
    PlatformType = PlatformType;

    constructor() { }

    ngOnInit() {
    }

}
