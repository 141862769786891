import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { ViewModes } from 'src/app/core/enums/view-mode.enum';
import { AppState } from 'src/app/app.reducer';
import { Store } from '@ngrx/store';
import { forkJoin, Observable } from 'rxjs';
import { selectShowPricedItemsFilter } from '../../selectors/order-guide.selector';
import { TemplateEnum } from 'src/app/core/enums/template.enum';
import { getThemeId } from '../../selectors/branding.selector';
import { SettingsService } from '../../../core/services/settings/settings.service';
import { SearchOptionType } from '../../../core/enums/search-option-type.enum';

@Component({
    selector: 'app-show-items',
    templateUrl: './show-items.component.html',
    styleUrls: ['./show-items.component.scss']
})
export class ShowItemsComponent implements OnInit {

    @Output() change = new EventEmitter<any>();

    showFavorites = false;
    showWithPrice = false;
    template$: Observable<TemplateEnum>;
    templateEnum = TemplateEnum;
    showWithPriceItemsFilter$: Observable<boolean>;
    viewModes = [
        { id: ViewModes.WithPrice, name: 'With Price', isSelected: false },
        { id: ViewModes.Favorites, name: 'Favorites', isSelected: false },
    ];
    ViewModes = ViewModes;

    constructor(
        private store: Store<AppState>,
        private settingsService: SettingsService
    ) {
    }

    ngOnInit(): void {
        this.template$ = this.store.select(getThemeId);
        this.showWithPriceItemsFilter$ = this.store.select(selectShowPricedItemsFilter);

        this.settingsService.getSearchOptions().subscribe(result => {
            result = result.filter(o => o.optionType === SearchOptionType.VIEW_MODE);

            result.forEach(option => {
                const viewMode = this.viewModes.find(o => o.id === option.optionKey);
                viewMode.isSelected = option.optionSelected;
            });
        });
    }

    onSelect($event: MatCheckboxChange, viewMode: any): void {
        viewMode.isSelected = $event.checked;

        this.settingsService.saveSearchOptions({
            optionType: SearchOptionType.VIEW_MODE,
            optionKey: viewMode.id,
            optionSelected: viewMode.isSelected
        }).subscribe(() => {
            this.change.emit(this.viewModes);
        });
    }

    onSelectAll(): void {
        this.viewModes.forEach(v => {
            v.isSelected = true;
        });

        const requests: Observable<boolean>[] = [];

        this.viewModes.forEach(o => {
            requests.push(this.settingsService.saveSearchOptions({
                optionType: SearchOptionType.VIEW_MODE,
                optionKey: o.id,
                optionSelected: o.isSelected
            }));
        });

        forkJoin(requests).subscribe(() => {
            this.change.emit(this.viewModes);
        });
    }

    onSelectViewMode($event: any): void {
        this.viewModes.find(v => v.id === $event.viewModeId).isSelected = $event.isSelected;

        this.settingsService.saveSearchOptions({
            optionType: SearchOptionType.VIEW_MODE,
            optionKey: $event.viewModeId,
            optionSelected: $event.isSelected
        }).subscribe(() => {
            this.change.emit(this.viewModes);
        });
    }

    modifyAll(value: boolean): void {
        const requests: Observable<boolean>[] = [];

        this.viewModes.forEach(v => {
            v.isSelected = value;
            requests.push(this.settingsService.saveSearchOptions({
                optionType: SearchOptionType.VIEW_MODE,
                optionKey: v.id,
                optionSelected: value
            }))
        })

        forkJoin(requests).subscribe();
    }
}
