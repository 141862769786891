import { Component, OnInit, OnDestroy, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/app.reducer';
import { LoadBuyers } from '../../actions/order-guide.action';
import { selectBuyers } from '../../selectors/order-guide.selector';
import { takeUntil, map, tap } from 'rxjs/operators';
import { BuyerModel } from 'src/app/core/models/order/buyer.model';
import { TemplateEnum } from 'src/app/core/enums/template.enum';
import { getThemeId } from '../../selectors/branding.selector';

@Component({
    selector: 'app-customers-filter',
    templateUrl: './customers-filter.component.html',
    styleUrls: ['./customers-filter.component.scss']
})
export class CustomersFilterComponent implements OnInit, OnDestroy, OnChanges {
    DEFAULT_BUYERS_COUNT = 6;
    buyers$: Observable<Array<BuyerModel>>;
    showItemsCount = this.DEFAULT_BUYERS_COUNT;
    showMore = false;
    currentPlatform = environment.platformId;
    searchCustomers: string;
    template$: Observable<TemplateEnum>;
    templateEnum = TemplateEnum;

    private buyers = new Array<BuyerModel>();

    @Input() selectedBuyers: number[] = [];
    @Output() change: EventEmitter<Array<number>> = new EventEmitter<Array<number>>();

    private ngUnsubscribe: Subject<void> = new Subject<void>();

    constructor(
        private store: Store<AppState>,
    ) { }

    ngOnInit() {
        this.template$ = this.store.select(getThemeId);
        this.store.dispatch(new LoadBuyers());
    }

    ngOnChanges() {
        this.buyers$ = this.store.select(selectBuyers).pipe(
            takeUntil(this.ngUnsubscribe),
            map((buyers: Array<BuyerModel>) => buyers.map(buyer => ({
                ...buyer,
                isSelected: this.selectedBuyers.includes(buyer.id)
            }))),
            tap(buyers => this.buyers = buyers)
        );
    }

    ngOnDestroy() {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    onSelect($event: MatCheckboxChange, buyer: BuyerModel) {
        if ($event.checked) {
            this.selectedBuyers.push(buyer.id);
        } else {
            const idx = this.selectedBuyers.findIndex(v => v === buyer.id);
            this.selectedBuyers.splice(idx, 1);
        }

        this.emitSelect(this.selectedBuyers);
    }

    emitSelect(selectedBuyers: Array<number>) {
        this.change.emit(selectedBuyers);
    }

    onSelectAll() {
        this.change.emit([]);
    }

    toggleMore() {
        this.showMore = !this.showMore;
        this.showItemsCount = this.showMore ? this.buyers.length : this.DEFAULT_BUYERS_COUNT;
    }
}
