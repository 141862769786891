import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { catchError, exhaustMap, map, mergeMap, take, tap, withLatestFrom } from 'rxjs/operators';
import {
    CartActionTypes,
    CartLoaded,
    CartSummaryLoaded,
    DeleteCartByVendorId,
    DeleteCartByVendorIdSuccess,
    LoadAuctionShoppingCartSuccess,
    LoadAvailableShippingMethods,
    LoadAvailableShippingMethodsSuccess,
    LoadCart,
    LoadCartSummary,
    LoadCartTotals,
    LoadCartTotalsSuccess,
    LoadDeliveryMethods,
    LoadDeliveryMethodsSuccess,
    MoveProduct,
    SaveDelivery,
    SaveDeliveryError,
    SaveDeliverySuccess,
    SaveNotes,
    SaveNotesSuccess,
    SaveTips,
    SaveTipsSuccess,
    SubmitOrder,
    SubmitOrderError,
    SubmitOrderSuccess
} from '../actions/cart.actions';
import { CartService } from 'src/app/core/services/cart/cart.service';
import { LoadNewOrdersCount } from '../actions/order.actions';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/app.reducer';
import { OrderService } from 'src/app/core/services/order/order.service';
import { DatePipe } from '@angular/common';
import { forkJoin, of } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { NotifierService } from 'angular-notifier';
import { MatDialog } from '@angular/material/dialog';
import { getAccountTypeId } from '../selectors/auth.selector';
import { selectCart } from '../selectors/cart.selector';
import { SubmitOrderSuccessModel } from 'src/app/core/models/order-guide/submit-order-success.model';
import { LoadWallet } from '../actions/rewards.actions';
import { AuctionService } from 'src/app/core/services/auction/auction.service';
import { GeneralCartModel } from 'src/app/core/models/cart/general-cart.model';
import { ShippingService } from '../../core/services/shipping/shipping.service';
import { OrderGuideService } from 'src/app/core/services/order-guide/order-guide.service';
import { LoadCartSummaryOrderGuide } from '../actions/order-guide.action';

// tslint:disable-next-line:ban-types
declare let fbq: Function;
// tslint:disable-next-line:ban-types
declare let gtag: Function;
// tslint:disable-next-line:ban-types
declare let ga: Function;

@Injectable()
export class CartEffects {

    @Effect({ dispatch: false })
    loadCartSummary$ = this.actions$.pipe(
        ofType<LoadCartSummary>(CartActionTypes.LoadCartSummaryAction),
        exhaustMap(() => this.cartSvc.loadCartSummary().pipe(
            map(summary => {
                this.store.dispatch(new CartSummaryLoaded({ summary }));
                this.store.dispatch(new LoadNewOrdersCount());
            })
        ))
    );

    @Effect()
    loadCart$ = this.actions$.pipe(
        ofType<LoadCart>(CartActionTypes.LoadCart),
        exhaustMap((action: LoadCart) => {
            let cartLoad = undefined;
            if (action.payload) {
                cartLoad = this.orderGuideSvc.loadShoppingCart(action.payload);
            } else {
                cartLoad = this.cartSvc.loadCart();
            }
            const cartAuctionLoad = this.auctionService.loadShoppingCart();

            forkJoin([cartLoad, cartAuctionLoad]).subscribe(
                (result: any) => {
                    result[1] = result[1]?.map(cd => ({
                        ...cd,
                        isAuction: true,
                        validationMessages: []
                    }));
                    let cartDetails = result[0];
                    if (result[1]) {
                        if (cartDetails) {
                            cartDetails.concat(result[1]);
                        } else {
                            cartDetails = result[1];
                        }
                    }
                    this.store.dispatch(new CartLoaded(
                        { cartDetails: cartDetails }
                    ));
                });

            return of();
        })
    );

    @Effect()
    deleteCartByVendorId$ = this.actions$.pipe(
        ofType<DeleteCartByVendorId>(CartActionTypes.DeleteCartByVendorId),
        mergeMap((action) => this.cartSvc.deleteCartByVendorId(
            action.payload.vendorId,
            action.payload.shoppingCartItemId,
            action.payload.customerSiteId
        ).pipe(map(() =>
            new DeleteCartByVendorIdSuccess({
                vendorId: action.payload.vendorId,
                shoppingCartItemId: action.payload.shoppingCartItemId,
                customerSiteId: action.payload.customerSiteId,
                customerAccountId: action.payload.customerAccountId
            }))
        )),
    );


    @Effect({ dispatch: false })
    deleteCartByVendorIdSuccess$ = this.actions$.pipe(
        ofType<DeleteCartByVendorIdSuccess>(CartActionTypes.DeleteCartByVendorIdSuccess),
        map((action) => {
            if (action.payload.customerSiteId) {
                this.store.dispatch(new LoadCartSummaryOrderGuide({ customerAccountId: action.payload.customerAccountId, customerSiteId: action.payload.customerSiteId }))
            } else {
                this.store.dispatch(new LoadCartSummary());
            }
        })
    );

    @Effect()
    saveNotes$ = this.actions$.pipe(
        ofType<SaveNotes>(CartActionTypes.SaveNotes),
        exhaustMap((action) => this.cartSvc.saveNotes(action.payload.notes, action.payload.vendorId, action.payload.shoppingCartItemId, action.payload.customerSiteId).pipe(
            map(() => action.payload)
        )),
        map(payload => new SaveNotesSuccess(payload))
    );

    @Effect()
    saveDelivery$ = this.actions$.pipe(
        ofType<SaveDelivery>(CartActionTypes.SaveDelivery),
        exhaustMap((action) => this.cartSvc.saveDelivery(
            action.payload.deliveryDate,
            action.payload.deliveryFromTime,
            action.payload.deliveryToTime,
            action.payload.vendorId,
            action.payload.shippingMethodId,
            action.payload.shippingCharge,
            action.payload.shippingReferenceId,
            action.payload.customerSiteId).pipe(
                map(() => action.payload),
                withLatestFrom(this.store.select(getAccountTypeId)),
                tap(([payload, accountTypeId]) => {
                }),
                map(([payload, accountTypeId]) => new SaveDeliverySuccess({
                    vendorId: payload.vendorId,
                    deliveryDate: this.datePipe.transform(payload.deliveryDate, 'MM/dd/yyyy'),
                    deliveryFromTime: this.datePipe.transform(payload.deliveryFromTime, 'HH:mm'),
                    deliveryToTime: this.datePipe.transform(payload.deliveryToTime, 'HH:mm'),
                    slideToCheckout: payload.slideToCheckout,
                    slideToShipping: payload.slideToShipping,
                    shippingMethodId: payload.shippingMethodId,
                    shippingCharge: payload.shippingCharge,
                    customerAccountId: payload.customerAccountId,
                    customerSiteId: payload.customerSiteId
                })
                ),
                catchError((e: HttpErrorResponse) => {
                    return of(new SaveDeliveryError({
                        vendorId: action.payload.vendorId,
                        errorMessage: e.error.message,
                        deliveryDate: this.datePipe.transform(action.payload.deliveryDate, 'MM/dd/yyyy'),
                        deliveryFromTime: this.datePipe.transform(action.payload.deliveryFromTime, 'HH:mm'),
                        deliveryToTime: this.datePipe.transform(action.payload.deliveryToTime, 'HH:mm'),
                    }));
                })
            ))
    );

    @Effect()
    saveDeliverySuccess$ = this.actions$.pipe(
        ofType<SaveDeliverySuccess>(CartActionTypes.SaveDeliverySuccess),
        map((action) => new LoadCartTotals({ vendorId: action.payload.vendorId, customerAccountId: action.payload.customerAccountId, customerSiteId: action.payload.customerSiteId }))
    );

    @Effect({ dispatch: false })
    moveProduct$ = this.actions$.pipe(
        ofType<MoveProduct>(CartActionTypes.MoveProduct),
        exhaustMap((action) => this.cartSvc.moveProduct(action.payload)),
        map(() => {
            this.store.dispatch(new LoadCart());
        })
    );

    @Effect()
    submit$ = this.actions$.pipe(
        ofType<SubmitOrder>(CartActionTypes.SubmitOrder),
        exhaustMap((action: SubmitOrder) => this.orderSvc.save(action.payload)
            .pipe(
                map((response: SubmitOrderSuccessModel) => {
                    this.store.dispatch(new LoadWallet());
                    response.shoppingCartItemId = action.payload.shoppingCartItemId;

                    return new SubmitOrderSuccess({ response });
                }),
                catchError((e: HttpErrorResponse) => {
                    if (action.payload.popNotifications) {
                        this.notifySvc.show({
                            type: 'error',
                            message: e.error.message
                        });
                    }

                    return of(new SubmitOrderError({
                        vendorId: action.payload.vendorId,
                        message: e.error.message
                    }));
                })
            )
        )
    );

    @Effect()
    submitOrderSuccess$ = this.actions$.pipe(
        ofType<SubmitOrderSuccess>(CartActionTypes.SubmitOrderSuccess),
        tap((action) => {
            this.store.select(selectCart)
                .pipe(
                    take(1),
                ).subscribe(cart => {
                    const currentCartOrder = cart.find(c => c.id === action.payload.response.vendorId);
                    if (currentCartOrder) {
                        this.sendAnalytics(currentCartOrder);
                    }
                });
        }),
        map(() => new LoadCartSummary())
    );

    @Effect()
    loadCartTotals$ = this.actions$.pipe(
        ofType<LoadCartTotals>(CartActionTypes.LoadCartTotals),
        exhaustMap((action) => this.cartSvc.loadCartTotals(action.payload)),
        map((response) => new LoadCartTotalsSuccess({
            cartVendorTotals: response
        }))
    );


    @Effect()
    saveTips$ = this.actions$.pipe(
        ofType<SaveTips>(CartActionTypes.SaveTips),
        exhaustMap((action) => this.cartSvc.saveTips(action.payload.vendorId, action.payload.amount).pipe(
            map(() => {
                this.store.dispatch(new LoadCartTotals({ vendorId: action.payload.vendorId }));
                return new SaveTipsSuccess();
            })
        ))
    );


    @Effect()
    loadAuctionShoppingCart$ = this.actions$.pipe(
        ofType(CartActionTypes.LoadAuctionShoppingCart),
        mergeMap(() => this.auctionService.loadShoppingCart().pipe(
            map((data: Array<GeneralCartModel>) => new LoadAuctionShoppingCartSuccess(data))
        ))
    );

    @Effect()
    loadAvailableShippingMethods$ = this.actions$.pipe(
        ofType<LoadAvailableShippingMethods>(CartActionTypes.LoadAvailableShippingMethods),
        exhaustMap((action) => this.shippingService.loadAvailableShippingMethods(action.payload.vendorId).pipe(
            map(data => new LoadAvailableShippingMethodsSuccess({
                vendorId: action.payload.vendorId,
                availableShippingMethods: data
            }))
        ))
    );

    @Effect()
    loadDeliveryMethods$ = this.actions$.pipe(
        ofType<LoadDeliveryMethods>(CartActionTypes.LoadDeliveryMethods),
        exhaustMap((action) => this.shippingService
            .loadDeliveryMethods(action.payload.carrierId, action.payload.request).pipe(
                map(data => new LoadDeliveryMethodsSuccess({
                    vendorId: action.payload.request.vendorId,
                    carrierId: action.payload.carrierId,
                    deliveryMethods: data
                }))
            ))
    );

    constructor(
        private actions$: Actions,
        private cartSvc: CartService,
        private orderSvc: OrderService,
        private store: Store<AppState>,
        private datePipe: DatePipe,
        private notifySvc: NotifierService,
        private matDialog: MatDialog,
        private auctionService: AuctionService,
        private shippingService: ShippingService,
        private orderGuideSvc: OrderGuideService
    ) { }

    private sendAnalytics(order) {
        try {
            const dataLayer: any[] = (window as any).dataLayer || [];
            const products = order.items.map(i => ({
                name: i.name,
                id: i.sku,
                price: i.price,
                brand: order.submittedDetails.vendorName,
                category: i.categoryName || '',
                quantity: i.quantity,
            }));

            const fbProducts = order.items.map(i => ({
                id: i.sku,
                quantity: i.quantity,
            }));

            dataLayer.push({
                event: 'transaction',
                ecommerce: {
                    currencyCode: 'USD',
                    purchase: {
                        actionField: {
                            id: order.submittedDetails.orderNumber,
                            affiliation: order.submittedDetails.vendorName,
                            revenue: order.submittedDetails.total,
                            tax: '0',
                            shipping: order.deliveryFee,
                            coupon: order.coupon ? order.coupon.code : ''
                        },
                        products,
                        fbProducts
                    }
                }
            });

            console.log('Send ga ecommerce conversion');
        } catch (ex) {
            // console.log(ex);
        }
    }
}
