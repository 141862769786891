import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { AppState } from 'src/app/app.reducer';
import { TemplateEnum } from 'src/app/core/enums/template.enum';
import { SubmitOrderSuccessModel } from 'src/app/core/models/order-guide/submit-order-success.model';
import { SlideToCheckout } from 'src/app/shared/actions/cart.actions';
import { PrintOrder } from 'src/app/shared/actions/order.actions';
import { IconCartComponent } from 'src/app/shared/icons/icon-cart/icon-cart.component';
import { getThemeId } from '../../selectors/branding.selector';

@Component({
    selector: 'app-order-success',
    templateUrl: './order-success.component.html',
    styleUrls: ['./order-success.component.scss'],
    entryComponents: [IconCartComponent]
})
export class OrderSuccessComponent implements OnInit {

    template$: Observable<TemplateEnum>;
    templateEnum = TemplateEnum;

    constructor(
        private dialogRef: MatDialogRef<OrderSuccessComponent>,
        @Inject(MAT_DIALOG_DATA) public data: { order: SubmitOrderSuccessModel },
        private store: Store<AppState>,
        private router: Router
    ) { }

    ngOnInit(): void {
        this.template$ = this.store.select(getThemeId);
    }

    close() {
        this.dialogRef.close();
    }

    redirectToCart(): void {
        this.store.dispatch(new SlideToCheckout({ show: true, id: this.data.order.vendorId }));
        this.close();
    }

    redirectToOrderDetails(): void {
        this.router.navigate(['orders', this.data.order.id]);
        this.close();
    }

    print(): void {
        this.store.dispatch(new PrintOrder({
            orderId: this.data.order.id
        }));
        this.close();
    }

    get title(): string {
        if (this.data) {
            return this.data.order.earnedTokens && this.data.order.earnedTokens.length > 0 ? 'Congratulations!' : 'Success!';
        }
    }
}
