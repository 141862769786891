import { EntityChanel } from '../../core/models/chat/entity-chanel.model';
import { ChatActions, ChatActionTypes, LoadConversationSuccess } from '../actions/chat.actions';
import { ChatConversation } from '../../core/models/chat/chat-conversation.model';
import { BuyerVendorModel } from '../../core/models/chat/buyer-vendor.model';
import { ChatDisplayStatus } from '../../core/models/chat/chat-display.status';

export interface ChatState {
    loading: boolean;
    entityChannels: Array<EntityChanel>;
    conversations: Array<ChatConversation>;
    buyersVendors: Array<BuyerVendorModel>;
    chatDisplayStatus: ChatDisplayStatus;
}

const initialState: ChatState = {
    loading: false,
    entityChannels: [],
    conversations: [],
    buyersVendors: [],
    chatDisplayStatus: new ChatDisplayStatus()
};

export function chatReducer(state = initialState, action: ChatActions): ChatState {
    switch (action.type) {
        case ChatActionTypes.LoadAllChannels:
            return {
                ...state,
                loading: true
            };

        case ChatActionTypes.LoadAllChannelsSuccess:
            const badgesArray = ['red', 'light-blue', 'blue', 'green'];

            // tslint:disable-next-line:no-shadowed-variable
            action.payload.forEach((channel, index) => {
                const badgeColor = badgesArray[Math.floor(Math.random() * badgesArray.length)];

                const stateChannel = state.entityChannels.find(statechannel => statechannel.id === channel.id);

                if (index >= 1) {
                    if (stateChannel && stateChannel.badgeColor) {
                        channel.badgeColor = stateChannel.badgeColor;
                        return;
                    }
                    if (action.payload[index - 1].badgeColor === badgeColor) {
                        const badgeIndex = badgesArray.indexOf(badgeColor);
                        const newBadgeIndex = badgeIndex + 1 > badgesArray.length - 1 ? 0 : badgeIndex + 1;
                        channel.badgeColor = badgesArray[newBadgeIndex];
                    } else {
                        channel.badgeColor = badgeColor;
                    }
                    return;
                } else {
                    channel.badgeColor = stateChannel && stateChannel.badgeColor ? stateChannel.badgeColor : badgeColor;
                }
            });

            return {
                ...state,
                loading: false,
                entityChannels: action.payload
            };

        case ChatActionTypes.LoadConversation:
            return {
                ...state,
                loading: true
            };

        case ChatActionTypes.LoadConversationSuccess:
            return {
                ...state,
                loading: false,
                entityChannels: [...state.entityChannels, action.payload.channel],
                conversations: [...state.conversations, action.payload]
            };

        case ChatActionTypes.LoadBuyerVendor:
            return {
                ...state,
                loading: true
            };

        case ChatActionTypes.LoadBuyerVendorSuccess:
            return {
                ...state,
                loading: false,
                buyersVendors: action.payload
            };
        case ChatActionTypes.GenerateChannelUUID:
            return {
                ...state,
                loading: true
            };

        case ChatActionTypes.GenerateChannelUUIDSuccess:
            const chatConversation = new ChatConversation();
            const entityChannel = new EntityChanel();
            entityChannel.uuid = action.payload;
            entityChannel.id = 0;
            chatConversation.channel = entityChannel;
            let index = state.entityChannels.findIndex(channel => channel.id === entityChannel.id);
            if (index > -1) {
                state.entityChannels.splice(index, 1);
            }
            state.entityChannels.push(entityChannel);

            index = state.conversations.findIndex(conversation => conversation.channel.id === chatConversation.channel.id);
            if (index > -1) {
                state.conversations.splice(index, 1);
            }
            state.conversations.push(chatConversation);

            return {
                ...state,
                loading: false,
                entityChannels: [...state.entityChannels],
                conversations: [...state.conversations]
            };
        case ChatActionTypes.ChatDisplayStatus:
            state.chatDisplayStatus = action.payload;
            return {
                ...state,
                chatDisplayStatus: { ...state.chatDisplayStatus }
            };

        default:
            return state;
    }
}



