import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { AccountTypes } from 'src/app/core/enums/account-type.enum';
import { GeneralCartModel } from 'src/app/core/models/cart/general-cart.model';
import * as moment from 'moment';
import { PAYMENT_METHOD } from 'src/app/core/enums/payment-method.enum';
import { PaymentMethodModel } from 'src/app/core/models/cart/payment-method.model';
import { PaymentProfileModel } from 'src/app/core/models/cart/payment-profile.model';
import { WalletModel } from 'src/app/core/models/rewards/wallet.model';
import { Platform } from '../../../../core/models/branding/platform.model';

@Component({
    selector: 'app-payment-checkout-b2b2c',
    templateUrl: './payment-checkout.b2b2c.component.html',
    styleUrls: ['./payment-checkout.b2b2c.component.scss']
})
export class PaymentCheckoutB2b2cComponent implements OnInit {

    @Input() vendorId: number;
    @Input() cartVendor: GeneralCartModel;
    @Input() accountType: AccountTypes;
    @Input() selectedPaymentMethod: PaymentMethodModel;
    @Input() paymentProfiles: Array<PaymentProfileModel>;
    @Input() tipsOptions: Array<{ amount: number; }>;
    @Input() selectedWallets: Array<WalletModel> = new Array<WalletModel>();
    @Input() wallets: Array<WalletModel>;
    @Input() remainingToPay: number;
    @Input() platform: Platform;

    @Output() backEmitter: EventEmitter<any> = new EventEmitter<any>();
    @Output() openCardModal: EventEmitter<any> = new EventEmitter<any>();
    @Output() deleteProfile: EventEmitter<PaymentProfileModel> = new EventEmitter<PaymentProfileModel>();
    @Output() saveTip: EventEmitter<number> = new EventEmitter<number>();
    @Output() submitOrder: EventEmitter<any> = new EventEmitter<any>();
    @Output() selectProfile: EventEmitter<PaymentProfileModel> = new EventEmitter<PaymentProfileModel>();
    @Output() selectPaymentMethod: EventEmitter<PaymentMethodModel> = new EventEmitter<PaymentMethodModel>();

    AccountTypes = AccountTypes;
    PAYMENT_METHOD = PAYMENT_METHOD;
    selectedProfile: PaymentProfileModel;
    expandRemainingWallets: boolean;
    orderTotalInToken: number;

    constructor() { }

    ngOnInit(): void { }

    getTime(date: Date): string {
        if (!date) {
            return;
        }
        return moment(date).format('LT');
    }

    getSubmitBtnMessage(vendor) {
        if (vendor.acceptedPaymentMethods.find(p => p.id === PAYMENT_METHOD.BEHALF || p.id === PAYMENT_METHOD.CREDIT_CARD)) {
            return 'Submit & Pay';
        }

        return 'Submit';
    }

    goBack(): void {
        this.backEmitter.emit();
    }

    isSelectedPaymentMethod(paymentMethod: PaymentMethodModel) {
        if (!this.selectedPaymentMethod) {
            return false;
        }

        if (paymentMethod.id === PAYMENT_METHOD.TOKEN) {
            return paymentMethod.tokenId === this.selectedPaymentMethod.tokenId;
        }

        return paymentMethod.id === this.selectedPaymentMethod.id;
    }

    openCreditCardModal(): void {
        this.openCardModal.emit();
    }

    isSelectedProfile(profile: PaymentProfileModel) {
        if (!this.selectedProfile) {
            return false;
        }

        return profile.id === this.selectedProfile.id;
    }

    onDeleteProfile($event: any, profile: PaymentProfileModel): void {
        $event.preventDefault();
        this.deleteProfile.emit(profile);
    }

    saveTips(amount?: number) {
        this.saveTip.emit(amount);
    }

    onSubmit(): void {
        this.submitOrder.emit();
    }

    onSelectProfile(profile: PaymentProfileModel): void {
        this.selectedProfile = profile;
        this.selectProfile.emit(profile);
    }

    onSelectPaymentMethod(paymentMethod: PaymentMethodModel): void {
        this.selectedPaymentMethod = paymentMethod;
        this.orderTotalInToken = paymentMethod.orderTotalInToken;
        this.selectPaymentMethod.emit(paymentMethod);
    }

    getFormattedDate(date: string): string {
        return moment(date).format('l');
    }

    getSelectedAmount(): number {
        let value = 0;
        this.selectedWallets.forEach(wallet => {
            if (wallet.paidAmount) {
                value += Number(wallet.paidAmount);
            }
        });

        return value;
    }

    getSelectedWalletsFullAmount(): number {
        let value = 0;

        this.selectedWallets.forEach(wallet => {
            value += Number(wallet.amount);
        });

        return value;
    }

    addWallet(wallet: WalletModel) {
        this.selectedWallets.push(wallet);
        this.expandRemainingWallets = false;
    }

    getRemainingNotSelectedWallets(): Array<WalletModel> {
        return this.wallets.filter(wallet => {
            return !this.selectedWallets.some(displayedWallet => {
                return wallet.id === displayedWallet.id;
            });
        });
    }
}
