import { EditRuleResponse } from 'src/app/core/models/rewards/edit-rule.model';
import { PaymentWalletModel } from 'src/app/core/models/rewards/payment-wallet.model';
import { RuleModel } from 'src/app/core/models/rewards/rule.model';
import { TokenModel } from 'src/app/core/models/rewards/token.model';
import { WalletModel } from 'src/app/core/models/rewards/wallet.model';
import { RewardsActionTypes, RewardsActions } from '../actions/rewards.actions';

export interface RewardsState {
    wallet: Array<WalletModel>;
    paymentWallet: Array<PaymentWalletModel>;
    loading: boolean;
    rules: Array<RuleModel>;
    tokens: Array<TokenModel>;
    editRule: EditRuleResponse;
}

export const initialState: RewardsState = {
    wallet: undefined,
    paymentWallet: undefined,
    loading: false,
    rules: [],
    tokens: undefined,
    editRule: undefined
};

export function rewardsReducer(
    state = initialState,
    action: RewardsActions): RewardsState {

    let ruleIndex: number;
    let tokenIndex: number;
    let storeWallet: Array<WalletModel>;

    switch (action.type) {
        case RewardsActionTypes.LoadWallet:
            return {
                ...state,
                loading: true
            };
        case RewardsActionTypes.LoadWalletSuccess:
            storeWallet = state.wallet;

            if (action.payload.length && storeWallet) {
                const walletIndex = state.wallet.findIndex(wallet => wallet.id === action.payload[0].id);
                if (walletIndex !== -1) {
                    storeWallet[walletIndex].activities = storeWallet[walletIndex].activities;
                    storeWallet[walletIndex].balances = action.payload[0].balances;
                }
            } else {
                storeWallet = action.payload;
            }

            return {
                ...state,
                loading: false,
                wallet: [...storeWallet]
            };
        case RewardsActionTypes.LoadWalletActivity:
            return {
                ...state,
                loading: true
            };
        case RewardsActionTypes.LoadWalletActivitySuccess:
            storeWallet = state.wallet;

            if (action.payload.items.length) {
                const walletIndex = state.wallet.findIndex(wallet => wallet.id === action.payload.items[0].walletId);
                if (walletIndex !== -1) {
                    storeWallet[walletIndex].activities =
                        !storeWallet[walletIndex].activities || storeWallet[walletIndex].activities.page >= action.payload.page
                            ? action.payload : {
                                ...storeWallet[walletIndex].activities,
                                page: action.payload.page,
                                items: storeWallet[walletIndex].activities.items.concat(action.payload.items)
                            };
                }
            }

            return {
                ...state,
                wallet: [
                    ...storeWallet
                ],
                loading: false
            };
        case RewardsActionTypes.LoadRules:
            return {
                ...state,
                loading: true
            };
        case RewardsActionTypes.LoadRulesSuccess:
            return {
                ...state,
                rules: action.payload,
                loading: true
            };
        case RewardsActionTypes.LoadTokensSuccess:
            return {
                ...state,
                tokens: action.payload
            };
        case RewardsActionTypes.SaveRuleSuccess:
            if (action.payload.isEditMode) {
                ruleIndex = state.rules.findIndex(rule => rule.id === action.payload.rule.id);
                state.rules[ruleIndex] = action.payload.rule;
            } else {
                state.rules.push(action.payload.rule);
            }

            return {
                ...state,
                rules: [...state.rules],
                editRule: {
                    isLoading: false,
                    rule: undefined,
                    beneficiaryBusinessUnits: undefined,
                    beneficiaryPriceGroups: undefined
                }
            };

        case RewardsActionTypes.LoadTokenActivitiesSuccess:
            const tokens = state.tokens;

            if (action.payload.items.length) {
                tokenIndex = state.tokens.findIndex(token => token.id === action.payload.items[0].tokenId);
                if (tokenIndex !== -1) {
                    tokens[tokenIndex].activities =
                        !tokens[tokenIndex].activities || tokens[tokenIndex].activities.page >= action.payload.page
                            ? action.payload : {
                                ...tokens[tokenIndex].activities,
                                page: action.payload.page,
                                items: tokens[tokenIndex].activities.items.concat(action.payload.items)
                            };
                }
            }

            return {
                ...state,
                tokens: [
                    ...tokens
                ],
                loading: false
            };
        case RewardsActionTypes.LoadRuleById:
            return {
                ...state,
                editRule: {
                    ...state.editRule,
                    isLoading: true
                }
            };
        case RewardsActionTypes.LoadRuleByIdSuccess:
            return {
                ...state,
                editRule: {
                    rule: action.payload.rule,
                    beneficiaryBusinessUnits: action.payload.beneficiaryBusinessUnits,
                    beneficiaryPriceGroups: action.payload.beneficiaryPriceGroups,
                    isLoading: false
                }
            };
        case RewardsActionTypes.EnableRule:
            ruleIndex = state.rules.findIndex(rule => rule.id === action.payload.ruleId);
            state.rules[ruleIndex].isLoading = true;

            return {
                ...state,
                rules: [...state.rules]
            };
        case RewardsActionTypes.EnableRuleSuccess:
            const ruleIndexSuccess = state.rules.findIndex(rule => rule.id === action.payload.id);
            state.rules[ruleIndexSuccess] = {
                ...action.payload,
                isLoading: false
            };

            return {
                ...state,
                rules: [...state.rules]
            };
        case RewardsActionTypes.UpdateExchangeRate:
            tokenIndex = state.tokens.findIndex(token => token.id === action.payload.fromTokenId);
            state.tokens[tokenIndex] = {
                ...state.tokens[tokenIndex],
                isLoading: true
            };

            return {
                ...state,
                tokens: [...state.tokens]
            };

        case RewardsActionTypes.UpdateExchangeRateSuccess:
            tokenIndex = state.tokens.findIndex(token => token.id === action.payload.fromTokenId);
            state.tokens[tokenIndex].exchangeRate[0].toAmount = action.payload.toAmount;

            state.tokens[tokenIndex] = {
                ...state.tokens[tokenIndex],
                editRate: false,
                exchangeRate: [
                    ...state.tokens[tokenIndex].exchangeRate,
                ],
                isLoading: false
            };

            return {
                ...state,
                tokens: [...state.tokens]
            };
        case RewardsActionTypes.UpdateExchangeRateFailed:
            tokenIndex = state.tokens.findIndex(token => token.id === action.payload.tokenId);
            state.tokens[tokenIndex] = {
                ...state.tokens[tokenIndex],
                editRate: true,
                isLoading: false
            };

            return {
                ...state,
                tokens: [...state.tokens]
            };
        case RewardsActionTypes.LoadWalletByToken:
            return {
                ...state,
                loading: true
            };
        case RewardsActionTypes.LoadWalletByTokenSuccess:
            return {
                ...state,
                loading: false,
                paymentWallet: action.payload
            };
        default:
            return state;
    }
}
