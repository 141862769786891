import { Component, OnInit, Input } from '@angular/core';

@Component({
    selector: 'app-icon-export',
    templateUrl: './icon-export.component.html'
})
export class IconExportComponent implements OnInit {

    @Input() cssClass = '';

    constructor() { }

    ngOnInit() {
    }

}
