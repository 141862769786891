import { Component, OnInit, Input } from '@angular/core';

@Component({
    selector: 'app-icon-draft',
    templateUrl: './icon-draft.component.html'
})
export class IconDraftComponent implements OnInit {
    @Input() cssClass = '';

    constructor() { }

    ngOnInit() {
    }

}
