import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-product-price-chart',
  templateUrl: './product-price-chart.component.html',
  styleUrls: ['./product-price-chart.component.scss']
})
export class ProductPriceChartComponent implements OnInit, OnChanges {

  @Input() priceTrends: any[];

  public barChartType = 'line';
  public barChartLegend = true;
  public barChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    legend: { 
      labels : {
        usePointStyle: true,
        boxWidth: 8
      },
      pointStyle: 'circle'
    },
    scales: {
      xAxes: [{
        gridLines: {
          drawBorder: true,
          drawOnChartArea: false,
          drawTicks: false
        },
        ticks: {          
          padding: 15
        }
      }],
      yAxes: [{
        gridLines: {
          drawBorder: true,
          drawOnChartArea: false,
          drawTicks: false,
        },
        ticks: {
          beginAtZero: false,
          min: 0,
          padding: 10
        }
      }],
    }
  };

  public barChartLabels = [];
  public barChartData = [];

  constructor(
    private datePipe: DatePipe
  ) { }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.priceTrends && changes.priceTrends.currentValue && changes.priceTrends.currentValue.length) {
      const colors = ['#5DD7F6', '#FFD714'];
      this.barChartLabels = changes.priceTrends.currentValue[0].priceTrendItems.map(i => this.datePipe.transform(i.date, 'MM/dd'));
      this.barChartData = changes.priceTrends.currentValue.map((vTrend, idx) => ({
        data: vTrend.priceTrendItems.map(i => i.price),
        label: vTrend.vendorName,
        fill: false,
        lineTension: 0,
        borderColor: colors[idx],
        pointBackgroundColor: colors[idx],
        pointHoverBorderColor: colors[idx]
      }));
    }
  }

}
