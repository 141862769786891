import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'app-icon-no-results',
    templateUrl: './icon-no-results.component.html',
    styleUrls: ['./icon-no-results.component.scss']
})
export class IconNoResultsComponent implements OnInit {

    @Input() cssClass = '';

    constructor() { }

    ngOnInit(): void {
    }

}
