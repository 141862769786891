import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/app.reducer';
import { EditTag, DeleteTag } from '../../actions/tags.actions';

@Component({
    selector: 'app-tags-filter-modal',
    templateUrl: './tags-filter-modal.component.html',
    styleUrls: ['./tags-filter-modal.component.scss']
})
export class TagsFilterModalComponent implements OnInit {

    editTagsForm: FormGroup;
    tags = [];
    show: boolean = false;

    constructor(
        private fb: FormBuilder,
        private store: Store<AppState>,
        public dialogRef: MatDialogRef<TagsFilterModalComponent>,
        @Inject(MAT_DIALOG_DATA) public tags$: Observable<any>) {
    }

    ngOnInit() {
        this.editTagsForm = this.fb.group({
            name: ['', [Validators.required]],
            id: ['', [Validators.required]]
        });

        this.tags$.subscribe(tags => this.tags = tags.map(t => ({ ...t, isSelected: false })));
    }

    onSubmit() {
        if (this.editTagsForm.invalid) {
            return;
        }

        const tagSelected = this.tags.find(tag => tag.isSelected);
        const tagId = this.editTagsForm.value.id;
        const tagName = this.editTagsForm.value.name.trim();

        if (tagName.length === 0) {
            this.editTagsForm.setErrors({ 'invalid': true });
            return;
        }

        if (tagSelected.name !== tagName) {
            this.store.dispatch(new EditTag({
                tag: {
                    name: tagName,
                    id: tagId
                }
            }));
            this.editTagsForm.reset();
        }
    }

    closeModal(): void {
        this.dialogRef.close();
        this.tags.forEach(t => t.isSelected = false);
    }

    selectTag(tag) {
        this.tags.forEach(t => t.isSelected = t.id === tag.id);
        this.editTagsForm.controls.name.setValue(tag.name);
        this.editTagsForm.controls.id.setValue(tag.id);
    }

    toggleTag() {
        const tagSelected = this.tags.find(tag => tag.isSelected);
        if (!tagSelected) {
            return;
        }

        this.show = !this.show;
    }
    confirmDelete() {
        if (this.editTagsForm.invalid) {
            return;
        }

        const tagId = this.editTagsForm.value.id;
        this.store.dispatch(new DeleteTag({
            tag: {
                id: tagId
            }
        }));
        this.editTagsForm.reset();
        this.show = false;
    }

}
