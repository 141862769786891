import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'app-icon-remove-selected',
    templateUrl: './icon-remove-selected.component.html'
})
export class IconRemoveSelectedComponent implements OnInit {

    @Input() cssClass: string;

    constructor() { }

    ngOnInit() {
    }

}
