import { createSelector, createFeatureSelector } from '@ngrx/store';
import { CustomerState } from '../reducers/customer.reducer';

export const selectCustomerState = createFeatureSelector<CustomerState>('customer');

export const selectCustomers = createSelector(
    selectCustomerState,
    state => state.customers
);
export const selectLoadingCustomers = createSelector(
    selectCustomerState,
    state => state.loadingCustomers
);
export const selectCustomerLeads = createSelector(
    selectCustomerState,
    state => state.customerLeads
);
export const selectLoadingCustomerLeads = createSelector(
    selectCustomerState,
    state => state.loadingCustomerLeads
);

export const selectCustomerDetails = createSelector(
    selectCustomerState,
    state => state.customerDetails
);

export const selectCustomerDetailsLoading = createSelector(
    selectCustomerState,
    state => state.customerDetailsLoading
);

export const getCustomerInvites = createSelector(
    selectCustomerState,
    state => state.customerInvites
);

export const getCustomerInvitesLoading = createSelector(
    selectCustomerState,
    state => state.customerInvitesLoading
);

export const priceLevelsList = createSelector(
    selectCustomerState,
    state => state.priceLevels
);
