export class GeneralItemModel {
    id: number;
    name?: string;
    itemName?: string;

    constructor(data?: GeneralItemModel) {
        if (data) {
            this.id = data.id;
            this.name = data.name;
            this.itemName = data.name;
        }
    }
}
