import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { exhaustMap, map } from 'rxjs/operators';
import { TagsService } from 'src/app/core/services/tags/tags.service';
import { LoadTags, TagsActionTypes, TagsLoaded, EditTag, ReloadTagsAction, TagUIAction, DeleteTag, AssignTag,
    CreateAndAssignTag } from '../actions/tags.actions';
import { AssignTagSuccess, UpdateTags, UpdateTagsActionType } from '../actions/order-guide.action';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/app.reducer';

@Injectable()
export class TagsEffects {

    @Effect()
    tags$ = this.actions$.pipe(
        ofType<LoadTags>(TagsActionTypes.LoadTagsAction),
        exhaustMap(() => this.tagsSvc.get().pipe(
            map(tags => new TagsLoaded({ tags }))
        ))
    );

    @Effect({dispatch: false})
    editTag$ = this.actions$.pipe(
        ofType<EditTag>(TagsActionTypes.EditTagAction),
        exhaustMap((action) => this.tagsSvc.edit(action.payload.tag)),
        map(tag => {
            this.store.dispatch(new ReloadTagsAction({ tag, type: TagUIAction.Edit }));
            this.store.dispatch(new UpdateTags({ tag, actionType: UpdateTagsActionType.Update }));
        })
    );

    @Effect({ dispatch: false })
    deleteTag$ = this.actions$.pipe(
        ofType<DeleteTag>(TagsActionTypes.DeleteTagAction),
        exhaustMap((action) => this.tagsSvc.delete(action.payload.tag).pipe(
            map(response => action.payload.tag)
        )),
        map(tag => {
            this.tagsSvc.resetCache();
            this.store.dispatch(new ReloadTagsAction({ tag, type: TagUIAction.Delete }));
            this.store.dispatch(new UpdateTags({
                tag,
                actionType: UpdateTagsActionType.Delete
            }));
        })
    )

    @Effect()
    assigTag$ = this.actions$.pipe(
        ofType<AssignTag>(TagsActionTypes.AssignTag),
        exhaustMap((action) => {
            const obs = action.payload.isAssigned ? this.tagsSvc.unassign(action.payload.tagId, action.payload.productId) :
                this.tagsSvc.assign(action.payload.tagId, action.payload.productId, action.payload.name);
            return obs.pipe(
                map(response => ({
                    response,
                    params: action.payload
                })));
        }),
        map((response) => new AssignTagSuccess({
            tagId: response.params.tagId,
            productId: response.params.productId,
            isAssigned: !response.params.isAssigned
        }))
    );

    @Effect({ dispatch: false })
    createAndAssign$ = this.actions$.pipe(
        ofType<CreateAndAssignTag>(TagsActionTypes.CreateAndAssignTag),
        exhaustMap((action) => this.tagsSvc.create(action.payload.name, action.payload.productId).pipe(
            map(tag => ({
                ...tag,
                isSelected: true,
                productId: action.payload.productId
            }))
        )),
        map((newTag) => {
            this.tagsSvc.resetCache();
            this.store.dispatch(new UpdateTags({
                tag: newTag,
                actionType: UpdateTagsActionType.Add
            }));
            this.store.dispatch(new ReloadTagsAction({
                tag: newTag,
                type: TagUIAction.Add
            }));
        })

    );

    constructor(
        private actions$: Actions,
        private tagsSvc: TagsService,
        private store: Store<AppState>
    ) { }

}
