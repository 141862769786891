import { Action } from '@ngrx/store';
import { CategoryModel } from '../../core/models/product/category.model';
import { ProductCatalogFilter } from 'src/app/core/models/product/product-catalog.filter';
import { ProductDetailsModel } from 'src/app/core/models/product/product-details.model';
import { ProductSettingsModel } from 'src/app/core/models/product/product-settings.model';
import { SaveProductCatalogModel } from 'src/app/core/models/product/save-product.model';
import { ProductCatalogModel } from 'src/app/core/models/product/product-catalog.model';
import { UnitOfMeasureModel } from 'src/app/core/models/product/unit-of-measure.model';
import { CategoryOptionModel } from 'src/app/core/models/product/category-option.model';
import { ProductSaveRequestModel } from 'src/app/core/models/product/product-save-request.model';
import { ConvertedPriceModel } from 'src/app/core/models/product/converted-price.model';
import { ProductUploadModel } from 'src/app/core/models/product/product-upload.model';
import { ProductUploadResponseModel } from 'src/app/core/models/product/upload-response.model';
import { SaveUploadModel } from 'src/app/core/models/product/save-upload.model';
import { ProductParCountModel } from 'src/app/core/models/product/product-par-count.model';
import { PriceLevelInfo } from 'src/app/core/models/product/price-level-info.model';
import { PriceLevelModel } from 'src/app/core/models/product/price-level.model';
import { ProductInventorySettingsModel } from 'src/app/core/models/product/product-inventory-settings.model';
import { ProductCompositionModel } from 'src/app/core/models/product/product-composition.model';
import { GlobalProductModel } from 'src/app/core/models/product/global-product.model';


export enum ProductActionTypes {
    LoadCategories = '[Product] Load Categories',
    LoadCategoriesSuccess = '[Product] Load Categories Success',
    LoadProducts = '[Product] Load Products',
    LoadProductsSuccess = '[Product] Load Products Success',
    SaveProductSettings = '[Product] Save Product Settings',
    SaveProductSettingsSuccess = '[Product] Save Product Settings Success',
    SaveProductSettingsFailed = '[Product] Save Product Settings Failed',
    SaveProduct = '[Product] Save product',
    SaveProductSuccess = '[Product] Save product Success',
    SaveProductFailed = '[Product] Save product Failed',
    LoadProductDetails = '[Product] Load Price Levels For Product',
    LoadProductDetailsSuccess = '[Product] Load Price Levels For Product Success',
    SavePriceLevelsForProduct = '[Product] Save Price Levels For Product',
    SavePriceLevelsForProductSuccess = '[Product] Save Price Levels For Product Success',
    ResetPrice = '[Product] Reset Price',
    ResetPriceSuccess = '[Product] Reset Price Success',
    LoadBaseCatalogs = '[Product] Load Base Catalogs',
    LoadBaseCatalogsSuccess = '[Product] Load Base Catalogs Success',
    LoadCategoryOptions = '[Product] Load Category Options',
    LoadCategoryOptionsSuccess = '[Product] Load Category Options Success',
    LoadUomOptions = '[Product] Load Uom Options',
    LoadUomOptionsSuccess = '[Product] Load Uom Options Success',
    SaveProductCatalog = '[Product] Save Product Catalog',
    SaveProductCatalogSuccess = '[Product] Save Product Catalog Success',
    LoadConvertedPrice = '[Product] Load Converted Price',
    LoadConvertedPriceSuccess = '[Product] Load Converted Price Success',
    LoadRelatedUom = '[Product] Load Related Uom',
    LoadRelatedUomSuccess = '[Product] Load Related Uom Success',
    UploadPrices = '[Product] Upload Product Prices',
    UploadPricesSuccess = '[Product] Upload Product Prices Success',
    UploadPricesFinish = '[Product] Upload Product Prices Finish',
    UploadPricesSuccessFinish = '[Product] Upload Product Prices Finish Success',
    LoadPriceLevels = '[Product] Load Price Level Without Base',
    LoadPriceLevelsSuccess = '[Product] Load Price Level Without Base Success',
    ExportProducts = '[Product] Export Products',
    ExportProductsSuccess = '[Product] Export Products Success',
    AddCount = '[Product Warehouse] Add Count',
    AddCountSuccess = '[Product Warehouse] Add Count Success',
    AddCountFailed = '[Product Warehouse] Add Count Failed',
    AddPar = '[Product Warehouse] Add Par',
    AddParSuccess = '[Product Warehouse] Add Par Success',
    AddParFailed = '[Product Warehouse] Add Par Failed',
    ConvertProductUom = '[Product Warehouse] Convert Product Inventory UOM',
    ConvertProductUomSuccess = '[Product Warehouse] Convert Product Inventory UOM Success',
    ConvertProductUomFailed = '[Product Warehouse] Convert Product Inventory UOM Failed',
    LoadAllCategories = '[Product] Load All Categories',
    LoadAllCategoriesSuccess = '[Product] Load All Categories Success',
    SavePriceLevelSuccess = '[Account] Save Price Level Success',
    SavePriceLevel = '[Product] Save Price Level',
    UpdatePriceLevel = '[Product] Update Price Level',
    UpdatePriceLevelSuccess = '[Product] Update Price Level Success',
    SaveProductInventorySettings = '[Product] Save product inventory settings',
    SaveProductInventorySettingsSuccess = '[Product] Save product inventory settings success',
    LoadProductsForComposition = '[Product] Load Products for Composition',
    LoadProductsForCompositionSuccess = '[Product] Load Products for Composition Success',
    AddProductForComposition = '[Product] Add product for Composition',
    AddProductForCompositionSuccess = '[Product] Add product for Composition success',
    CreateProductComposition = '[Product] Create Product Composition',
    CreateProductCompositionSuccess = '[Product] Create Product Composition Success',
    EditProductCompositionSuccess = '[Product] Edit Product Composition Success',
    EditProductCompositionFailed = '[Product] Edit Product Composition Failed',
    EditProductComposition = '[Product] Edit Product Composition',
    DeleteProductCompositionSuccess = '[Product] Delete Product Composition Success',
    DeleteProductCompositionFailed = '[Product] Delete Product Composition Failed',
    DeleteProductComposition = '[Product] Delete Product Composition',
    LoadProductCompositionSuccess = '[Product] Load Product Composition Success',
    LoadProductComposition = '[Product] Load Product Composition',
    AddProductForCompositionFailed = '[Product] Add product for Composition Failed',
    CreateProductCompositionFailed = '[Product] Create Product Composition Failed',
    ResetLastSavedProductId = '[Product] Reset Last Saved Product Id',
    ClearProducts = '[Product] Clear Products',
    LoadPreviewInheritProduct = '[Product] Preview Inherit parent',
    SaveCalculateFromParent = '[Product] Save Product setup as calculate from parent',
    SetVariationAsParent = '[Product] Set Variation as Parent',
    SetVariantAsParentSuccess = '[Product] Set Variation as Parent Success',
    ClearConvertedPrice = '[Product] Clear converted prices',
    SaveCalculateFromSuccess = '[Product] Save Calculate From with Success'
}

export class LoadCategories implements Action {
    readonly type = ProductActionTypes.LoadCategories;

    constructor() { }
}

export class LoadCategoriesSuccess implements Action {
    readonly type = ProductActionTypes.LoadCategoriesSuccess;

    constructor(public payload: Array<CategoryModel>) { }
}

export class LoadProducts implements Action {
    readonly type = ProductActionTypes.LoadProducts;

    constructor(public payload: ProductCatalogFilter) { }
}

export class LoadProductsSuccess implements Action {
    readonly type = ProductActionTypes.LoadProductsSuccess;

    constructor(public payload: ProductDetailsModel) { }
}

export class SaveProductSettings implements Action {
    readonly type = ProductActionTypes.SaveProductSettings;

    constructor(public payload: ProductSettingsModel) { }
}

export class SaveProductSettingsSuccess implements Action {
    readonly type = ProductActionTypes.SaveProductSettingsSuccess;

    constructor(public payload: number) { }
}

export class SaveProductSettingsFailed implements Action {
    readonly type = ProductActionTypes.SaveProductSettingsFailed;
}

export class SaveProduct implements Action {
    readonly type = ProductActionTypes.SaveProduct;

    constructor(public payload: SaveProductCatalogModel) { }
}

export class SaveCalculateFromParent implements Action {
    readonly type = ProductActionTypes.SaveCalculateFromParent;

    constructor(public payload: any) { }
}

export class SaveProductSuccess implements Action {
    readonly type = ProductActionTypes.SaveProductSuccess;

    constructor(public payload: ProductCatalogModel) { }
}

export class SaveCalculateFromSuccess implements Action {
    readonly type = ProductActionTypes.SaveCalculateFromSuccess;

    constructor(public payload) { }
}
export class SaveProductFailed implements Action {
    readonly type = ProductActionTypes.SaveProductFailed;

    constructor(public payload) { }
}

export class LoadProductDetails implements Action {
    readonly type = ProductActionTypes.LoadProductDetails;

    constructor(public payload: number) { }
}

export class LoadProductDetailsSuccess implements Action {
    readonly type = ProductActionTypes.LoadProductDetailsSuccess;

    constructor(public payload: ProductCatalogModel) { }
}

export class SavePriceLevelsForProduct implements Action {
    readonly type = ProductActionTypes.SavePriceLevelsForProduct;

    constructor(public payload: ProductCatalogModel) { }
}

export class SavePriceLevelsForProductSuccess implements Action {
    readonly type = ProductActionTypes.SavePriceLevelsForProductSuccess;

    constructor(public payload: ProductCatalogModel) { }
}

export class LoadBaseCatalogs implements Action {
    readonly type = ProductActionTypes.LoadBaseCatalogs;
}

export class LoadBaseCatalogsSuccess implements Action {
    readonly type = ProductActionTypes.LoadBaseCatalogsSuccess;

    constructor(public payload: Array<PriceLevelInfo>) { }
}

export class LoadCategoryOptions implements Action {
    readonly type = ProductActionTypes.LoadCategoryOptions;
}

export class LoadCategoryOptionsSuccess implements Action {
    readonly type = ProductActionTypes.LoadCategoryOptionsSuccess;

    constructor(public payload: Array<CategoryOptionModel>) { }
}

export class LoadUomOptions implements Action {
    readonly type = ProductActionTypes.LoadUomOptions;
}

export class LoadUomOptionsSuccess implements Action {
    readonly type = ProductActionTypes.LoadUomOptionsSuccess;

    constructor(public payload: Array<UnitOfMeasureModel>) { }
}

export class SaveProductCatalog implements Action {
    readonly type = ProductActionTypes.SaveProductCatalog;

    constructor(public payload: ProductSaveRequestModel) { }
}

export class SaveProductCatalogSuccess implements Action {
    readonly type = ProductActionTypes.SaveProductCatalogSuccess;

    constructor(public payload: ProductCatalogModel) { }
}

export class LoadConvertedPrice implements Action {
    readonly type = ProductActionTypes.LoadConvertedPrice;

    constructor(public payload: Array<ConvertedPriceModel>) { }
}

export class LoadConvertedPriceSuccess implements Action {
    readonly type = ProductActionTypes.LoadConvertedPriceSuccess;

    constructor(public payload: Array<ConvertedPriceModel>) { }
}

export class LoadRelatedUom implements Action {
    readonly type = ProductActionTypes.LoadRelatedUom;

    constructor(public payload: number) { }
}

export class LoadRelatedUomSuccess implements Action {
    readonly type = ProductActionTypes.LoadRelatedUomSuccess;

    constructor(public payload: Array<UnitOfMeasureModel>) { }
}

export class UploadPrices implements Action {
    readonly type = ProductActionTypes.UploadPrices;

    constructor(public payload: ProductUploadModel) { }
}

export class UploadPricesSuccess implements Action {
    readonly type = ProductActionTypes.UploadPricesSuccess;

    constructor(public payload: ProductUploadResponseModel) { }
}

export class UploadPricesFinish implements Action {
    readonly type = ProductActionTypes.UploadPricesFinish;

    constructor(public payload: SaveUploadModel) { }
}

export class UploadPricesSuccessFinish implements Action {
    readonly type = ProductActionTypes.UploadPricesSuccessFinish;
}

export class LoadPriceLevels implements Action {
    readonly type = ProductActionTypes.LoadPriceLevels;
}

export class LoadPriceLevelsSuccess implements Action {
    readonly type = ProductActionTypes.LoadPriceLevelsSuccess;

    constructor(public payload: Array<PriceLevelInfo>) { }
}

export class ExportProducts implements Action {
    readonly type = ProductActionTypes.ExportProducts;

    constructor(public payload: ProductCatalogFilter) { }
}

export class ExportProductsSuccess implements Action {
    readonly type = ProductActionTypes.ExportProductsSuccess;

    constructor(public payload: any) { }
}

export class AddCount implements Action {
    readonly type = ProductActionTypes.AddCount;

    constructor(public payload: { warehouseId: number, locationId: number, count: ProductParCountModel }) { }
}

export class AddCountSuccess implements Action {
    readonly type = ProductActionTypes.AddCountSuccess;

    constructor(public payload: { count: ProductParCountModel, onHandTotal: number, onOrderTotal: number, liveInventory: number }) { }
}

export class AddCountFailed implements Action {
    readonly type = ProductActionTypes.AddCountFailed;
}

export class AddPar implements Action {
    readonly type = ProductActionTypes.AddPar;

    constructor(public payload: { warehouseId: number, locationId: number, parTemplateId: number, par: ProductParCountModel }) { }
}

export class AddParSuccess implements Action {
    readonly type = ProductActionTypes.AddParSuccess;

    constructor(public payload: ProductParCountModel) { }
}

export class AddParFailed implements Action {
    readonly type = ProductActionTypes.AddParFailed;
}

export class ConvertProductUom implements Action {
    readonly type = ProductActionTypes.ConvertProductUom;

    constructor(public payload: { uomId: number, uomSize: number, productId: number }) { }
}

export class ConvertProductUomSuccess implements Action {
    readonly type = ProductActionTypes.ConvertProductUomSuccess;

    constructor(public payload: { productId: number, onHand: number }) { }
}

export class ConvertProductUomFailed implements Action {
    readonly type = ProductActionTypes.ConvertProductUomFailed;
}

export class LoadAllCategories implements Action {
    readonly type = ProductActionTypes.LoadAllCategories;
}

export class LoadAllCategoriesSuccess implements Action {
    readonly type = ProductActionTypes.LoadAllCategoriesSuccess;

    constructor(public payload: Array<CategoryModel>) { }
}

export class SavePriceLevelSuccess implements Action {
    readonly type = ProductActionTypes.SavePriceLevelSuccess;

    constructor(public payload: PriceLevelInfo) { }
}

export class SavePriceLevel implements Action {
    readonly type = ProductActionTypes.SavePriceLevel;

    constructor(public payload: PriceLevelModel) { }
}

export class UpdatePriceLevel implements Action {
    readonly type = ProductActionTypes.UpdatePriceLevel;

    constructor(public payload: PriceLevelModel) { }
}

export class UpdatePriceLevelSuccess implements Action {
    readonly type = ProductActionTypes.UpdatePriceLevelSuccess;

    constructor(public payload: PriceLevelInfo) { }
}

export class SaveProductInventorySettings implements Action {
    readonly type = ProductActionTypes.SaveProductInventorySettings;

    constructor(public payload: { warehouseId: number, locationId: number, settings: ProductInventorySettingsModel }) { }
}

export class SaveProductInventorySettingsSuccess implements Action {
    readonly type = ProductActionTypes.SaveProductInventorySettingsSuccess;
}

export class LoadProductsForComposition implements Action {
    readonly type = ProductActionTypes.LoadProductsForComposition;

    constructor(public payload: { keyword: string, excludedProductsIds: Array<number> }) { }
}

export class LoadProductsForCompositionSuccess implements Action {
    readonly type = ProductActionTypes.LoadProductsForCompositionSuccess;

    constructor(public payload: Array<GlobalProductModel>) { }
}

export class AddProductForComposition implements Action {
    readonly type = ProductActionTypes.AddProductForComposition;

    constructor(public payload: ProductSaveRequestModel) { }
}

export class AddProductForCompositionSuccess implements Action {
    readonly type = ProductActionTypes.AddProductForCompositionSuccess;
}

export class CreateProductComposition implements Action {
    readonly type = ProductActionTypes.CreateProductComposition;

    constructor(public payload: ProductCompositionModel) { }
}

export class CreateProductCompositionSuccess implements Action {
    readonly type = ProductActionTypes.CreateProductCompositionSuccess;

    constructor(public payload: ProductCompositionModel) { }
}

export class EditProductComposition implements Action {
    readonly type = ProductActionTypes.EditProductComposition;

    constructor(public payload: ProductCompositionModel) { }
}

export class EditProductCompositionSuccess implements Action {
    readonly type = ProductActionTypes.EditProductCompositionSuccess;

    constructor(public payload: any) { }
}

export class EditProductCompositionFailed implements Action {
    readonly type = ProductActionTypes.EditProductCompositionFailed;
}

export class DeleteProductComposition implements Action {
    readonly type = ProductActionTypes.DeleteProductComposition;

    constructor(public payload: { compositionId: number, productId: number }) { }
}

export class DeleteProductCompositionSuccess implements Action {
    readonly type = ProductActionTypes.DeleteProductCompositionSuccess;

    constructor(public payload: number) { }
}

export class DeleteProductCompositionFailed implements Action {
    readonly type = ProductActionTypes.DeleteProductCompositionFailed;
}

export class CreateProductCompositionFailed implements Action {
    readonly type = ProductActionTypes.CreateProductCompositionFailed;
}

export class LoadProductComposition implements Action {
    readonly type = ProductActionTypes.LoadProductComposition;

    constructor(public payload: { productId: number, productCompositionId: number }) { }
}

export class LoadProductCompositionSuccess implements Action {
    readonly type = ProductActionTypes.LoadProductCompositionSuccess;

    constructor(public payload: ProductCompositionModel) { }
}

export class AddProductForCompositionFailed implements Action {
    readonly type = ProductActionTypes.AddProductForCompositionFailed;
}

export class ResetLastSavedProductId implements Action {
    readonly type = ProductActionTypes.ResetLastSavedProductId;
}

export class ClearProducts implements Action {
    readonly type = ProductActionTypes.ClearProducts;
}
export class LoadPreviewInheritProduct implements Action {
    readonly type = ProductActionTypes.LoadPreviewInheritProduct;

    constructor(public payload) { }
}
export class SetVariationAsParent implements Action {
    readonly type = ProductActionTypes.SetVariationAsParent;

    constructor(public payload: {mainProductId: number, productId: number}) { }
}
export class SetVariantAsParentSuccess implements Action {
    readonly type = ProductActionTypes.SetVariantAsParentSuccess;

    constructor(public payload) { }
}

export class ClearConvertedPrice implements Action {
    readonly type = ProductActionTypes.ClearConvertedPrice;

    constructor(public payload) { }
}

export type ProductActions =
    | LoadCategories
    | LoadCategoriesSuccess
    | LoadProducts
    | LoadProductsSuccess
    | SaveProductSettings
    | SaveProductSettingsSuccess
    | SaveProductSettingsFailed
    | SaveProduct
    | SaveProductSuccess
    | LoadProductDetails
    | LoadProductDetailsSuccess
    | SavePriceLevelsForProduct
    | SavePriceLevelsForProductSuccess
    | LoadBaseCatalogs
    | LoadBaseCatalogsSuccess
    | LoadCategoryOptions
    | LoadCategoryOptionsSuccess
    | LoadUomOptions
    | LoadUomOptionsSuccess
    | SaveProductCatalog
    | SaveProductCatalogSuccess
    | LoadConvertedPrice
    | LoadConvertedPriceSuccess
    | LoadRelatedUom
    | LoadRelatedUomSuccess
    | UploadPrices
    | UploadPricesSuccess
    | UploadPricesFinish
    | UploadPricesSuccessFinish
    | LoadPriceLevels
    | LoadPriceLevelsSuccess
    | ExportProducts
    | ExportProductsSuccess
    | AddCount
    | AddCountSuccess
    | AddCountFailed
    | AddPar
    | AddParSuccess
    | AddParFailed
    | ConvertProductUom
    | ConvertProductUomSuccess
    | ConvertProductUomFailed
    | LoadAllCategories
    | LoadAllCategoriesSuccess
    | SavePriceLevel
    | SavePriceLevelSuccess
    | UpdatePriceLevel
    | UpdatePriceLevelSuccess
    | LoadProductsForComposition
    | LoadProductsForCompositionSuccess
    | AddProductForComposition
    | AddProductForCompositionSuccess
    | CreateProductComposition
    | CreateProductCompositionSuccess
    | EditProductComposition
    | EditProductCompositionSuccess
    | DeleteProductComposition
    | DeleteProductCompositionSuccess
    | LoadProductComposition
    | LoadProductCompositionSuccess
    | AddProductForCompositionFailed
    | DeleteProductCompositionFailed
    | CreateProductCompositionFailed
    | EditProductCompositionFailed
    | ResetLastSavedProductId
    | ClearProducts
    | LoadPreviewInheritProduct
    | SaveCalculateFromParent
    | SetVariationAsParent
    | SetVariantAsParentSuccess
    | ClearConvertedPrice
    | SaveProductFailed
    | SaveCalculateFromSuccess;

