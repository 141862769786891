import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-icon-archive',
    templateUrl: './icon-archive.component.html'
})
export class IconArchiveComponent implements OnInit {

    constructor() { }

    ngOnInit() {
    }

}
