import { Action } from '@ngrx/store';
import { LoadAllChannelsFilter } from 'src/app/core/models/chat/load-all-channels.filter';
import { EntityChanel } from 'src/app/core/models/chat/entity-chanel.model';
import { ChannelFilter } from 'src/app/core/models/chat/channel.filter';
import { ChatConversation } from 'src/app/core/models/chat/chat-conversation.model';
import { BuyerVendorModel } from 'src/app/core/models/chat/buyer-vendor.model';
import { ChatDisplayStatus } from 'src/app/core/models/chat/chat-display.status';

export enum ChatActionTypes {
    LoadAllChannels = '[Chat] Load All Channels',
    LoadAllChannelsSuccess = '[Chat] Load All Channels Success',
    LoadConversation = '[Chat] Load Conversation',
    LoadConversationSuccess = '[Chat] Load Conversation Success',
    LoadBuyerVendor = '[Chat] Load Buyer Vendor',
    LoadBuyerVendorSuccess = '[Chat] Load Buyer Vendor Success',
    GenerateChannelUUID = '[Chat] Generate Channel',
    GenerateChannelUUIDSuccess = '[Chat] Generate Channel Success',
    ResetUnreadMessages = '[Chat] Reset Unread Messages',
    ChatDisplayStatus = '[Chat] Set Display Status'
}

export class LoadAllChannels implements Action {
    readonly type = ChatActionTypes.LoadAllChannels;

    constructor(public payload: LoadAllChannelsFilter) { }
}

export class LoadConversation implements Action {
    readonly type = ChatActionTypes.LoadConversation;

    constructor(public payload: ChannelFilter) { }
}

export class LoadConversationSuccess implements Action {
    readonly type = ChatActionTypes.LoadConversationSuccess;

    constructor(public payload: ChatConversation) { }
}

export class LoadAllChannelsSuccess implements Action {
    readonly type = ChatActionTypes.LoadAllChannelsSuccess;

    constructor(public payload: Array<EntityChanel>) { }
}

export class LoadBuyerVendor implements Action {
    readonly type = ChatActionTypes.LoadBuyerVendor;

    constructor(public payload: string) { }
}

export class LoadBuyerVendorSuccess implements Action {
    readonly type = ChatActionTypes.LoadBuyerVendorSuccess;

    constructor(public payload: Array<BuyerVendorModel>) { }
}

export class GenerateChannelUUID implements Action {
    readonly type = ChatActionTypes.GenerateChannelUUID;

    constructor(public payload: number) { }
}

export class GenerateChannelUUIDSuccess implements Action {
    readonly type = ChatActionTypes.GenerateChannelUUIDSuccess;

    constructor(public payload: string) { }
}

export class ResetUnreadMessages implements Action {
    readonly type = ChatActionTypes.ResetUnreadMessages;

    constructor(public payload: string) { }
}

export class ChatDisplayStatusAction implements Action {
    readonly type = ChatActionTypes.ChatDisplayStatus;

    constructor(public payload: ChatDisplayStatus) { }
}

export type ChatActions = LoadAllChannels
    | LoadAllChannelsSuccess
    | LoadConversation
    | LoadConversationSuccess
    | LoadBuyerVendor
    | LoadBuyerVendorSuccess
    | GenerateChannelUUID
    | GenerateChannelUUIDSuccess
    | ResetUnreadMessages
    | ChatDisplayStatusAction;
