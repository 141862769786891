import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-icon-product-details',
    templateUrl: './icon-product-details.component.html'
})
export class IconProductDetailsComponent implements OnInit {

    constructor() { }

    ngOnInit() {
    }

}
