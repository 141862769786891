import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { AppState } from 'src/app/app.reducer';
import { AuctionItemModel } from 'src/app/modules/auctions/models/auction-item.model';
import { AuctionOfferModel } from 'src/app/modules/auctions/models/auction-offer.model';
import { OfferStatusEnum } from 'src/app/modules/auctions/models/offer-status.enum';
import { ApproveOffer, LoadAuctionOffers, RejectOffer } from '../../../../shared/actions/auction.actions';
import { InfobarState } from 'src/app/shared/reducers/infobar.reducer';
import { selectAuctionOffers, selectSavingAuction } from 'src/app/shared/selectors/auction.selector';
import { ConfirmationModalComponent } from '../../confirmation-modal/confirmation-modal.component';

@Component({
    selector: 'app-auction-offers',
    templateUrl: './auction-offers.component.html',
    styleUrls: ['./auction-offers.component.scss']
})
export class AuctionOffersComponent implements OnInit {

    @Input() infobarConfigs: InfobarState;
    offers: Array<AuctionOfferModel>;
    auction: AuctionItemModel;
    loading$: Observable<boolean>;
    offerStatus = OfferStatusEnum;

    constructor(
        private store: Store<AppState>,
        private dialog: MatDialog
    ) { }

    ngOnInit(): void {
        this.loading$ = this.store.select(selectSavingAuction);
        if (this.infobarConfigs.params.auction) {
            this.auction = this.infobarConfigs.params.auction;
        }

        this.store.pipe(select(selectAuctionOffers)).subscribe((offer: Array<AuctionOfferModel>) => {
            if (offer) {
                this.offers = offer;
            }
        });
        if (this.infobarConfigs.params.auction.id) {
            this.store.dispatch(new LoadAuctionOffers(this.infobarConfigs.params.auction.id));
        }
    }

    approveOffer(offer: AuctionOfferModel) {
        const confirmRef = this.dialog.open(ConfirmationModalComponent, {
            data: {
                message: `Are you sure you want to approve this offer?`
            },
        });
        confirmRef.afterClosed().subscribe(isConfirmed => {
            if (isConfirmed) {
                this.store.dispatch(new ApproveOffer({ auctionItemId: offer.auctionItemId, offerId: offer.offerId }));
            }
        });
    }

    declineOffer(offer: AuctionOfferModel) {
        const confirmRef = this.dialog.open(ConfirmationModalComponent, {
            data: {
                message: `Are you sure you want to cancel this offer?`
            },
        });
        confirmRef.afterClosed().subscribe(isConfirmed => {
            if (isConfirmed) {
                this.store.dispatch(new RejectOffer({ auctionItemId: offer.auctionItemId, offerId: offer.offerId }));
            }
        });
    }

    hasApprovedOffer(displayedOffered: AuctionOfferModel): boolean {
        return this.offers.find(offer => offer.statusId === this.offerStatus.APPROVED && offer.offerId !== displayedOffered.offerId) ? true : false;
    }
}
