import { Component, OnInit, Input } from '@angular/core';

@Component({
    selector: 'app-icon-upload',
    templateUrl: './icon-upload.component.html'
})
export class IconUploadComponent implements OnInit {

    @Input() cssClass = '';

    constructor() { }

    ngOnInit() {
    }

}
