import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-icon-checkmark-round',
  templateUrl: './icon-checkmark-round.component.html',
  styleUrls: ['./icon-checkmark-round.component.scss']
})
export class IconCheckmarkRoundComponent implements OnInit {

  @Input() cssClass = '';

  constructor() { }

  ngOnInit(): void {
  }

}
