import { PlatformType } from './platform-type.enum';

// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: true,
    platformId: PlatformType.AMAST,
    platformName: 'AMAST',
    api: {
        auth: 'https://staging.auth.amast.com/api',
        content: 'https://staging.api.amast.com/api'
    },
    assets: {
        key: PlatformType.AMAST,
        baseUrl: 'https://tlamast.s3.amazonaws.com'
    },
    basePlatformUrl: 'http://localhost:83',
    PUBNUB_KEYS: {
        PUBLISH_KEY: 'pub-c-cde6b699-e2f6-4d39-9fbf-52d17f9fda81',
        SUBSCRIBE_KEY: 'sub-c-711ca270-581e-11ec-931a-1addb9510060',
    },
    BEHALF: {
        SDK_CLIENT_TOKEN: '7P8AJdnP'
    },
    createAccountLink: 'http://staging.account.amast.com',
    GA_TRACKER_ID: 'UA-17021264-16',
    REWARDS_POINTS_LEARN_URL: 'https://amast.com/learn-more-points/',
    GOOGLE_CAPTCHA_KEY: '6Lf-lGoeAAAAAEntFFi8u40zBvb8flheAWjEH-xv'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
