import { Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { AppState } from 'src/app/app.reducer';
import { TemplateEnum } from 'src/app/core/enums/template.enum';
import { DiscountPriceRequest } from 'src/app/core/models/order-guide/discount-price-request.model';
import { ProductCatalogModel } from 'src/app/core/models/product/product-catalog.model';
import { ResetProductSpecialPrice, SaveProductSpecialPrice } from '../../actions/order-guide.action';
import { RoundUpPipe } from '../../pipes/round-up/round-up.pipe';
import { getThemeId } from '../../selectors/branding.selector';
import { selectModifiedPrice } from '../../selectors/order-guide.selector';

@Component({
  selector: 'app-product-price-quantity',
  templateUrl: './product-price-quantity.component.html',
  styleUrls: ['./product-price-quantity.component.scss']
})
export class ProductPriceQuantityComponent implements OnInit {

  @Input() product: ProductCatalogModel;
  @Input() customerSiteId: number;
  @Input() customerAccountId: number;
  @Input() vendorCustomerId: number;
  @Input() inlineContainer: boolean;

  editMode = false;

  template$: Observable<TemplateEnum>;
  templateEnum = TemplateEnum;

  constructor(private store: Store<AppState>) { }

  ngOnInit(): void {
    this.template$ = this.store.select(getThemeId);
    this.store.select(selectModifiedPrice).subscribe((response) => {
      if (response?.productId === this.product.id) {
        this.product.vendors[0].discount_set_id = response.setId
      }
    })
  }

  resetPrice() {
    this.product.vendors[0].discount_price = undefined;
    const request = new DiscountPriceRequest();
    request.productId = this.product.id;
    request.setId = this.product.vendors[0].discount_set_id;
    request.customerSelectedSiteId = this.customerSiteId;
    request.customerSelectedAccountId = this.customerAccountId;
    this.store.dispatch(new ResetProductSpecialPrice(request));
    this.product.vendors[0].discount_set_id = 0;
  }

  submitPrice(value: number): void {
    const roundUp = new RoundUpPipe();
    const price = roundUp.transform(value);
    this.product.vendors[0].discount_price = price;
    this.editMode = false;
    const request = new DiscountPriceRequest();
    request.productId = this.product.id;
    request.productName = this.product.name;
    request.specialPrice = price;
    request.customerSelectedSiteId = this.customerSiteId;
    request.customerSelectedAccountId = this.customerAccountId;
    this.store.dispatch(new SaveProductSpecialPrice({ vendorCustomerId: this.vendorCustomerId, request }));
  }
}
