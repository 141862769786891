import { OrderActions, OrderActionTypes } from '../actions/order.actions';
import { OrderStatusType } from 'src/app/core/enums/order-status.enum';
import { AvailablePlatformModel } from 'src/app/core/models/order/available-platform.model';
import { OrderModel } from 'src/app/core/models/order/order.model';
import { ORDER_HISTORY_ITEM_CHANGE } from '../../core/enums/order-history-item-change.enum';

export interface OrderState {
    newOrdersCount: number;
    loading: boolean;
    orders: any;
    statuses: any[];
    orderDetails: OrderModel;
    loadingOrderDetails: boolean;
    editProducts: any[];
    availablePlatforms: Array<AvailablePlatformModel>;
    reloadSubtotals: boolean;
    validationMessages: any[];
}

export const initialState: OrderState = {
    newOrdersCount: 0,
    loading: false,
    orders: {},
    statuses: [],
    orderDetails: undefined,
    loadingOrderDetails: false,
    editProducts: [],
    availablePlatforms: [],
    reloadSubtotals: false,
    validationMessages: []
};

export function orderReducer(
    state = initialState,
    action: OrderActions): OrderState {

    switch (action.type) {
        case OrderActionTypes.NewOrdersCountLoadedAction:
            return {
                ...state,
                newOrdersCount: action.payload.count
            };
        case OrderActionTypes.LoadOrdersAction:
            return {
                ...state,
                loading: true
            };
        case OrderActionTypes.OrdersLoadedAction:
            return {
                ...state,
                loading: false,
                orders: action.payload.orders
            };
        case OrderActionTypes.StatusLoadedAction:
            return {
                ...state,
                statuses: action.payload.statuses
            };
        case OrderActionTypes.ChangeOrderStatus:
            return {
                ...state,
                loadingOrderDetails: action.payload.statusId === OrderStatusType.CANCELED
            };
        case OrderActionTypes.ChangeOrderStatusSuccess:
            const newOrders = state.orders?.items ? {
                ...state.orders,
                items: state.orders?.items.map(o => ({
                    ...o,
                    status: o.id === action.payload.orderId ? o.availableStatuses.find(s => s.id === action.payload.statusId) : o.status,
                    availableStatuses: o.id === action.payload.orderId ? action.payload.availableStatuses : o.availableStatuses
                }))
            } : state.orders;

            const newOrderDetails = state.orderDetails ? {
                ...state.orderDetails,
                availableStatuses: state.orderDetails.id === action.payload.orderId ?
                    action.payload.availableStatuses : state.orderDetails.availableStatuses,
                status: state.orderDetails.id === action.payload.orderId ?
                    state.orderDetails.availableStatuses.find(s => s.id === action.payload.statusId) : state.orderDetails.status
            } : state.orderDetails;

            return {
                ...state,
                orders: newOrders,
                loadingOrderDetails: false,
                orderDetails: newOrderDetails
            };
        case OrderActionTypes.LoadOrderAction:
            return {
                ...state,
                loadingOrderDetails: true
            };
        case OrderActionTypes.OrderLoadedAction:
            return {
                ...state,
                loadingOrderDetails: false,
                validationMessages: null,
                orderDetails: {
                    ...action.payload.orderDetails, isEditMode: false
                }
            };

        case OrderActionTypes.EditOrder:
            return {
                ...state,
                loadingOrderDetails: true
            };
        case OrderActionTypes.EditOrderLoaded:
            const { payload } = action;
            const editOrderDetails = {
                ...state.orderDetails,
                totalPrice: action.payload.editDetails.totalPrice,
                subTotalPrice: action.payload.editDetails.subTotalPrice,
                totalShippingPrice: action.payload.editDetails.deliveryFee,
                shoppingCartId: payload.editDetails.shoppingCartId,
                notes: action.payload.editDetails.notes,
                deliveryDate: action.payload.editDetails.deliveryDate,
                deliveryFromTime: action.payload.editDetails.deliveryFromTime,
                deliveryToTime: action.payload.editDetails.deliveryToTime,
                customFields: action.payload.editDetails.customFields,
                validationMessages: action.payload.editDetails.validationMessages,
                isEditMode: true,
                canSubmitOrder: payload.editDetails.canSubmitOrder
            };

            // check if we need to add new items
            const currentOrderProducts = state.orderDetails.items.map(i => i.productId);
            const newEditItems = action.payload.editDetails.items.filter(i => !currentOrderProducts.includes(i.productId));
            if (newEditItems.length) {
                for (const ni of newEditItems) {
                    editOrderDetails.items.push({
                        productId: ni.productId,
                        quantity: ni.quantity,
                        price: ni.price,
                        sku: ni.sku,
                        sellerSku: ni.vendorSku,
                        uomSize: ni.uomSize,
                        uomName: ni.uomName,
                        uomDescription: ni.uomDescription,
                        itemName: ni.name,
                        customName: ni.customName,
                        totalShippingPrice: 0,
                        totalPrice: ni.totalPrice,
                        imageUrl: ni.imageUrl,
                        imageUrlSmall: ni.imageUrl,
                        priceUom: ni.priceUom,
                        historyChange: null
                    });
                }
            }

            editOrderDetails.items = editOrderDetails.items.map((i) => {
                const editItem = payload.editDetails.items.find(ei => ei.productId === i.productId);

                if (editItem) {
                    i.shoppingCartItemId = editItem.shoppingCartItemId;
                    i.vendor = editItem.vendor;
                    i.price = editItem.price;
                    i.totalPrice = editItem.totalPrice;
                }

                return i;
            });

            return {
                ...state,
                loadingOrderDetails: false,
                orderDetails: editOrderDetails
            };

        case OrderActionTypes.EditOrderFailed:
            return {
                ...state,
                loadingOrderDetails: false
            };

        case OrderActionTypes.EditOrderChangeQuantitySuccess:
            const updatedOrderDetails = {
                ...state.orderDetails
            };

            if (action.payload.quantity > 0) {
                updatedOrderDetails.items = updatedOrderDetails.items
                    .filter(item => item.historyChange !== ORDER_HISTORY_ITEM_CHANGE.REMOVED)
                    .map(i => ({
                        ...i,
                        vendor: {
                            ...i.vendor,
                            quantity: i.productId === action.payload.productId ? action.payload.quantity : i.vendor.quantity
                        }
                    }));
            } else {
                updatedOrderDetails.items = updatedOrderDetails.items.filter(i => i.productId !== action.payload.productId);
            }

            return {
                ...state,
                orderDetails: updatedOrderDetails,
                reloadSubtotals: false,
                editProducts: []
            };
        case OrderActionTypes.LoadEditOrderProducts:
            return {
                ...state,
                loading: true
            };
        case OrderActionTypes.LoadEditOrderProductsSuccess:
            return {
                ...state,
                loading: false,
                editProducts: action.payload.products
            };
        case OrderActionTypes.SaveOrderEditNotesSuccess:
            return {
                ...state,
                orderDetails: {
                    ...state.orderDetails,
                    notes: action.payload.notes
                }
            };
        case OrderActionTypes.UpdateOrderEdit:
            const newEditOrderDetails = {
                ...state.orderDetails,
                totalPrice: action.payload.orderDetails.totalPrice,
                subTotalPrice: action.payload.orderDetails.subTotalPrice,
                totalShippingPrice: action.payload.orderDetails.deliveryFee,
                discount: action.payload.orderDetails.discount,
                customFields: action.payload.orderDetails.customFields,
                validationMessages: action.payload.orderDetails.validationMessages,
                canSubmitOrder: action.payload.orderDetails.canSubmitOrder
            };

            // check if we need to add new items
            const currentProducts = state.orderDetails.items.map(i => i.productId);
            const newItems = action.payload.orderDetails.items.filter(i => !currentProducts.includes(i.productId));
            if (newItems.length) {
                for (const ni of newItems) {
                    newEditOrderDetails.items.push({
                        productId: ni.productId,
                        quantity: ni.quantity,
                        price: ni.price,
                        sku: ni.sku,
                        sellerSku: ni.vendorSku,
                        uomSize: ni.uomSize,
                        uomName: ni.uomName,
                        uomDescription: ni.uomDescription,
                        itemName: ni.name,
                        customName: ni.customName,
                        totalShippingPrice: 0,
                        totalPrice: ni.totalPrice,
                        imageUrl: ni.imageUrl,
                        imageUrlSmall: ni.imageUrl,
                        priceUom: ni.priceUom,
                        historyChange: null
                    });
                }
            }

            // update items with edit mode fields
            newEditOrderDetails.items.map((i) => {
                const editItem = action.payload.orderDetails.items.find(ei => ei.productId === i.productId);

                if (editItem) {
                    i.shoppingCartItemId = editItem.shoppingCartItemId;
                    i.vendor = editItem.vendor;
                    i.totalPrice = editItem.totalPrice;
                    i.displaySku = editItem.vendorSku && editItem.vendorSku !== "" ? editItem.vendorSku : editItem.sku;
                }

                return i;
            });

            return {
                ...state,
                loadingOrderDetails: false,
                orderDetails: newEditOrderDetails
            };
        case OrderActionTypes.SaveOrderEditDeliveryError:
            return {
                ...state,
                validationMessages: [action.payload.errorMessage]
            };
        case OrderActionTypes.SaveOrderEditDeliverySuccess:
            return {
                ...state,
                orderDetails: {
                    ...state.orderDetails,
                    deliveryDate: action.payload.deliveryDate,
                    deliveryFromTime: action.payload.deliveryFromTime,
                    deliveryToTime: action.payload.deliveryToTime
                },
                validationMessages: null
            };
        case OrderActionTypes.SaveEditOrder:
            return {
                ...state,
                loadingOrderDetails: true
            };
        case OrderActionTypes.UpdateOrderDetailsLoad:
            return {
                ...state,
                loadingOrderDetails: action.payload.isLoading
            };
        case OrderActionTypes.LoadAvailablePlatformsSuccess:
            return {
                ...state,
                availablePlatforms: action.payload
            };
        case OrderActionTypes.SaveOrderCustomField:
            return {
                ...state,
                loading: true
            };
        case OrderActionTypes.SaveOrderCustomFieldSuccess:
            return {
                ...state,
                orderDetails: {
                    ...action.payload,
                    buyerShippingAddress: state.orderDetails.buyerShippingAddress,
                    vendorBillingAddress: state.orderDetails.vendorBillingAddress,
                    totalShippingPrice: action.payload.deliveryFee,
                    isEditMode: true
                },
                loading: false
            };
        case OrderActionTypes.SaveOrderCustomFieldFailed:
            return {
                ...state,
                loading: false
            };
        case OrderActionTypes.EditOrderCustomField:
            return {
                ...state,
                loading: true
            };
        case OrderActionTypes.EditOrderCustomFieldSuccess:
            return {
                ...state,
                orderDetails: {
                    ...action.payload,
                    buyerShippingAddress: state.orderDetails.buyerShippingAddress,
                    vendorBillingAddress: state.orderDetails.vendorBillingAddress,
                    totalShippingPrice: action.payload.deliveryFee,
                    isEditMode: true,
                    id: state.orderDetails.id
                },
                loading: false
            };
        case OrderActionTypes.EditOrderCustomFieldFailed:
            return {
                ...state,
                loading: false
            };
        case OrderActionTypes.EditOrderCustomFieldValueSuccess:
            return {
                ...state,
                orderDetails: {
                    ...action.payload,
                    buyerShippingAddress: state.orderDetails.buyerShippingAddress,
                    vendorBillingAddress: state.orderDetails.vendorBillingAddress,
                    totalShippingPrice: action.payload.deliveryFee,
                    id: state.orderDetails.id,
                    isEditMode: true
                }
            };

        case OrderActionTypes.DeleteOrderCustomField:
            return {
                ...state,
                reloadSubtotals: true
            };
        case OrderActionTypes.DeleteOrderCustomFieldFailed:
            return {
                ...state,
                reloadSubtotals: false
            };
        case OrderActionTypes.DeleteOrderCustomFieldSuccess:
            return {
                ...state,
                reloadSubtotals: false,
                orderDetails: {
                    ...action.payload,
                    buyerShippingAddress: state.orderDetails.buyerShippingAddress,
                    vendorBillingAddress: state.orderDetails.vendorBillingAddress,
                    id: state.orderDetails.id,
                    isEditMode: true
                }
            };
        case OrderActionTypes.RemovePercentageOverrideSuccess:
            return {
                ...state,
                orderDetails: {
                    ...action.payload,
                    buyerShippingAddress: state.orderDetails.buyerShippingAddress,
                    vendorBillingAddress: state.orderDetails.vendorBillingAddress,
                    totalShippingPrice: action.payload.deliveryFee,
                    id: state.orderDetails.id,
                    isEditMode: true
                }
            };
        case OrderActionTypes.EditOrderChangeQuantity:
            return {
                ...state,
                reloadSubtotals: true
            };
        case OrderActionTypes.EditOrderChangeQuantityFailed:
            return {
                ...state,
                reloadSubtotals: false
            };
        case OrderActionTypes.ClearOrders:
            return {
                ...state,
                orders: undefined
            };
        default:
            return state;
    }
}
