import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Cacheable } from 'ngx-cacheable';

@Injectable({
  providedIn: 'root'
})
export class UtilsService {

  constructor(
    private http: HttpClient
  ) { }

  @Cacheable()
  getCountries() {
    return this.http.get(`${environment.api.content}/Utils/countries`);
  }

  @Cacheable()
  getStates(countryId: number) {
    return this.http.get(`${environment.api.content}/Utils/states/${countryId}`);
  }

  @Cacheable()
  getTimezones() {
    return this.http.get(`${environment.api.content}/Utils/timeZones`);
  }

}
