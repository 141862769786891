import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-icon-product-variation',
    templateUrl: './icon-product-variation.component.html'
})
export class IconProductVariationComponent implements OnInit {

    constructor() { }

    ngOnInit() {
    }

}
