import { Injectable } from "@angular/core";
import { Actions, Effect, ofType } from '@ngrx/effects';
import { ChatService } from 'src/app/core/services/chat/chat.service';
import { Observable } from 'rxjs';
import { Action, Store } from '@ngrx/store';
import * as chatActions from "../actions/chat.actions";
import { mergeMap, map } from 'rxjs/operators';
import { ChatConversation } from 'src/app/core/models/chat/chat-conversation.model';
import { BuyerVendorModel } from 'src/app/core/models/chat/buyer-vendor.model';
import { AppState } from 'src/app/app.reducer';

@Injectable()
export class ChatEffects {

    constructor(
        private actions$: Actions,
        private chatService: ChatService,
        private store: Store<AppState>
    ) { }

    @Effect()
    loadAllChannels$: Observable<Action> = this.actions$.pipe(
        ofType(chatActions.ChatActionTypes.LoadAllChannels),
        mergeMap((action: chatActions.LoadAllChannels) => this.chatService.loadAllChannels(action.payload).pipe(
            map(channels => new chatActions.LoadAllChannelsSuccess(channels.entityChannels))
        ))
    );

    @Effect()
    loadConversation$: Observable<Action> = this.actions$.pipe(
        ofType(chatActions.ChatActionTypes.LoadConversation),
        mergeMap((action: chatActions.LoadConversation) => this.chatService.loadConversation(action.payload).pipe(
            map(conversation => {
                this.store.dispatch(new chatActions.ResetUnreadMessages(conversation.channel.uuid));
                return new chatActions.LoadConversationSuccess(new ChatConversation(conversation));
            })
        ))
    );

    @Effect()
    loadBuyerVendor$: Observable<Action> = this.actions$.pipe(
        ofType(chatActions.ChatActionTypes.LoadBuyerVendor),
        mergeMap((action: chatActions.LoadBuyerVendor) => this.chatService.loadBuyerVendor(action.payload).pipe(
            map(buyersVendors => new chatActions.LoadBuyerVendorSuccess(buyersVendors))
        ))
    );

    @Effect()
    generateUUID$: Observable<Action> = this.actions$.pipe(
        ofType(chatActions.ChatActionTypes.GenerateChannelUUID),
        mergeMap((action: chatActions.GenerateChannelUUID) => this.chatService.generateChannelUUID(action.payload).pipe(
            map(uuid => new chatActions.GenerateChannelUUIDSuccess(uuid))
        ))
    );

    @Effect({ dispatch: false })
    resetUnreadMessages$: Observable<any> = this.actions$.pipe(
        ofType(chatActions.ChatActionTypes.ResetUnreadMessages),
        mergeMap((action: chatActions.ResetUnreadMessages) => this.chatService.resetUnreadMessages(action.payload))
    );
}
