import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';

@Component({
    selector: 'app-customer-price-leveltemp1',
    templateUrl: './customer-price-level.temp1.component.html',
    styleUrls: ['./customer-price-level.temp1.component.scss']
})
export class CustomerPriceLevelTemp1Component implements OnInit {

    @Input() title: string;
    @Input() statuses: Array<any>;
    @Input() search: string;
    @Input() placeholder: string;
    @Input() priceLevels: Array<number>;
    @Output() selectAll = new EventEmitter();
    @Output() select = new EventEmitter<Array<number>>();
    @Output() nameClick = new EventEmitter<Array<number>>();

    constructor() { }

    ngOnInit(): void {
    }

    onSelectAll() {
        this.selectAll.emit();
    }

    onSelect($event: MatCheckboxChange, priceLevel) {
        if ($event.checked) {
            this.priceLevels.push(priceLevel.id);
        } else {
            const idx = this.priceLevels.findIndex(s => s === priceLevel.id);
            this.priceLevels.splice(idx, 1);
        }

        this.select.emit(this.priceLevels);
    }

    onNameClick(priceLevel, $event?) {
        if ($event) {
            $event.preventDefault();
        }

        this.nameClick.emit(priceLevel);
    }

}
