import { OtloginComponent } from './modules/otlogin/otlogin.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './modules/login/login.component';
import { MasterPageComponent } from './modules/master-page/master-page.component';
import { IsLoggedInGuard } from './core/guards/is-logged-in/is-logged-in.guard';
import { UserDetailsResolve } from './core/resolvers/user-details.resolver';
import { AutoLoginComponent } from './modules/auto-login/auto-login.component';
import { PermissionsGuard } from './core/guards/permissions.guard';
import { NotConsumerGuard } from './core/guards/not-consumer.guard';
import { IsBuyerGuard } from './core/guards/is-buyer.guard';
import { PlatformConfigurationResolve } from './core/resolvers/platform-configuration.resolver';

const routes: Routes = [
    {
        path: '',
        resolve: {
            platformConfiguration: PlatformConfigurationResolve
        },
        children: [
            {
                path: 'login',
                component: LoginComponent
            },
            {
                path: 'autologin',
                component: AutoLoginComponent,
                data: {
                    isOldVersion: true
                }
            },
            {
                path: 'auto',
                component: AutoLoginComponent
            },
            {
                path: 'otlogin',
                loadChildren: './modules/otlogin/otlogin.module#OtloginModule',
            },
            {
                path: 'forgotpassword',
                loadChildren: './modules/forgot-password/forgot-password.module#ForgotPasswordModule',
            },
            {
                path: 'reset-password',
                loadChildren: './modules/reset-password/reset-password.module#ResetPasswordModule',
            },
            {
                path: 'createaccount',
                loadChildren: './modules/create-account/create-account.module#CreateAccountModule'
            },
            {
                path: 'prepareaccount',
                loadChildren: './modules/prepare-account/prepare-account.module#PrepareAccountModule'
            },
            {
                path: '',
                component: MasterPageComponent,
                canActivate: [IsLoggedInGuard],
                resolve: {
                    userDetails: UserDetailsResolve
                },
                children: [
                    { path: 'dashboard', loadChildren: './modules/dashboard/dashboard.module#DashboardModule' },
                    {
                        path: 'home',
                        canActivate: [IsBuyerGuard],
                        loadChildren: './modules/home/home.module#HomeModule'
                    },
                    {
                        path: 'payments',
                        canActivate: [NotConsumerGuard],
                        loadChildren: './modules/payments/payments.module#PaymentsModule'
                    },
                    {
                        path: 'order-guide',
                        canActivate: [IsBuyerGuard],
                        loadChildren: './modules/order-guide/order-guide.module#OrderGuideModule'
                    },
                    { path: 'cart', loadChildren: './modules/cart/cart.module#CartModule' },
                    { path: 'orders', loadChildren: './modules/orders/orders.module#OrdersModule' },
                    {
                        path: 'drafts',
                        canActivate: [PermissionsGuard],
                        loadChildren: './modules/drafts/drafts.module#DraftsModule',
                        data: { permission: { permissionGroup: 'ORDERS', permissionKey: 'orders_approve' } }
                    },
                    {
                        path: 'vendors',
                        // This is commented at the DM Support request. They need the vendors page on shop 4 to manage customers
                        // canActivate: [NotConsumerGuard],
                        loadChildren: './modules/vendors/vendors.module#VendorsModule'
                    },
                    {
                        path: 'product-cost',
                        loadChildren: './modules/product-cost-report/product-cost-report.module#ProductCostReportModule'
                    },
                    {
                        path: 'product-cost-by-category',
                        loadChildren: './modules/product-cost-by-category/product-cost-by-category.module#ProductCostByCategoryModule'
                    },
                    {
                        path: 'product-cost-by-product',
                        loadChildren: './modules/product-cost-by-product/product-cost-by-product.module#ProductCostByProductModule'
                    },
                    { path: 'account', loadChildren: './modules/account/account.module#AccountModule' },
                    { path: 'customers', loadChildren: './modules/customers/customers.module#CustomersModule' },
                    { path: 'manage-coupons', loadChildren: './modules/cupons/manage-coupons.module#ManageCouponsModule' },
                    {
                        path: 'products', canActivate: [NotConsumerGuard],
                        loadChildren: './modules/products/products.module#ProductsModule'
                    },
                    { path: 'price-requests', loadChildren: './modules/price-requests/price-requests.module#PriceRequestsModule' },
                    {
                        path: 'rewards',
                        loadChildren: './modules/rewards/rewards.module#RewardsModule'
                    },
                    {
                        path: 'auctions',
                        loadChildren: './modules/auctions/auctions.module#AuctionsModule'
                    },
                    {
                        path: '**',
                        redirectTo: '/home',
                        pathMatch: 'full'
                    }
                ]
            },
            {
                path: '**',
                redirectTo: '/login',
                pathMatch: 'full',
            }
        ]
    },
    {
        path: '**',
        redirectTo: '/login',
        pathMatch: 'full',
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {
        enableTracing: false,
        onSameUrlNavigation: 'reload',
        scrollPositionRestoration: 'enabled'
    })],
    exports: [RouterModule]
})
export class AppRoutingModule { }
