import { Action } from '@ngrx/store';
import { PriceRequestsSearchRequests } from 'src/app/core/models/price-request/price-requests-search.model';
import { SearchResponse } from 'src/app/core/models/search-response.model';
import { PriceRequestModel, UpdatePriceRequestModel } from 'src/app/core/models/price-request/price-request.model';
import { PriceRequestSeller } from 'src/app/core/models/price-request/price-request-seller.interface';

export enum PriceRequestActionTypes {
    LoadPriceRequests = '[Price Request] Load Price Requests',
    LoadPriceRequestsSuccess = '[Price Request] Load Price Request Success',
    ArchivePriceRequest = '[Price Request] Archive Price Request',
    ArchivePriceRequestSuccess = '[Price Request] Archive Price Request Success',
    UpdatePriceRequest = '[Price Request] Update Price Request',
    UpdatePriceRequestSuccess = '[Price Request] Update Price Request Success',
    LoadVendorPriceRequest = '[Price Request] VendorPriceRequest',
    LoadVendorPriceRequestSuccess = '[Price Request] VendorPriceRequest Success',
    RequestPriceVendor = '[Price Request] RequestPriceVendor',
    RequestPriceVendorSuccess = '[Price Request] RequestPriceVendorSuccess',
}

export class LoadPriceRequests implements Action {
    readonly type = PriceRequestActionTypes.LoadPriceRequests;

    constructor(public payload: { requestParams: PriceRequestsSearchRequests }) { }
}


export class LoadPriceRequestsSuccess implements Action {
    readonly type = PriceRequestActionTypes.LoadPriceRequestsSuccess;

    constructor(public payload: { response: SearchResponse<PriceRequestModel> }) { }
}

export class ArchivePriceRequest implements Action {
    readonly type = PriceRequestActionTypes.ArchivePriceRequest;

    constructor(public payload: { id: number }) { }
}

export class ArchivePriceRequestSuccess implements Action {
    readonly type = PriceRequestActionTypes.ArchivePriceRequestSuccess;

    constructor(public payload: { id: number }) { }
}

export class UpdatePriceRequest implements Action {
    readonly type = PriceRequestActionTypes.UpdatePriceRequest;

    constructor(public payload: UpdatePriceRequestModel) { }
}

export class UpdatePriceRequestSuccess implements Action {
    readonly type = PriceRequestActionTypes.UpdatePriceRequestSuccess;

    constructor(public payload: PriceRequestModel) { }
}

export class LoadVendorPriceRequest implements Action {
    readonly type = PriceRequestActionTypes.LoadVendorPriceRequest;

    constructor(public payload: { productId: number }) { }
}

export class LoadVendorPriceRequestSuccess implements Action {
    readonly type = PriceRequestActionTypes.LoadVendorPriceRequestSuccess;

    constructor(public payload: { vendorDetails: PriceRequestSeller[], productId: number }) { }
}

export class RequestPriceVendor implements Action {
    readonly type = PriceRequestActionTypes.RequestPriceVendor;

    constructor(public payload: { productId: number, vendorId: number }) { }
}

export class RequestPriceVendorSuccess implements Action {
    readonly type = PriceRequestActionTypes.RequestPriceVendorSuccess;

    constructor(public payload: { applyDate: Date, vendorId: number }) { }
}

export type PriceRequestActions = LoadPriceRequests
    | LoadPriceRequestsSuccess
    | ArchivePriceRequest
    | ArchivePriceRequestSuccess
    | UpdatePriceRequest
    | UpdatePriceRequestSuccess
    | LoadVendorPriceRequest
    | LoadVendorPriceRequestSuccess
    | RequestPriceVendor
    | RequestPriceVendorSuccess;
