import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-icon-order-edit',
  templateUrl: './icon-order-edit.component.html',
  styleUrls: ['./icon-order-edit.component.scss']
})
export class IconOrderEditComponent implements OnInit {
  
  @Input() cssClass = '';

  constructor() { }

  ngOnInit() {
  }

}
