import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import * as moment from 'moment';
import { DATE_CUSTOM_RANGES } from '../../constants/global.contants';

@Component({
    selector: 'app-daterange-selector',
    templateUrl: './daterange-selector.component.html',
    styleUrls: ['./daterange-selector.component.scss']
})
export class DaterangeSelectorComponent implements OnInit {

    @Input() placeholder = 'Select date(s)';
    @Input() maxDate = moment();
    @Input() selected;
    @Input() ranges: any = DATE_CUSTOM_RANGES;

    @Output() datesUpdated = new EventEmitter<Array<Date>>();

    constructor() { }

    ngOnInit() { }

    onDateRangeChanged(dateParams?: Array<Date>) {
        this.datesUpdated.emit(dateParams);
    }
}
