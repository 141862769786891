import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-icon-meat',
  templateUrl: './icon-meat.component.html',
  styleUrls: ['./icon-meat.component.scss']
})
export class IconMeatComponent implements OnInit {

  @Input() cssClass = 'icon--18';
  
  constructor() { }

  ngOnInit() {
  }

}
