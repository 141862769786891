import { Component, OnInit, Input } from '@angular/core';

@Component({
    selector: 'app-icon-price-levels',
    templateUrl: './icon-price-levels.component.html'
})
export class IconPriceLevelsComponent implements OnInit {
    @Input() cssClass = '';

    constructor() { }

    ngOnInit() {
    }

}
