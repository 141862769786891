import { Component, OnInit, Input } from '@angular/core';
import { environment } from 'src/environments/environment';
import { PlatformType } from 'src/environments/platform-type.enum';

@Component({
    selector: 'app-icon-minus',
    templateUrl: './icon-minus.component.html',
    styleUrls: ['./icon-minus.component.scss']
})
export class IconMinusComponent implements OnInit {

    currentPlatform = environment.platformId;
    PlatformType = PlatformType;

    @Input() cssClass = '';

    constructor() { }

    ngOnInit() {
    }

}
