import { Platform } from './../../core/models/branding/platform.model';
import { getPlatformConfiguration } from './../selectors/branding.selector';
import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { catchError, exhaustMap, map, mergeMap, tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { defer, Observable, of } from 'rxjs';
import { LOGIN_DATA_STORAGE_KEY, USER_PERMISSIONS_STORAGE_KEY } from '../constants/global.contants';
import {
    AuthActionTypes,
    ChangePassword,
    ChangePasswordError,
    ChangePasswordSuccess,
    GuestRegister,
    GuestRegisterSuccess,
    GuestUpdateInfo,
    GuestUpdateInfoFail,
    GuestUpdateInfoSuccess,
    HomeUrlLoaded,
    LoadHomeUrl,
    LoadUserDetails,
    LoadUserPermissions,
    LoadUserPermissionsSuccess,
    Login,
    Logout,
    ReloadUserDetails,
    SendVerificationEmail,
    UpdateSelectedSite
} from '../actions/auth.actions';
import { SitesService } from 'src/app/core/services/sites/sites.service';
import { AppState } from 'src/app/app.reducer';
import { Action, select, Store } from '@ngrx/store';
import { LoadCartSummary } from '../actions/cart.actions';
import { LoadSites } from '../actions/site.actions';
import { ToggleInfobar } from '../actions/infobar.actions';
import { MarketingService } from 'src/app/core/services/marketing/marketing.service';
import { AuthenticationService } from 'src/app/core/services/authentication/authentication.service';
import { NotifierService } from 'angular-notifier';
import { AccountTypes } from 'src/app/core/enums/account-type.enum';
import { LoadVendors, UpdateOrderGuideLayout } from '../actions/order-guide.action';
import { LoadDraftsCount } from '../actions/draft.actions';
import { UserService } from 'src/app/core/services/user/user.service';
import { UserPermissionGroup } from 'src/app/core/models/user-permission-group.model';
import * as authSelector from '../selectors/auth.selector';
import { VendorService } from 'src/app/core/services/vendor/vendor.service';
import { TagsService } from 'src/app/core/services/tags/tags.service';

@Injectable()
export class AuthEffects {
    public isVendorStore: boolean;

    @Effect({ dispatch: false })
    login$ = this.actions$.pipe(
        ofType<Login>(AuthActionTypes.LoginAction),
        tap(action => {
            localStorage.setItem(LOGIN_DATA_STORAGE_KEY(), JSON.stringify(action.payload.loginResponse));
            this.store.dispatch(new ToggleInfobar({ open: false }));
        })
    );

    @Effect({ dispatch: false })
    logout$ = this.actions$.pipe(
        ofType<Logout>(AuthActionTypes.LogoutAction),
        tap((action) => {
            if (action.payload && action.payload.forceLogout) {
                this.notifier.show({
                    type: 'info',
                    message: action.payload.message || 'Username or password is invalid'
                });
            }

            localStorage.removeItem(LOGIN_DATA_STORAGE_KEY());
            localStorage.removeItem(USER_PERMISSIONS_STORAGE_KEY());
            localStorage.removeItem('SITE_ID_STORAGE');

            // clear cache
            this.vendorsSvc.resetCache();
            this.tagsSvc.resetCache();

            if (action.payload && action.payload.changeZip) {
                this.router.navigateByUrl('/createaccount/guest');
            } else {
                this.router.navigate(['login'], {
                    queryParams: {
                        returnUrl: action.payload && action.payload.returnUrl ? action.payload.returnUrl : undefined
                    }
                });
            }
        })
    );

    @Effect({ dispatch: false })
    updateSelectedSite$ = this.actions$.pipe(
        ofType<UpdateSelectedSite>(AuthActionTypes.UpdateSelectedSiteAction),
        tap((action) => {
            this.sitesSvc.updateSelectedSite(action.payload.siteId).subscribe();
            localStorage.setItem('SITE_ID_STORAGE', JSON.stringify(action.payload.siteId));

            this.userService.loadUserDetails().subscribe(userDetails => {
                if (!userDetails.isAccountVerified || (!userDetails.connectedSellers.length && !userDetails.isGuest)) {
                    this.router.navigateByUrl('/prepareaccount');
                    localStorage.removeItem('SITE_ID_STORAGE');
                } else {
                    this.store.dispatch(new LoadCartSummary());
                    this.store.dispatch(new LoadVendors(true));

                    this.store.select(authSelector.userHasPermissions, {
                        permissionGroup: 'ORDERS',
                        permissionKey: 'orders_approve'
                    })
                        .subscribe((hasPermission: boolean) => {
                            if (hasPermission) {
                                this.store.dispatch(new LoadDraftsCount());
                            }
                        });
                }
            });
        })
    );

    @Effect({ dispatch: false })
    loadUserDetailsAction$ = this.actions$.pipe(
        ofType<LoadUserDetails>(AuthActionTypes.LoadUserDetailsAction),
        tap((action) => {
            if (window['$crisp'] && !action.payload.userDetails.isGuest) {
                window['$crisp'].push(["set", "user:email", [action.payload.userDetails.email]]);
            }
            this.store.dispatch(new LoadSites());
            this.store.select(authSelector.userHasPermissions, { permissionGroup: 'ORDERS', permissionKey: 'orders_approve' })
                .subscribe((hasPermission: boolean) => {
                    if (hasPermission) {
                        this.store.dispatch(new LoadDraftsCount());
                    }
                });

            this.store.pipe(select(getPlatformConfiguration)).subscribe((platform: Platform) => {
                this.isVendorStore = (platform || {} as Platform).branding.isVendorStore;
            });

            this.store.dispatch(new UpdateOrderGuideLayout({
                gridViewMode: action.payload.userDetails.userAccountTypeId !== AccountTypes.Consumer && !this.isVendorStore,
                showPricedItemsFilter: action.payload.userDetails.userAccountTypeId !== AccountTypes.Consumer
            }));

        })
    );

    @Effect()
    loadHomeUrl$ = this.actions$.pipe(
        ofType<LoadHomeUrl>(AuthActionTypes.LoadHomeUrl),
        exhaustMap((action) => this.marketingSvc.getHomeUrl()),
        map((response: any) => new HomeUrlLoaded({
            url: response.url
        }))
    );

    @Effect()
    userPermissions$: Observable<Action> = this.actions$.pipe(
        ofType(AuthActionTypes.LoadUserPermissions),
        mergeMap((action: LoadUserPermissions) => this.userService.loadUserPermissions(action.payload).pipe(
            map((userPermissions: Array<UserPermissionGroup>) => {
                localStorage.setItem(USER_PERMISSIONS_STORAGE_KEY(), JSON.stringify(userPermissions));
                return new LoadUserPermissionsSuccess(userPermissions);
            })
        ))
    );

    @Effect({ dispatch: false })
    sendVerificationEmail$ = this.actions$.pipe(
        ofType<SendVerificationEmail>(AuthActionTypes.SendVerificationEmail),
        exhaustMap((action) => this.authSvc.sendVerificationEmail(action.payload.userId)
            .pipe(
                map((response: { email: string; }) => {
                    if (!(action.payload || {}).userId || action.payload.showNotification) {
                        this.notifier.show({
                            type: 'success',
                            message: `Email verification sent to ${response.email}.`
                        });
                    }
                })
            )
        ),
    );

    @Effect()
    init$ = defer(() => {
        const loginData = localStorage.getItem(LOGIN_DATA_STORAGE_KEY());
        const userPermissionData = localStorage.getItem(USER_PERMISSIONS_STORAGE_KEY());

        if (loginData) {
            return of(new Login({ loginResponse: JSON.parse(loginData), userPermissionGroups: JSON.parse(userPermissionData) }));
        } else if (!['/autologin', '/auto', '/forgotpassword', '/reset-password', '/login'].includes(window.location.pathname)
            && !window.location.pathname.includes('/createaccount')
        ) {
            return of(new Logout({
                returnUrl: window.location.pathname + window.location.search
            })) as any;
        }
    });

    @Effect()
    changePassword$ = this.actions$.pipe(
        ofType<ChangePassword>(AuthActionTypes.ChangePassword),
        exhaustMap((action) => this.authSvc.changePassword(action.payload).pipe(map((token: any) => {
            this.notifier.notify('success', 'Password was updated');
            localStorage.setItem(LOGIN_DATA_STORAGE_KEY(), JSON.stringify(token));
            this.store.dispatch(new ToggleInfobar({
                open: false
            }));
            return new ChangePasswordSuccess(token.accessToken);
        }),
            catchError(err => {
                this.notifier.notify('error', err.error.message);

                return of(new ChangePasswordError());
            })),
        )
    );

    @Effect()
    registerGuest$ = this.actions$.pipe(
        ofType(AuthActionTypes.GuestRegister),
        exhaustMap((action: GuestRegister) => this.authSvc.registerGuest(action.payload.zipCode).pipe(
            map((response: any) => {
                if (action.payload.goPage) {
                    this.router.navigate([this.getRedirectURL(action.payload.goPage)]);
                } else {
                    this.router.navigate(['/order-guide']);
                }
                this.store.dispatch(new GuestRegisterSuccess());
                return new Login({ loginResponse: response.response });
            }),
            catchError(err => {
                this.notifier.notify('error', err.error.message);
                return err;
            }))
        )
    );

    @Effect()
    updateInfoGuest$ = this.actions$.pipe(
        ofType(AuthActionTypes.GuestUpdateInfo),
        exhaustMap((action: GuestUpdateInfo) => this.authSvc.updateInfo(action.payload)
            .pipe(
                map((response: any) => {
                    this.store.dispatch(new Login({ loginResponse: response }));
                    this.store.dispatch(new ToggleInfobar({
                        open: false
                    }));
                    this.store.dispatch(new ReloadUserDetails());
                    return new GuestUpdateInfoSuccess({ response });
                }),
                catchError(err => {
                    this.notifier.notify('error', err.error.message);
                    return of(new GuestUpdateInfoFail());
                })),
        )
    );

    @Effect()
    reloadUserDetails$ = this.actions$.pipe(
        ofType(AuthActionTypes.ReloadUserDetails),
        mergeMap(() => this.userService.loadUserDetails()),
        map((userDetails: any) => {
            return new LoadUserDetails({ userDetails });
        })
    );

    getRedirectURL(url: string) {
        if (url.includes('home')) {
            return url;
        } else {
            return '/order-guide';
        }
    }

    constructor(
        private actions$: Actions,
        private router: Router,
        private store: Store<AppState>,
        private sitesSvc: SitesService,
        private marketingSvc: MarketingService,
        private authSvc: AuthenticationService,
        private notifier: NotifierService,
        private userService: UserService,
        private vendorsSvc: VendorService,
        private tagsSvc: TagsService
    ) { }

}
