import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-icon-bank',
  templateUrl: './icon-bank.component.html',
  styleUrls: ['./icon-bank.component.scss']
})
export class IconBankComponent implements OnInit {
  
  @Input() cssClass = '';
  
  constructor() { }

  ngOnInit() {
  }

}
