import { Component, OnInit, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { AppState } from 'src/app/app.reducer';
import { TemplateEnum } from 'src/app/core/enums/template.enum';
import { getThemeId } from '../../selectors/branding.selector';

@Component({
    selector: 'app-icon-menu',
    templateUrl: './icon-menu.component.html',
    styleUrls: ['./icon-menu.component.scss']
})
export class IconMenuComponent implements OnInit {

    @Input() cssClass = '';
    @Input() showStandardIcon = false;

    template$: Observable<TemplateEnum>;
    templateEnum = TemplateEnum;

    constructor(private store: Store<AppState>) { }

    ngOnInit() {
        this.template$ = this.store.select(getThemeId);
    }

}
