import { TokenModel } from '../rewards/token.model';
import { ShippingStatus } from '../shipping/shipping-status.model';

export class SubmitOrderSuccessModel {
    id: number;
    orderNumber: string;
    total: number;
    vendorId: number;
    vendorName: string;
    earnedTokens: Array<TokenModel>;
    shoppingCartItemId?: number;
    shippingStatus: ShippingStatus;
}
