import { BrandingState } from '../reducers/branding.reducer';
import { createFeatureSelector, createSelector } from '@ngrx/store';

const getBrandingState = createFeatureSelector<BrandingState>('branding');

export const getBrandingLoading = createSelector(
    getBrandingState,
    state => state.loading
);

export const getPlatformConfiguration = createSelector(
    getBrandingState,
    state => state.platformConfiguration
);

export const getThemeId = createSelector(
    getPlatformConfiguration,
    state => state.themeId
);

