import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import * as moment from 'moment';
import {ActivityTransactionModel} from 'src/app/core/models/rewards/wallet-activity.model';

@Component({
    selector: 'app-activity-transactions',
    templateUrl: './activity-transactions.component.html',
    styleUrls: ['./activity-transactions.component.scss']
})
export class ActivityTransactionsComponent implements OnInit {

    @Input() activities: Array<ActivityTransactionModel>;
    @Input() infoMessage: string;
    @Input() hideViewMore: boolean;
    @Output() viewMoreEmit = new EventEmitter();

    isExpanded = true;

    constructor() {}

    ngOnInit(): void {
    }

    getDateFormatted(date: Date) {
        return moment(date).format('DD MMM YYYY');
    }

    viewMore() {
        this.viewMoreEmit.emit();
    }

}
