
import { SearchAction, SearchActionTypes } from '../actions/search.actions';

export interface SearchState {
    keyword: string;
    show: boolean;
    searchUrl: string;
    params: any;
    searchText: string;
    showOrderGuide?: boolean;
}

export const initialState: SearchState = {
    keyword: null,
    show: false,
    searchUrl: undefined,
    params: {},
    searchText: undefined,
    showOrderGuide: undefined
};

export function searchReducer(
    state = initialState,
    action: SearchAction): SearchState {
    switch (action.type) {
        case SearchActionTypes.SearchByKeywordGlobalAction:
            return {
                ...state,
                keyword: action.payload.keyword
            };
        case SearchActionTypes.ShowSearchAction:
            return {
                ...state,
                keyword: null,
                show: action.payload.show,
                searchUrl: action.payload.searchUrl,
                searchText: action.payload.searchText,
                showOrderGuide: action.payload.showOrderGuide
            };
        case SearchActionTypes.UpdateSearchKeyword:
            return {
                ...state,
                keyword: action.payload.keyword || state.keyword
            };
        case SearchActionTypes.UpdateParams: {
            return {
                ...state,
                params: action.payload
            };
        }
        default:
            return state;
    }
}
