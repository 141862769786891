import { TagsActions, TagsActionTypes, TagUIAction } from '../actions/tags.actions';

export interface TagsState {
  tags: any[]
}

export const initialState: TagsState = {
  tags: []
};

export function tagsReducer(state = initialState,
  action: TagsActions): TagsState {
  switch (action.type) {
    case TagsActionTypes.TagsLoadedAction:
      return {
        ...state,
        tags: action.payload.tags
      };
    case TagsActionTypes.ReloadTagsAction:
      let newTags = state.tags;
      switch(action.payload.type)
      {
        case TagUIAction.Edit:
          const tagToUpdate = newTags.find(t => t.id === action.payload.tag.id);
          if (tagToUpdate) {
            tagToUpdate.name = action.payload.tag.name;
          }
          break;
        case TagUIAction.Delete:
          const tagToDeleteIdx = newTags.findIndex(t => t.id === action.payload.tag.id);
          newTags.splice(tagToDeleteIdx, 1);
          break;
        case TagUIAction.Add:
          newTags =  [...newTags, action.payload.tag];
        break;
      }

      return {
        ...state,
        tags: [...newTags]
      };
    default:
      return state;
  }
}