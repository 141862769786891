import { Store } from '@ngrx/store';
import { Component, Input, OnInit } from '@angular/core';
import { ProductAIHeaders } from 'src/app/core/enums/product-ai-headers.enum';
import { InfobarState } from 'src/app/shared/reducers/infobar.reducer';
import { AppState } from 'src/app/app.reducer';
import { ToggleInfobar } from 'src/app/shared/actions/infobar.actions';

@Component({
  selector: 'app-products-chat-ai',
  templateUrl: './products-chat-ai.component.html',
  styleUrls: ['./products-chat-ai.component.scss']
})
export class ProductsChatAIComponent implements OnInit {
  @Input() infobarConfigs: InfobarState;

  productAIHeaders = ProductAIHeaders;

  constructor(private store: Store<AppState>) { }

  ngOnInit(): void { }

  cancel() {
    this.store.dispatch(new ToggleInfobar({
      open: false,
    }));
  }

}
