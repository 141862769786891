import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-icon-bricks',
  templateUrl: './icon-bricks.component.html',
  styleUrls: ['./icon-bricks.component.scss']
})
export class IconBricksComponent implements OnInit {
  
  @Input() cssClass = '';

  constructor() { }

  ngOnInit() {
  }

}
