import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Cacheable } from 'ngx-cacheable';

@Injectable({
  providedIn: 'root'
})
export class RatingService {

  constructor(
    private http: HttpClient) { }

  @Cacheable()
  getRatingCategories(entityTypeId) {
    return this.http.get(`${environment.api.content}/Rating/categories/${entityTypeId}`)
  }

  addRating(params) {
    return this.http.post(`${environment.api.content}/Rating/`, {
      entityId: params.entityId,
      ratingEntityTypeId: params.ratingEntityTypeId,
      score: params.score
    });
  }

  @Cacheable()
  getRating(entityTypeId, entityId) {
    return this.http.get(`${environment.api.content}/Rating/${entityTypeId}/${entityId}`) 
  }
  
}
