import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { Action, Store } from '@ngrx/store';
import * as auctionActions from './../actions/auction.actions';
import { mergeMap, map, catchError } from 'rxjs/operators';
import { AuctionService } from 'src/app/core/services/auction/auction.service';
import { LoadAuctionShoppingCart } from '../actions/cart.actions';
import { AuctionsResponseModel } from 'src/app/modules/auctions/models/auctions-response.model';
import { AuctionItemModel } from 'src/app/modules/auctions/models/auction-item.model';
import { NotifierService } from 'angular-notifier';
import { AddEditAuctionItemResponseModel } from 'src/app/modules/auctions/models/add-edit-auction-item-response.model';
import { AppState } from 'src/app/app.reducer';
import { ToggleInfobar } from '../actions/infobar.actions';
import { Router } from '@angular/router';
import { AuctionOfferModel } from 'src/app/modules/auctions/models/auction-offer.model';
import { MatDialog } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';

@Injectable()
export class AuctionEffects {

    @Effect()
    auctions$: Observable<Action> = this.actions$.pipe(
        ofType(auctionActions.AuctionActionTypes.LoadAuction),
        mergeMap((action: auctionActions.LoadAuctions) => this.auctionService.getAuctions(action.payload).pipe(
            map((auctions: AuctionsResponseModel) => new auctionActions.LoadAuctionSuccess(auctions))
        ))
    );

    @Effect()
    saveAuctionItem$: Observable<Action> = this.actions$.pipe(
        ofType(auctionActions.AuctionActionTypes.SaveAuctionItem),
        mergeMap((action: auctionActions.SaveAuctionItem) => this.auctionService.saveAuctionItem(action.payload.images, action.payload.auctionItem, action.payload.isEditMode).pipe(
            map((response: AddEditAuctionItemResponseModel) => {
                if (action.payload.isEditMode) {
                    this.notifierService.notify('success', 'Auction item was successfully updated.');
                } else {
                    this.notifierService.notify('success', 'Auction item was successfully added.');
                }

                const auctionItem = new AuctionItemModel();
                auctionItem.name = response.productName;
                auctionItem.address = response.address;
                auctionItem.imageUrl = this.sanitizer.bypassSecurityTrustResourceUrl(response.imageUrl);
                auctionItem.price = response.price;
                auctionItem.description = response.productDescription;
                auctionItem.isCurrentUsersProduct = response.isCurrentUsersProduct;
                auctionItem.id = response.id;
                auctionItem.status = response.status;

                this.store.dispatch(new ToggleInfobar({
                    open: false
                }));

                return new auctionActions.SaveAuctionItemSuccess({ auctionItem: auctionItem, isEditMode: action.payload.isEditMode });
            }),
            catchError((err) => {
                this.notifierService.notify('error', err.error.message);
                return of(new auctionActions.SaveAuctionItemFailed());
            })
        ))
    );

    @Effect()
    makeOffer$: Observable<Action> = this.actions$.pipe(
        ofType(auctionActions.AuctionActionTypes.MakeOffer),
        mergeMap((action: auctionActions.MakeOffer) => this.auctionService.makeOffer(action.payload.auctionItemId, action.payload.deliveryTypeId, action.payload.price).pipe(
            map(() => {
                const modalInstance = this.matDialog.openDialogs.find(d => d.id === 'make-offer-dialog');
                if (modalInstance) {
                    modalInstance.close();
                }
                this.notifierService.notify('success', 'Offer placed with success.');
                return new auctionActions.MakeOfferSuccess();
            }),
            catchError((err) => {
                this.notifierService.notify('error', err.error.message);
                return of(new auctionActions.MakeOfferFailed());
            })
        ))
    );

    @Effect()
    approveOffer$: Observable<Action> = this.actions$.pipe(
        ofType(auctionActions.AuctionActionTypes.ApproveOffer),
        mergeMap((action: auctionActions.ApproveOffer) => this.auctionService.approveOffer(action.payload.auctionItemId, action.payload.offerId).pipe(
            map(() => {
                this.notifierService.notify('success', 'Offer approved successfully.');
                return new auctionActions.ApproveOfferSuccess(action.payload);
            }),
            catchError((err) => {
                this.notifierService.notify('error', err.error.message);
                return of(new auctionActions.ApproveOfferFailed());
            })
        ))
    );

    @Effect()
    rejectOffer$: Observable<Action> = this.actions$.pipe(
        ofType(auctionActions.AuctionActionTypes.RejectOffer),
        mergeMap((action: auctionActions.RejectOffer) => this.auctionService.rejectOffer(action.payload.auctionItemId, action.payload.offerId).pipe(
            map(() => {
                this.notifierService.notify('success', 'Offer rejected successfully.');
                return new auctionActions.RejectOfferSuccess(action.payload);
            }),
            catchError((err) => {
                this.notifierService.notify('error', err.error.message);
                return of(new auctionActions.RejectOfferFailed());
            })
        ))
    );

    @Effect()
    auctionAddToCart$: Observable<Action> = this.actions$.pipe(
        ofType(auctionActions.AuctionActionTypes.AddToCart),
        mergeMap((action: auctionActions.AddToCart) => this.auctionService.addToCart(action.payload.auctionItemId, action.payload.quantity).pipe(
            map(() => {
                this.router.navigate(['/cart']);
                return new LoadAuctionShoppingCart();
            })
        )),
    );

    @Effect()
    loaddAuctionOffers$ = this.actions$.pipe(
        ofType(auctionActions.AuctionActionTypes.LoadAuctionOffers),
        mergeMap((action: auctionActions.LoadAuctionOffers) => this.auctionService.getOffers(action.payload).pipe(
            map((data: Array<AuctionOfferModel>) => new auctionActions.LoadAuctionOffersSuccess(data))
        ))
    );

    constructor(
        private actions$: Actions,
        private auctionService: AuctionService,
        private notifierService: NotifierService,
        private store: Store<AppState>,
        private router: Router,
        private matDialog: MatDialog,
        private sanitizer: DomSanitizer
    ) { }
}
