import { createSelector } from '@ngrx/store';

export const selectRatingState = state => state.rating;

export const ratingCategories = createSelector(
  selectRatingState,
  state => state.ratingCategories
);

export const loadingRatingCat = createSelector(
  selectRatingState,
  state => state.loadingRatingCat
);

export const selectRating = (entityId,entityTypeId) => createSelector(
  selectRatingState,
  (state) => {
      if (state.ratings) {
          return state.ratings.find(r => r.entityId === entityId 
            && r.ratingEntityTypeId === entityTypeId);
      } else {
          return undefined;
      }
});
