import { createSelector } from '@ngrx/store';

export const selectCouponState = state => state.coupon;

export const couponErrorList = createSelector(
    selectCouponState,
    state => state.couponErrors
);

export const loadingSaveCupon = createSelector(
    selectCouponState,
    state => state.loadingSave
);

export const loadingCupon = createSelector(
    selectCouponState,
    state => state.loading
);

export const getCouponDetails = createSelector(
    selectCouponState,
    state => state.couponDetails
);

export const getEditCoupon = createSelector(
    selectCouponState,
    state => state.couponEdit
);
