import { createSelector } from '@ngrx/store';

export const selectUtilsState = state => state.utils;

export const selectCountries = createSelector(
    selectUtilsState,
    state => state.countries
);

export const selectStates = createSelector(
    selectUtilsState,
    (state, props) => state.states.get((props || {}).countryId)
);

export const selectTimezones = createSelector(
    selectUtilsState,
    state => state.timezones
);