import { Component, OnInit, Output, EventEmitter, Input, OnChanges, SimpleChanges } from '@angular/core';
import { SortbyFilter, SortByType } from 'src/app/core/enums/sortby-filter.enum';
import { DraftOrderStatusType } from 'src/app/core/enums/draft-status.enum';
import { LeadsType } from 'src/app/core/enums/leads-type.enum';
import { TemplateEnum } from 'src/app/core/enums/template.enum';
import { Observable } from 'rxjs';
import { getThemeId } from '../../selectors/branding.selector';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/app.reducer';

@Component({
    selector: 'app-sortby-filter',
    templateUrl: './sortby-filter.component.html',
    styleUrls: ['./sortby-filter.component.scss']
})
export class SortbyFilterComponent implements OnInit, OnChanges {

    @Input() sortby: any;
    @Input() sortType: SortByType;

    template$: Observable<TemplateEnum>;
    templateEnum = TemplateEnum;

    options = [];
    title = 'Sort By';

    @Output() changed = new EventEmitter<SortbyFilter>();

    constructor(private store: Store<AppState>) { }

    ngOnInit() {
        this.template$ = this.store.select(getThemeId);
        this.setOptions();
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.options
            .forEach(o => o.isSelected = o.id === this.sortby);
    }

    onClick(option) {
        this.options.forEach(o => {
            o.isSelected = o.id === option.id;
        });

        this.changed.emit(option.id);
    }

    setOptions() {
        switch (this.sortType) {
            case (SortByType.Drafts):
                this.options = [
                    { id: DraftOrderStatusType.Pending, name: 'Pending', isSelected: DraftOrderStatusType.Pending === this.sortby },
                    { id: DraftOrderStatusType.Processed, name: 'Processed', isSelected: DraftOrderStatusType.Processed === this.sortby }
                ];
                break;
            case (SortByType.Leads):
                this.title = 'Leads';
                this.options = [
                    { id: LeadsType.New, name: 'New', isSelected: LeadsType.New === this.sortby },
                    { id: LeadsType.Archived, name: 'Archived', isSelected: LeadsType.Archived === this.sortby }
                ];
                break;
            default:
                this.options = [
                    { id: SortbyFilter.PLACED, name: 'Placed', isSelected: SortbyFilter.PLACED === this.sortby },
                    { id: SortbyFilter.DELIVER, name: 'Deliver', isSelected: SortbyFilter.DELIVER === this.sortby }
                ];
        }
    }
}
