import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS, HttpClientJsonpModule } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './modules/login/login.component';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { EffectsModule } from '@ngrx/effects';
import { reducers, metaReducers } from './app.reducer';
import { environment } from 'src/environments/environment';
import { AuthEffects } from './shared/effects/auth.effects';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SharedModule } from './shared/shared.module';
import { AuthenticationInterceptor } from './core/services/authentication/authentication.interceptor';
import { MasterPageComponent } from './modules/master-page/master-page.component';
import { UserDetailsResolve } from './core/resolvers/user-details.resolver';
import { CartEffects } from './shared/effects/cart.effects';
import { CurrencyPipe } from '@angular/common';
import { OrderEffects } from './shared/effects/order.effects';
import { SiteEffects } from './shared/effects/site.effects';
import { SitesResolve } from './core/resolvers/sites.resolver';
import { AutoLoginComponent } from './modules/auto-login/auto-login.component';
import { OrderGuideEffects } from './shared/effects/order-guide.effects';
import { TagsEffects } from './shared/effects/tags.effects';
import { MatDialogModule } from '@angular/material/dialog';
import { SearchEffects } from './shared/effects/search.effects';
import { PaymentEffects } from './shared/effects/payment.effects';
import { UtilsEffects } from './shared/effects/utils.effects';
import { VendorEffects } from './shared/effects/vendor.effects';
import { NgxCurrencyModule } from 'ngx-currency';
import { UserEffects } from './shared/effects/user.effects';
import { RatingEffects } from './shared/effects/rating.effects';
import { DraftEffects } from './shared/effects/draft.effects';
import { ChatEffects } from './shared/effects/chat.effects';
import { CustomerEffects } from './shared/effects/customer.effect';
import { ProductEffects } from './shared/effects/product.effects';
import { SettingsEffects } from './shared/effects/settings.effects';
import { DeliveryDayEffects } from './shared/effects/delivery-day.effects';
import { PriceRequestsEffects } from './shared/effects/price-requests.effects';
import { BrandingEffects } from './shared/effects/branding.effect';
import { RegisterEffects } from './shared/effects/register.effects';
import { PlatformConfigurationResolve } from './core/resolvers/platform-configuration.resolver';
import { CouponEffects } from './shared/effects/coupon.effects';
import { PageHeaderComponent } from './modules/master-page/components/page-header/page-header.component';
import {
    PageHeaderTemp1Component
} from './modules/master-page/components/page-header/templates/page-header.temp1/page-header.temp1.component';
import { PageSidebarComponent } from './modules/master-page/components/page-sidebar/page-sidebar.component';
import {
    PageSidebarTemp1Component
} from './modules/master-page/components/page-sidebar/templates/page-sidebar.temp1/page-sidebar.temp1.component';
import { RewardsEffects } from './shared/effects/rewards.effects';
import { AuctionEffects } from './shared/effects/auction.effects';
import { ShippingEffects } from './shared/effects/shipping.effect';
import EncodeUrlSerializer from './shared/interceptors/encode-url.interceptor';
import { UrlSerializer } from '@angular/router';
import { ProductsChatAIComponent } from './modules/products-chat-ai/products-chat-ai.component';
import { GoogleTagManagerModule } from 'angular-google-tag-manager';

@NgModule({
    declarations: [
        AppComponent,
        LoginComponent,
        MasterPageComponent,
        AutoLoginComponent,
        PageHeaderComponent,
        PageHeaderTemp1Component,
        PageSidebarComponent,
        PageSidebarTemp1Component,
        ProductsChatAIComponent
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        HttpClientModule,
        HttpClientJsonpModule,
        ReactiveFormsModule,
        MatDialogModule,

        NgxCurrencyModule,

        StoreModule.forRoot(reducers, { metaReducers }),
        EffectsModule.forRoot([AuthEffects, CartEffects, OrderEffects, SiteEffects, OrderGuideEffects, TagsEffects, SearchEffects,
            PaymentEffects, VendorEffects, UtilsEffects, UserEffects, DraftEffects, RatingEffects, ChatEffects, CustomerEffects,
            ProductEffects, SettingsEffects, DeliveryDayEffects, PriceRequestsEffects, BrandingEffects, RegisterEffects,
            CouponEffects, RewardsEffects, AuctionEffects, ShippingEffects]),
        !environment.production ? StoreDevtoolsModule.instrument() : [],
        BrowserAnimationsModule,
        SharedModule,
        GoogleTagManagerModule.forRoot({ 
            id: ''
        })
    ],
    providers: [
        HttpClient,
        CurrencyPipe,
        UserDetailsResolve,
        PlatformConfigurationResolve,
        SitesResolve,
        { provide: HTTP_INTERCEPTORS, useClass: AuthenticationInterceptor, multi: true },
        { provide: UrlSerializer, useClass: EncodeUrlSerializer },
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
