import { CouponDetails } from 'src/app/core/models/cupons/coupon-details.model';
import { CouponModel } from 'src/app/core/models/cupons/coupon.model';
import { SaveCouponModel } from 'src/app/core/models/cupons/save-coupon.model';
import { CouponActions, CouponActionTypes } from '../actions/coupon.actions';


export const couponFeatureKey = 'coupon';

export interface CouponState {
    couponErrors: any;
    loadingSave: boolean;
    couponDetails: CouponDetails;
    loading: boolean;
    couponEdit: SaveCouponModel;
}

export const initialState: CouponState = {
    couponErrors: undefined,
    loadingSave: undefined,
    couponDetails: undefined,
    loading: undefined,
    couponEdit: undefined
};

export function couponReducer(
    state = initialState,
    action: CouponActions): CouponState {
    switch (action.type) {
        case CouponActionTypes.ApplyCoupon:
            return {
                ...state,
                couponErrors: undefined
            };
        case CouponActionTypes.ApplyCouponError:
            return {
                ...state,
                couponErrors: action.payload.message
            };
        case CouponActionTypes.SaveCoupon:
            return {
                ...state,
                loadingSave: true
            };
        case CouponActionTypes.SaveCouponFailed:
            return {
                ...state,
                loadingSave: false
            };
        case CouponActionTypes.SaveCouponSuccess:
            return {
                ...state,
                loadingSave: false,
                couponDetails: {
                    ...state.couponDetails,
                    items: [action.payload, ...state.couponDetails.items]
                }
            };
        case CouponActionTypes.LoadCoupon:
            return {
                ...state,
                loading: true
            };
        case CouponActionTypes.LoadCouponSuccess:
            return {
                ...state,
                loading: false,
                couponDetails: action.payload
            };
        case CouponActionTypes.LoadCouponDetails:
            return {
                ...state,
                loadingSave: true
            };
        case CouponActionTypes.LoadCouponDetailsSuccess:
            return {
                ...state,
                loadingSave: false,
                couponEdit: action.payload
            };
        case CouponActionTypes.ActivateCoupon:
            return {
                ...state,
                loading: true
            };
        case CouponActionTypes.ActivateCouponSuccess:
            return {
                ...state,
                loading: false
            };
        case CouponActionTypes.ActivateCouponFailed:
            return {
                ...state,
                loading: false
            };

        case CouponActionTypes.SaveEditCoupon:
            return {
                ...state,
                loadingSave: true
            };
        case CouponActionTypes.SaveEditCouponSuccess:
            const index = state.couponDetails.items.findIndex(coupon => coupon.id === action.payload.id);

            if (index !== -1) {
                state.couponDetails.items.splice(index, 1, action.payload);
            }

            return {
                ...state,
                couponEdit: undefined,
                loadingSave: false
            };
        case CouponActionTypes.SaveEditCouponFailed:
            return {
                ...state,
                loadingSave: false
            };
        case CouponActionTypes.ResetCouponErrors:
            return {
                ...state,
                couponErrors: undefined
            };
        default:
            return state;
    }
}
