import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-icon-chat-clean',
    templateUrl: './icon-chat-clean.component.html'
})
export class IconChatCleanComponent implements OnInit {

    constructor() { }

    ngOnInit() {
    }

}
