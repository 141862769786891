export class VendorModel {
    accountId: number;
    acronym: string;
    allowBackorder: boolean;
    id: number;
    isAvailable: boolean;
    isFavorite: boolean;
    isFavouriteForSearch: boolean;
    isInventory: boolean;
    isSelected: boolean;
    logo: string;
    minAmount: number;
    name: string;
    onHand: number;
    price: number;
    discount_price: number;
    discount_set_id: number;
    priceUom: number;
    productId: number;
    qtyThreshold: number;
    quantity: number;
    realProductId: number;
    representantEmail: string;
    representantName: string;
    representantPhone: string;
    shortDescription: string;
    uomName: string;
    vendorSku: string;
    originalVendorId: number;
}
