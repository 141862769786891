import { Directive, ElementRef, HostListener, Input } from '@angular/core';
import { AppState } from 'src/app/app.reducer';
import { Store } from '@ngrx/store';
import { LoadRating } from '../actions/rating.actions';
import { EntityType } from 'src/app/core/enums/entity-type.enum';

@Directive({
  selector: '[appVendorHover]'
})
export class VendorHoverDirective {

  @Input() entityId: number;
  @Input() entityTypeId: EntityType;

  constructor(
    private el: ElementRef,
    private store: Store<AppState>  
  ) { }
  
  @HostListener('mouseenter') onMouseEnter() {
    const { entityId, entityTypeId } = this;
    this.store.dispatch(new LoadRating({
      entityId,
      entityTypeId
    }))
  }


}
