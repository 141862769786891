export enum GridColumnDateGrouping {
    DAILY = 1,
    WEEKLY = 2,
    MONTHLY = 3,
    YEARLY = 4
}

export enum OrderFilterType {
    ORDER_DATE = 1,
    DELIVERY_DATE = 2
}