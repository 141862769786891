import {Component, Input, OnInit} from '@angular/core';
import {ActivityTransactionTypeEnum} from 'src/app/core/enums/wallet-activity-type.enum';

@Component({
    selector: 'app-icon-activity-type',
    templateUrl: './icon-activity-type.component.html',
    styleUrls: ['./icon-activity-type.component.scss']
})
export class IconActivityTypeComponent implements OnInit {
    @Input() cssClass: string;
    @Input() activityType: ActivityTransactionTypeEnum;

    walletActivityType = ActivityTransactionTypeEnum;

    constructor() {}

    ngOnInit(): void {
    }

}
