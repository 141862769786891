import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { AppState } from 'src/app/app.reducer';
import { Store } from '@ngrx/store';
import { ChangeProductName } from '../../actions/order-guide.action';

@Component({
  selector: 'app-edit-product-name',
  templateUrl: './edit-product-name.component.html',
  styleUrls: ['./edit-product-name.component.scss']
})
export class EditProductNameComponent implements OnInit {

  editForm: FormGroup;

  @Input() productId: number;

  @Input() originalName: string;
  
  @Input() currentName: string;

  @Input() inline = false;

  constructor(
    private fb: FormBuilder,
    private store: Store<AppState>
  ) { }

  ngOnInit() {
    this.editForm = this.fb.group({
      name: [this.currentName, [Validators.required]]
    });
  }

  onRevertChanges() {
    this.editForm.controls.name.setValue(this.originalName);
  }

  onSave() {
    if (this.editForm.invalid) {
      return;
    }

    this.store.dispatch(new ChangeProductName({
      productId: this.productId,
      newName: this.editForm.controls.name.value.trim(),
      currentName: this.currentName.trim(),
      originalName: this.originalName.trim()
    }));
  }

}
