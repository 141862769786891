import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { CustomerDetailsUpdateModel } from '../../models/customer/customer-details.model';
import { CustomerInvitesFilter } from '../../models/customer/customer-invites.filter';
import { ResendInviteModel } from '../../models/customer/resend-invite.model';
import { LeadsType } from '../../enums/leads-type.enum';
import { LeadsInfoModel } from '../../models/customer/leads-info.model';
import { Observable } from 'rxjs';
import { CustomerLight } from '../../models/customer/customer-light.model';

@Injectable({
    providedIn: 'root'
})
export class CustomerService {

    constructor(private http: HttpClient) { }


    getCustomers(params: {
        page: number,
        statuses?: number[],
        priceLevels?: Array<number>,
        keyword?: string,
        orderedDate?: { start: Date, end: Date; },
        lastOrderedDate?: { start: Date, end: Date; };
    }) {
        return this.http.post(`${environment.api.content}/Customer/search`, {
            page: params.page,
            statuses: params.statuses,
            priceLevels: params.priceLevels,
            orderedDate: params.orderedDate,
            lastOrderedDate: params.lastOrderedDate,
            keyword: params.keyword
        });
    }

    getAllCustomers(params: {
        page: number,
        statuses?: number[],
        priceLevels?: Array<number>,
        keyword?: string,
        orderedDate?: { start: Date, end: Date; },
        lastOrderedDate?: { start: Date, end: Date; };
    }): Observable<CustomerLight[]> {
        return this.http.post<CustomerLight[]>(`${environment.api.content}/Customer/search_all`, {
            page: params.page,
            statuses: params.statuses,
            priceLevels: params.priceLevels,
            orderedDate: params.orderedDate,
            lastOrderedDate: params.lastOrderedDate,
            keyword: params.keyword
        });
    }

    getCustomerLeads(params: { page: number, isArchived: LeadsType, keyword?: string; }) {
        return this.http.post(`${environment.api.content}/Customer/leads`, {
            page: params.page,
            keyword: params.keyword,
            isArchived: params.isArchived
        });
    }

    saveLeads(params: LeadsInfoModel) {
        return this.http.post(`${environment.api.content}/Customer/leads/${params.leadId}`, {
            isProcessing: params.isProcessing,
            statusId: params.statusId,
            customerSiteId: params.customerSiteId,
            notes: params.notes,
            priceLevelId: params.priceLevelId,
            representativeId: params.representativeId,
            buyerSiteCode: params.buyerSiteCode
        });
    }

    getCustomerDetails(customerDetails) {
        return this.http.get(`${environment.api.content}/Customer/${customerDetails.customerId}/${customerDetails.priceLevelId}`);
    }

    updateCustomerDetails(payload: { customerId: number, customerDetailsUpdate: CustomerDetailsUpdateModel; }) {
        return this.http.put(`${environment.api.content}/Customer/${payload.customerId}`, payload.customerDetailsUpdate);
    }

    getCustomerInvites(invitesFilter: CustomerInvitesFilter) {
        return this.http.post(`${environment.api.content}/Customer/invites`, invitesFilter);
    }

    inviteUser(invite: ResendInviteModel) {
        return this.http.post(`${environment.api.content}/Customer/invite_user`, invite);
    }

    deleteInvite(invitationId: number) {
        return this.http.delete(`${environment.api.content}/Customer/invites/${invitationId}`);
    }

    getCompatiblePriveLevels(platformId: any) {
        return this.http.get(`${environment.api.content}/Customer/compatible_price_levels/${platformId}`);
    }
}
