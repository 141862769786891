import { Component, OnInit, Input, OnChanges, Output, EventEmitter } from '@angular/core';
import { AppState } from 'src/app/app.reducer';
import { Store } from '@ngrx/store';
import { AddToCart, AddToCartFailed, LoadCartSummaryOrderGuide } from '../../actions/order-guide.action';
import { VendorModel } from 'src/app/core/models/vendor/vendor.model';
import { TemplateEnum } from 'src/app/core/enums/template.enum';
import { combineLatest, Observable, of } from 'rxjs';
import { getThemeId } from '../../selectors/branding.selector';
import { CartService } from 'src/app/core/services/cart/cart.service';
import { catchError, filter } from 'rxjs/operators';
import { CartSummaryLoaded, LoadCartTotals, StartCartSummaryLoading, UpdateShoppingCartAfterAddSuccess } from '../../actions/cart.actions';
import { NotifierService } from 'angular-notifier';
import { OrderService } from 'src/app/core/services/order/order.service';
import { NewOrdersCountLoaded } from '../../actions/order.actions';
import { AddToCartResponseModel } from '../../models/cart/add-to-cart-response.model';
import { customersList } from '../../selectors/order-guide.selector';

@Component({
    selector: 'app-product-quantity',
    templateUrl: './product-quantity.component.html',
    styleUrls: ['./product-quantity.component.scss']
})
export class ProductQuantityComponent implements OnInit, OnChanges {
    @Input() vendor: VendorModel;
    @Input() allowFraction = false;
    @Input() reloadCartVendor = false;
    @Input() extraParams: any;
    @Input() fromCart: boolean;
    @Input() externalDispatch = false;
    @Input() isProductDetails: boolean;
    @Input() gridContainer: boolean;
    @Input() inlineContainer: boolean;
    @Input() visible = false;
    @Input() discountPrice: number;
    @Output() quantityChange = new EventEmitter();
    customerSiteId: number;
    customerAccountId: number;

    template$: Observable<TemplateEnum>;
    tempalteEnum = TemplateEnum;

    public quantity = 0;
    addToCartAction: AddToCart;
    private timer;

    constructor(
        private store: Store<AppState>,
        private cartSvc: CartService,
        private notifier: NotifierService,
        private orderService: OrderService
    ) { }

    ngOnInit() {
        this.template$ = this.store.select(getThemeId);
        this.addToCartAction = new AddToCart({
            vendorId: undefined,
            productId: undefined,
            quantity: undefined,
            reloadCartVendor: undefined,
            customerSiteId: undefined,
            customerAccountId: undefined,
            price: undefined
        });
        this.store.select(customersList).subscribe(customers => {
            const selectedCustomer = customers?.find(c => c.selected);
            this.customerAccountId = selectedCustomer?.buyerCustomerAccountId;
            this.customerSiteId = selectedCustomer?.buyerCustomerSiteId;
        })
        this.quantity = this.vendor?.quantity;
    }

    ngOnChanges() {
        this.quantity = this.vendor?.quantity;
    }

    changeQuantity(decreaserQuantity: boolean = false) {
        const changeSize = this.allowFraction ? 0.5 : 1;
        const newQuantity = this.quantity + (decreaserQuantity ? (changeSize * -1) : changeSize);

        if (newQuantity < 0) {
            return;
        }

        this.quantity = newQuantity;
        clearTimeout(this.timer);
        this.timer = setTimeout(() => {
            if (this.externalDispatch) {
                this.quantityChange.emit({
                    vendorId: this.vendor.id,
                    productId: this.vendor.productId,
                    productIdFromGroup: this.vendor.realProductId,
                    quantity: this.quantity,
                    reloadCartVendor: this.reloadCartVendor,
                    extraParams: this.extraParams,
                    price: this.vendor.discount_price ? this.vendor.discount_price : this.discountPrice > 0 ? this.discountPrice : undefined,
                    orderGuideSeller: this.customerSiteId ? true : false,
                    originalVendorId: this.vendor.originalVendorId
                });
            } else {
                this.addToCartAction.payload.vendorId = this.vendor.id;
                this.addToCartAction.payload.productId = this.vendor.productId;
                this.addToCartAction.payload.productIdFromGroup = this.vendor.realProductId;
                this.addToCartAction.payload.quantity = this.vendor.isInventory && !this.vendor.allowBackorder &&
                    (this.vendor.onHand < this.quantity) ? this.vendor.onHand : this.quantity;
                this.addToCartAction.payload.reloadCartVendor = this.reloadCartVendor;
                this.addToCartAction.payload.displayMaxQuantityMessage = this.vendor.isInventory && !this.vendor.allowBackorder &&
                    (this.vendor.onHand < this.quantity) ? true : false;
                this.addToCartAction.payload.customerSiteId = this.customerSiteId;
                this.addToCartAction.payload.customerAccountId = this.customerAccountId;
                this.addToCartAction.payload.price = this.vendor.discount_price ? this.vendor.discount_price : this.discountPrice > 0 ? this.discountPrice : undefined;
                this.addToCartAction.payload.originalVendorId = this.vendor.originalVendorId;

                this.cartSvc.addToCart(this.addToCartAction.payload).subscribe(
                    (response: AddToCartResponseModel) => {
                        if (this.addToCartAction.payload.displayMaxQuantityMessage) {
                            // tslint:disable-next-line:max-line-length
                            this.notifier.notify('success', `Item was added in cart with max available quantity: ${this.addToCartAction.payload.quantity}`);
                        }

                        this.updateCartSummary(response);

                        this.quantityChange.emit({
                            quantity: this.quantity
                        });
                    },
                    catchError(error => {
                        this.notifier.notify('error', error.error.message);
                        this.store.dispatch(new AddToCartFailed());
                        return of();
                    }));
            }
        }, 300);
    }

    updateCartSummary(response: AddToCartResponseModel): void {
        response = {
            ...response,
            productIdFromGroup: this.addToCartAction.payload.productIdFromGroup,
            reloadCartVendor: this.addToCartAction.payload.reloadCartVendor,
            orderGuideSeller: this.customerSiteId ? true : false
        };

        this.store.dispatch(new StartCartSummaryLoading());

        this.store.dispatch(new UpdateShoppingCartAfterAddSuccess({
            productId: response.productId,
            productIdFromGroup: response.productIdFromGroup,
            vendorId: response.vendorId,
            quantity: response.quantity
        }));

        if (response.reloadCartVendor) {
            this.store.dispatch(new LoadCartTotals({
                vendorId: response.vendorId,
                customerSiteId: this.customerSiteId,
                customerAccountId: this.customerAccountId
            }));
        }

        if (!this.customerSiteId) {
            combineLatest([
                this.orderService.getNewOrdersCount(),
                this.cartSvc.loadCartSummary()
            ])
                .pipe(filter(([newCount, summary]) => !!newCount && !!summary))
                .subscribe(([newCount, summary]: [{ count: number }, any]) => {
                    this.store.dispatch(new CartSummaryLoaded({ summary }));
                    this.store.dispatch(new NewOrdersCountLoaded({ count: newCount.count }));
                });
        } else {
            this.store.dispatch(new LoadCartSummaryOrderGuide({ customerAccountId: this.customerAccountId, customerSiteId: this.customerSiteId }));
        }
    }

    submitQuantity($event: number) {
        if (isNaN($event)) {
            $event = this.quantity;
            return;
        }

        const newQuantity = Number($event);

        if (newQuantity < 0) {
            this.quantity = 0;
        }

        const hasValidDecimals = this.allowFraction ?
            ((newQuantity % 1 === 0 || newQuantity % 1 === 0.5 ? true : false)) : (newQuantity % 1 === 0);
        if (newQuantity < 0 || !hasValidDecimals || newQuantity === this.quantity) {
            $event = this.quantity;
            return;
        }

        this.quantity = newQuantity;
        if (this.externalDispatch) {
            this.quantityChange.emit({
                vendorId: this.vendor.id,
                productId: this.vendor.productId,
                quantity: this.quantity,
                reloadCartVendor: this.reloadCartVendor,
                extraParams: this.extraParams,
                price: this.vendor.discount_price ? this.vendor.discount_price : this.discountPrice > 0 ? this.discountPrice : undefined,
                orderGuideSeller: this.customerSiteId ? true : false,
                originalVendorId: this.vendor.originalVendorId
            });
        } else {
            this.store.dispatch(new AddToCart({
                vendorId: this.vendor.id,
                productId: this.vendor.productId,
                quantity: this.vendor.isInventory && !this.vendor.allowBackorder && (this.vendor.onHand < this.quantity) ?
                    this.vendor.onHand : this.quantity,
                reloadCartVendor: this.reloadCartVendor,
                displayMaxQuantityMessage: this.vendor.isInventory && !this.vendor.allowBackorder && (this.vendor.onHand < this.quantity) ?
                    true : false,
                customerAccountId: this.customerAccountId,
                customerSiteId: this.customerSiteId,
                price: this.vendor.discount_price ? this.vendor.discount_price : this.discountPrice > 0 ? this.discountPrice : undefined,
                orderGuideSeller: this.customerSiteId ? true : false,
                originalVendorId: this.vendor.originalVendorId
            }));
        }
    }

    getMaxValue(): number {
        if (this.vendor.onHand && !this.vendor.allowBackorder) {
            return this.vendor.onHand;
        }

        return undefined;
    }

    isInventoryAndOutOfStock(): boolean {
        return this.vendor?.isAvailable && this.vendor.isInventory && !this.vendor.allowBackorder && (this.vendor.onHand <= 0);
    }
}
